import type {TDropdownOption} from '@aktek/f4kit';
import {useForceUpdate} from '@aktek/f4kit';
import _debounce from 'lodash/debounce';
import React, {useCallback, useEffect, useMemo} from 'react';

import usePropsArray from '@/hooks/usePropsArray';

import {IOCacheContext} from '../IOCache/IOCacheContext';
import {TIOCacheContext} from '../IOCache/IOCacheContext';
import F3CacheFn from '../IOCache/IOCacheManager';
import IOHub from '../IOHub';
import GetOptions from '../IOSelect.GetOptions';
import type {TGetValueReturn} from '../types/IODict.t';
import {TIOHubProps} from '../types/IOHub.t';

export const useIODict = (props: TIOHubProps) => {
    const {type} = props;

    const missingValuesQueried = React.useRef<Set<string>>(new Set());
    const missingValues = React.useRef<Set<string>>(new Set());

    const ioCacheContext = React.useContext<TIOCacheContext>(IOCacheContext);
    const forceUpdate = useForceUpdate()[1];

    const hubRule = IOHub.GetHubRule(type);
    const internalCache = React.useRef<Record<string, TDropdownOption>>({});

    const hubKey = IOHub.GetKey(props);
    const options = ioCacheContext.getObject(hubKey);
    const missingValueFn = hubRule.getMissingValues;

    // @ts-expect-error We don't have a type for that
    const dict = GetOptions({options: options?.object?.dictionary, ...props});

    const fetchMissing = React.useCallback(_debounce(async () => {
        const missingValuesArray = Array.from(missingValues.current);

        missingValues.current.clear();
        // @ts-expect-error Props are resolving to never
        const res = await missingValueFn(props, missingValuesArray);
        missingValuesArray.forEach((key) => missingValuesQueried.current.add(key));

        // @ts-expect-error We don't have the type of responses yet
        if (res.isSuccessful()) {
            // @ts-expect-error We don't have the type of responses yet
            const data = res.getData().dictionary;
            internalCache.current = {...internalCache, ...data};
            forceUpdate();
        }
    }, 600), [props]);

    const refreshKeys = useMemo(() => {
        return hubRule.refreshKeys;
    }, [props]);

    const propsArray = usePropsArray(props, refreshKeys);

    const refresh = () => {
        F3CacheFn.getData(ioCacheContext, props);
    };

    useEffect(() => {
        if (!refreshKeys?.length) {
            refresh();
        }
    }, []);

    useEffect(() => {
        if (refreshKeys?.length > 0) {
            refresh();
        }
    }, propsArray);

    const exists = useCallback((key: string) => {
        return !!options?.[key];
    }, [options]);

    const getValue = useCallback((key: string): TGetValueReturn => {
        if (!dict) {
            const isLoading = ioCacheContext.getLoadingState(hubKey);

            if (isLoading) {
                return {isLoading: true};
            }

            return {isAvailable: false};
        }

        const entry = dict?.[key] || internalCache.current?.[key];

        if (entry) {
            return {value: entry};
        }

        if (typeof missingValueFn != 'function') {
            return {isAvailable: false};
        }

        const hasBeenQueriedBefore = missingValuesQueried.current.has(key);

        if (hasBeenQueriedBefore) {
            return {isAvailable: false};
        }

        const isQueuedForQuerying = missingValues.current.has(key);

        if (!isQueuedForQuerying) {
            missingValues.current.add(key);
            fetchMissing();

            return {isLoading: true, isAvailable: false};
        }

        return {isAvailable: false};
    }, [dict, internalCache.current, missingValuesQueried.current]);

    const getValues =useCallback((keys?: string[]) => {
        return keys.map((key) => getValue(key));
    }, [getValue, dict]);

    return {dict, getValue, getValues, exists};
};

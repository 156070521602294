import {F4ThemeColors} from '@aktek/f3theme';
import {F4Nestor} from '@aktek/f4form';
import {Icon} from '@aktek/f4kit';
import {faFolderPlus, faPlus, faTrashCan} from '@fortawesome/pro-regular-svg-icons';
import {useMemo} from 'react';

import F4OptionsButtonGroup from '@/components/F4Elements/F4OptionsButtonGroup';

import SmartFilterRule from './SmartFilter.Builder.Rule';
import type {TSmartFilterGroupProps} from './types/SmartFilter.Builder.t';

const groupColors = F4ThemeColors.filter((e) => e.startsWith('neutral')).map((e) => `bg-${e}`);

function SmartFilterGroup(props: TSmartFilterGroupProps) {
    const {depth = 0, onDelete, onAddRule, onAddGroup, fields, group, smartFilter, parentId, sboId} = props;

    const bgColor = useMemo(() => {
        if (depth == 0) return '';

        return groupColors[depth];
    }, [depth]);

    const childRules = Object.keys(group?.rules || {}).map((e) => smartFilter?.[e]);

    return <div className={`p-4 ${bgColor} w-full rounded-2xl`} >
        <div className={`flex flex-row gap-2  items-center justify-between`}>
            <F4Nestor key={group?.id} name={group?.id}>
                <F4OptionsButtonGroup
                    size="xs"
                    name="operator" options={{
                        '$and': {label: 'AND'},
                        '$or': {label: 'OR'},
                    }}
                />
            </F4Nestor>
            <div className="flex flex-row gap-4  items-center pr-3">
                <Icon icon={faFolderPlus} size="md" color="neutral-600" onClick={() => onAddGroup(group?.id)} />
                <Icon icon={faPlus} size="md" color="neutral-600" onClick={() => onAddRule(group?.id)} />
                {group?.id != 'main' && <Icon icon={faTrashCan} size="md" color="neutral-600" onClick={()=> onDelete(parentId, group?.id)} />}
            </div>
        </div>
        <div className={`flex flex-col gap-4 mt-4`}>
            {childRules.map((rule) => {
                if (rule.type == 'group') {
                    return <SmartFilterGroup
                        key={rule.id}
                        fields={fields}
                        sboId={sboId}
                        depth={depth + 1}
                        parentId={group.id}
                        smartFilter={smartFilter}
                        onAddGroup={onAddGroup}
                        onAddRule={onAddRule}
                        onDelete={onDelete}
                        group={rule} />;
                }

                return <SmartFilterRule
                    key={rule.id}
                    fields={fields}
                    sboId={sboId}
                    depth={depth + 1}
                    parentId={group.id}
                    smartFilter={smartFilter}
                    onDelete={onDelete}
                    rule={rule} />;
            })}
        </div>

    </div>;
}

export default SmartFilterGroup;

import {MorphService} from '@aktek/morph-frontend-sdk';

export const GetDataTableRows = (
    sboId: string,
    search: unknown,
    sort: object,
    skip: number,
    limit: number,
    userFilter: object,
    shouldReturnAllFields: boolean,
)=> MorphService.retrieveMorphsBySBOId(
    sboId,
    search,
    sort,
    skip,
    limit,
    userFilter,
    shouldReturnAllFields);


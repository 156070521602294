/* eslint-disable camelcase */
import {Icon, Image, Label, LoadingIndicator, Modal, TModalRef, toast} from '@aktek/f4kit';
import {Authentication_TokensService} from '@aktek/helios-frontend-sdk';
import {faBuildings, faCheck} from '@fortawesome/pro-regular-svg-icons';
import cn from 'classnames';
import {forwardRef, useEffect, useState} from 'react';

import AKTEKLogo from '@/assets/images/aktek_logo.png';
import SearchBox from '@/components/SearchBox/SearchBox';
import Env from '@/config/env';
import {useSignUserIn} from '@/context/UserContext/Hooks/useSignUserIn';
import {useUserAuth} from '@/context/UserContext/Hooks/useUserAuth';
import {EventKeys} from '@/helpers/Events';
import {strings} from '@/localization/i18n';

import useKeydownEventListener from '../hooks/useKeydownEventListener';
import useSwitchCompaniesSearch, {isRootCompany} from '../hooks/useSwitchCompaniesSearch';

const SwitchCompanyModal = ({}, ref:TModalRef) => {
    const signIn = useSignUserIn();
    const [searchQuery, setSearchQuery] = useState<string>('');
    const userAuth = useUserAuth();

    const [isLoading, setIsLoading] = useState(false);
    const filteredCompaniesData = useSwitchCompaniesSearch(searchQuery, userAuth);
    const userCompanies = userAuth.userCompanies;
    const rootCompanyId = userCompanies.find(isRootCompany)?._id;
    const {currentCompany} = useUserAuth();

    // logging out the user, because we are not firing an api call in the switch company modal
    useEffect(() => {
        if (userCompanies?.length == 0) {
            location.reload();
        }
    }, []);

    const isCompaniesDataEmpty = !filteredCompaniesData || filteredCompaniesData.length === 0;

    const handleSwitchCompany = async (companyId: string) => {
        setIsLoading(true);

        const res = await Authentication_TokensService.switchCompany(companyId);

        if (!res.isSuccessful?.()) {
            toast.error(strings('user_already_in_this_company'));

            return;
        }

        const data = res.getData();
        signIn(data);

        ls.tabsSchema = undefined;
        ls.recentlyUsedSBO = undefined;
        setIsLoading(false);
        location.reload();
        ls.permissionSection = 'admin';
    };

    const DisplayCompanies = ({companies, level = 0}) => companies.map((company) => {
        const rootCompany = level == 0;
        const companyLogo = company?.companyLogo;
        const isRoot = company?._id === rootCompanyId;
        const isCurrentCompanySelected = company?._id === currentCompany?._id;

        return (
            <div
                key={company?._id}
                className={cn(
                    !rootCompany && 'border-l border-neutral-300 pl-2.5 ml-8',
                )} >

                <div
                    className={cn(
                        !isCurrentCompanySelected && 'cursor-pointer hover:bg-neutral-50 ',
                        'p-4 rounded-xl ml-0.5',
                        'transition-all duration-200 ease-in-out',
                        'flex items-center justify-between gap-0',
                        isCurrentCompanySelected && 'bg-primary-25',
                    )}
                    onClick={() => !isCurrentCompanySelected && handleSwitchCompany(company?._id)} >

                    <div className="flex flex-row items-center justify-between gap-2">

                        {isRoot && <Image alt="AKTEK Logo" src={AKTEKLogo}
                            className="rounded-full !w-10 !h-10 !content-stretch p-0.5  " />}

                        {!isRoot && (companyLogo
                            ? <Image src={Env.getFileURL(companyLogo)} alt="image logo"
                            // object-cover
                                className="!w-10 !h-10 !rounded-full !content-stretch" />
                            : <div className="rounded-full !w-10 !h-10 p-2.5 bg-neutral-100">
                                <Icon icon={faBuildings} size="xl" color="neutral-600" className="w-full h-full" />
                            </div>)}

                        <Label text={company.companyName} className={cn(!isCurrentCompanySelected && 'cursor-pointer')} color="neutral-900" size="sm" />
                    </div>

                    {isCurrentCompanySelected && <Icon icon={faCheck} color="primary-600"/>}
                </div>

                {company.children?.length > 0 && <DisplayCompanies companies={company.children} level={level + 1} /> }
            </div>
        );
    });

    const handleKeyDown = (event) => EventKeys.isEnter(event)
    && filteredCompaniesData[0]?._id !== currentCompany?._id
    && handleSwitchCompany(filteredCompaniesData[0]?._id);

    useKeydownEventListener(handleKeyDown, filteredCompaniesData);

    return (
        <Modal
            className="overflow-hidden relative h-full"
            title={strings('switch_companies')}
            headerClassName="pl-6 border-b border-b-neutral-100 mb-4 text-xl"
            ref={ref}
            onClose={() => document.removeEventListener('keydown', handleKeyDown)} >

            {isLoading && <div className=" absolute flex items-center w-full h-full p-4 justify-center elevation-5 !shadow-none"
                style={{backgroundColor: 'rgba(0, 0, 0, 0.15)'}}>
                <LoadingIndicator size="xl" />
            </div>}

            <SearchBox
                onSearch={(e) => setSearchQuery(e)}
                className="!bg-neutral-100 rounded-lg mx-6"
                textBoxInputClassName="!bg-neutral-100 !text-base !font-normal !text-neutral-900"
                hasBottomBorder={false}
                placeholder={strings('search_companies')}
            />

            <div className="h-4/6 overflow-y-auto mx-6 my-4 relative">
                {isCompaniesDataEmpty && <div className="px-2 py-4 text-center text-neutral-600">{strings('no_results_found')}</div>}

                {!isCompaniesDataEmpty && <DisplayCompanies companies={filteredCompaniesData} /> }
            </div>

        </Modal>
    );
};

export default forwardRef(SwitchCompanyModal);

import {LoadingIndicator, Spreadsheet, SpreadsheetRef} from '@aktek/f4kit';
import React from 'react';

import {strings} from '@/localization/i18n';

import EmptyTableState from '../Table/Components/EmptyTableState';
import createNewMorphs from './API/createNewMorphs';
import _deleteRows from './API/deleteRows';
import saveModifications from './API/saveModifications';
import EmptyState from './Components/Empty';
import {NoColumnsState} from './Components/NoColumnsState';
import {Config} from './MorphSpreadsheet.Config';
import transformPastedData from './PastedDataParsers/transformPastedData';
import renderCell from './Render/RenderCell';
import useMorphSpreadsheet from './useMorphSpreadsheet';
import ValidateBulkCell from './validations/ValidateBulkCell';
import validateCell from './validations/ValidateCell';

interface F4TableSpreadsheetProps {
    mode: 'creation' | 'edit';
    sboId: string;
    onSave: () => void;
    spreadsheetRef?: React.MutableRefObject<SpreadsheetRef>;
}

const F4TableSpreadsheet: React.FC<F4TableSpreadsheetProps> = (props) => {
    const state = useMorphSpreadsheet(props);

    if (state.isPageLoading) {
        return <div className="flex items-center justify-center p-10">
            <LoadingIndicator />
        </div>;
    }

    if (state.isEmpty) {
        return null;
    }

    if (!state.dataState.columns?.length) {
        return null;
    }

    return <Spreadsheet alertMode hideRibbonBar
        ref={props.spreadsheetRef}
        topBarRightAdditionalView={<div>Right</div>}
        idColumn="_id"
        allowAddingNewRecords={props.mode == 'creation'}
        initialCount={props.mode == 'creation' ? 1 : 0}
        sorting={state.sorting}
        setSorting={state.setSorting}
        deleteRows={(ids) => _deleteRows(props.sboId, ids)}
        onSave={props.onSave}
        confirm={async (title, message) => {
            console.log('....F4TableSpreadsheet.tsx__53__: confirm', {title, message}, '\n_________');

            return true;
        }}
        aSyncCellValidation={async (cellInfo) => {
            const result = await validateCell(cellInfo);

            console.log('....F4TableSpreadsheet.tsx__41__: ', {result}, '\n_________');

            return result;
        }}
        aSyncBulkCellValidation={async (cells) => {
            const result = ValidateBulkCell(cells);
            console.log('....F4TableSpreadsheet.tsx__57__: ', {result}, '\n_________');

            return result;
        }}
        onChange={(tableState) => {
            state.setTableState(tableState);
        }}
        columns={state.dataState.columns}
        pastedDataTransformer={(rowIndex, colIndex, pastedValue) => {
            const column = state.dataState.columns[colIndex];
            const field = column.field;

            return transformPastedData(field, pastedValue);
        }}
        defaultData={state.dataState.dataByKey[state.key]}
        cellRenderer={({colIndex, ...rest}) => {
            const column = state.dataState?.columns[colIndex];

            return renderCell({column, ...rest});
        }}
        customStyles={{
            thContainer: {whiteSpace: 'nowrap'},
        }}
        config={Config}
        createNewRows={(newIds, modifications) => createNewMorphs(props.sboId, newIds, modifications)}
        saveModifications={(modifications) => saveModifications(props.sboId, modifications)}
    />;
};

export default F4TableSpreadsheet; // React.forwardRef(MorphSpreadsheet); !! Not needed as not used

import AskForModal from '@/asks/AskForModal';

import UsageMetricsModal from '../components/Agency.UsageMetrics.Modal';

const OnRibbonAgencyActions = (key:string) => {
    if (key === 'history') {
        AskForModal(
            UsageMetricsModal,
            {
                companyId: 'Global',
                openOnMount: true,
                header: 'Global',
            },
        );
    }
};

export default OnRibbonAgencyActions;

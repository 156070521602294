import {MorphService} from '@aktek/morph-frontend-sdk';

type TGetRowsCount = {
    sboId: string
}

export const GetDataTableRowsCount = async ({sboId}: TGetRowsCount) => {
    // add the filter object
    const response = await MorphService.retrieveMorphsCountBySBOId(sboId, '', null);
    if (!response.isSuccessful()) return 0;

    return response.getData() as number;
};

import {TContextMenuEntry} from '@aktek/f4kit';
import {faLink, faPen, faTrashAlt, faTurnRight} from '@fortawesome/pro-regular-svg-icons';
import _compact from 'lodash/compact';

import {strings} from '@/localization/i18n';

export const GetFolderContextMenu = ({
    copyLinkToFolder,
    openEditModal,
    openDeleteModal,
    folders,
}) => _compact([
    // TODO: to add the isVisible when we add it in f4kit
    {
        label: strings('copy_link_to_folder'),
        icon: faLink,
        iconProps: {color: 'neutral-500', className: '!w-4 !h-4'},
        onClick: copyLinkToFolder,
    },
    folders.can_edit && {
        label: strings('edit_folder'),
        icon: faPen,
        iconProps: {color: 'neutral-500', className: '!w-4 !h-4'},
        onClick: openEditModal,
    },
    folders.can_delete && {
        label: strings('delete_folder'),
        icon: faTrashAlt,
        iconProps: {color: 'error-600', className: '!w-4 !h-4'},
        labelProps: {color: 'error-600', className: 'pl-0.2'},
        onClick: openDeleteModal,
    },
]) as TContextMenuEntry[];

export const GetReportsContextMenu = ({openReport, moveDataTable, editDataStructure, folders, reportStructure}) => _compact([
    {
        label: strings('open'),
        onClick: openReport,
    },
    // folders.can_edit && {
    reportStructure.can_edit && {
        label: strings('move_to'),
        onClick: moveDataTable,
        icon: faTurnRight,
        iconProps: {color: 'neutral-500'},
    },
    reportStructure.can_edit && {
        label: strings('edit_data_structure'),
        onClick: editDataStructure,
        icon: faPen,
        iconProps: {color: 'neutral-500'},
    },
]);

export const GetRecentlyUsedContextMenu = (openRecentlyUsed) => [
    {
        label: strings('open'),
        onClick: openRecentlyUsed,
    },
    // {
    //     label: strings('remove'),
    //     icon: faPen,
    //     iconProps: {color: 'neutral-500'},
    // },
];

export const GetShortcutContextMenu = ({openDeleteModal, openShortcut, folders}) => _compact([
    {
        label: strings('open'),
        onClick: openShortcut,
    },
    folders.can_delete && {
        label: strings('delete_shortcut'),
        icon: faTrashAlt,
        iconProps: {color: 'error-600'},
        labelProps: {color: 'error-600'},
        onClick: openDeleteModal,
    },
]);


import {faEnvelope, faEye, faHistory, faLink, faPen, faSnowflake, faTrash, faUserUnlock} from '@fortawesome/pro-regular-svg-icons';
import {Row} from '@tanstack/react-table';

import {TRowActionsBuilder} from '@/components/Table/Types/Table.t';
import {UserTypes} from '@/constants/UserTypes.c';
import {strings} from '@/localization/i18n';
import {TPermission} from '@/types/Permissions.t';
import {TUser} from '@/types/User.t';

export type TGetRowUsersActionsArgs = {
    usersPermissions: TPermission,
    permissionsPermissions: TPermission,
    resetOtherUserPasswordPermissions: TPermission,
    canFreezeFromCompanySettings: boolean,
    canAdminGenerateLink: boolean,
    isCurrentUserAccountManager: boolean,
    isCurrentUserRegularUser: boolean,
    currentUser: TUser,
}

export const GetUsersRowActionsFn = ({
    usersPermissions,
    permissionsPermissions,
    resetOtherUserPasswordPermissions,
    canFreezeFromCompanySettings,
    canAdminGenerateLink,
    isCurrentUserAccountManager,
    isCurrentUserRegularUser,
    currentUser,

}: TGetRowUsersActionsArgs) => (row: Row<TUser>): TRowActionsBuilder => {
    const {isAccountManager, userType, isFrozen, isActive} = row.original;

    const isEditVisible = ()=>{
        if (((isCurrentUserRegularUser || isCurrentUserAccountManager) && userType != UserTypes.Regular)) {
            return false;
        }

        return usersPermissions?.can_edit;
    };

    const isCurrentUser = currentUser._id === row.original._id;

    const isDeleteVisible = ()=>{
        const isAccountManager = row.original?.isAccountManager;
        const userType = row.original?.userType;

        if ((isCurrentUserRegularUser || isCurrentUserAccountManager) && userType != UserTypes.Regular) return false;

        return usersPermissions?.can_delete && !isAccountManager;
    };

    return [
        {
            key: 'edit',
            label: strings('edit'),
            isVisible: isEditVisible,
            icon: faPen,
            showOnRow: true,
        },
        {
            key: 'view_permissions',
            label: strings('view_permissions'),
            isVisible: permissionsPermissions?.can_view,
            icon: faEye,
        },

        {
            key: 'freeze_account',
            label: strings('freeze_account'),
            isVisible: (canFreezeFromCompanySettings && !isFrozen) || isCurrentUser,
            icon: faSnowflake,
        },
        {
            key: 'unfreeze_account',
            label: strings('unfreeze_account'),
            isVisible: (canFreezeFromCompanySettings && isFrozen) || isCurrentUser,
            icon: faUserUnlock,
        },
        {
            key: 'send_verification_email',
            label: strings('send_verification_email'),
            isVisible: !isActive,
            icon: faEnvelope,
        },
        {
            key: 'generate_password_link',
            label: strings('generate_password_link'),
            isVisible: canAdminGenerateLink && resetOtherUserPasswordPermissions?.can_access && !isAccountManager && isActive,
            icon: faLink,
        },
        {
            key: 'view_history',
            label: strings('view_history'),
            isVisible: !isAccountManager,
            icon: faHistory,
        },
        {
            key: 'delete',
            label: strings('delete'),
            isVisible: isDeleteVisible,
            icon: faTrash,
            showOnRow: true,
        },
    ];
};

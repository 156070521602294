import IOHub from '../IOHub';
import IOHubRules from '../iohub.rules';
import {TIOHubProps} from '../types/IOHub.t';
import {TIOCacheContext} from './IOCacheContext';

async function wrapFunction(ioCacheContext: TIOCacheContext, key: string, fn) {
    if (ioCacheContext.getErrorState(key) === true) {
        return;
    }

    if (ioCacheContext.getLoadingState(key) === true) {
        return;
    }

    ioCacheContext.setLoadingState(key, true);
    const res = await fn();

    if (res.status === 304) {
        ioCacheContext.updateObjectCacheTime(key);
        ioCacheContext.setLoadingState(key, false);

        return;
    }

    if (res.isSuccessful()) {
        const data = res.getData();
        ioCacheContext.cacheObject(key, data);
    } else {
        ioCacheContext.setErrorState(key, true);
    }

    ioCacheContext.setLoadingState(key, false);
}

;

class F3CacheFn {

    static async callFunction(ioCacheContext: TIOCacheContext, props: TIOHubProps) {
        const {type} = props;
        const hubRule = IOHubRules[type];

        if (!hubRule) {
            console.warn('No cache rule for type:', type);

            return;
        }

        const key = IOHub.GetKey(props);
        const cachedObject = ioCacheContext.getObject(key);
        const lastClientCacheTime = cachedObject ? cachedObject.lastClientCacheTime : null;

        // @ts-ignore
        return wrapFunction(ioCacheContext, key, () => hubRule.apiCall(props, lastClientCacheTime));
    }

    static getConstraintText(props: TIOHubProps) {
        const {type} = props;
        const hubRule = IOHubRules[type];

        if (!hubRule) {
            console.warn('No cache rule for type:', type);

            return;
        }

        // @ts-ignore
        return hubRule.getConstraintText?.(props);
    }

    static getData(ioCacheContext: TIOCacheContext, props: TIOHubProps) {
        const {type} = props;
        const hubRule = IOHubRules[type];

        if (!hubRule) {
            console.warn('No cache rule for type:', type);

            return;
        }

        const key = IOHub.GetKey(props);
        const cachedObject = ioCacheContext.getObject(key);
        const lastClientCacheTime = cachedObject ? cachedObject.lastClientCacheTime : null;

        // @ts-ignore
        return wrapFunction(ioCacheContext, key, () => hubRule.apiCall(props, lastClientCacheTime));

        // return F3CacheFn.withContext(f3CacheContext).callFunction(props);
    }

    static getHubRule(props: TIOHubProps) {
        const {type} = props;
        const hubRule = IOHubRules[type];

        return hubRule;
    }

    static async getMissingValues(props: TIOHubProps, values: unknown[]) {
        const {type} = props;
        const hubRule = IOHubRules[type];

        if (!hubRule) {
            console.warn('No cache rule for type:', type);

            return;
        }

        const fn = hubRule.getMissingValues;

        if (!fn) return;

        // @ts-ignore
        return fn(props, values);
    }

}

export default F3CacheFn;

import {TDropdownOption, TDropdownValue} from '@aktek/f4kit';

import {LANGUAGES} from '@/constants/languages';

const GetDefaultLanguages = (_availableLanguages) => {
    return _availableLanguages
        .filter((lang) => LANGUAGES[lang])
        .sort((a, b) => LANGUAGES[a].label.localeCompare(LANGUAGES[b].label))
        .reduce(
            (acc, lang) => {
                acc[lang] = LANGUAGES[lang];

                return acc;
            },
      {} as Record<TDropdownValue, TDropdownOption>,
        );
};

export default GetDefaultLanguages;

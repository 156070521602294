import {Icon, Label} from '@aktek/f4kit';
import {faQuestionCircle} from '@fortawesome/pro-light-svg-icons';

import {TSmartFilterPreviewValueProps} from './types/SmartFilter.Preview.t';

function SmartFilterPreviewValue(props: TSmartFilterPreviewValueProps) {
    const {rule, fields} = props;
    const {fieldId, value} = rule;
    const field = fields[fieldId];

    if (!value) {
        return <Icon tooltip="Missing value"
            tooltipProps={{showDelay: 350}}
            color="neutral-400"
            size="sm"
            className="contents-center" icon={faQuestionCircle}/>;
    }
    // !!TODO: This is where the morph logic will need to be injected to display the value depending on the field type

    return <div className="rounded-md bg-neutral-50 border border-neutral-200 px-2 py-1 flex gap-1 flex-row items-center">
        {/* <Icon color="neutral-400" icon={fieldIcon.icon} />*/}
        <Label color="neutral-900" text={value?.toString?.()} size="sm" fontWeight="medium" />
    </div>;
}

export default SmartFilterPreviewValue;

import SBOEditorSection from './SBOEditorSection';
import SBOEditorSectionFields from './SBOEditorSectionFields';

export default function SBOEditorSections(props) {
    const currentSBO = props.sboFormRef.current?.data;

    if (!currentSBO?.sections) return null;

    const result = [];
    let index = 0;
    currentSBO?.sections?.forEach((sectionId) => {
        if (currentSBO?.sectionsMap.hasOwnProperty(sectionId)) {
            const section = currentSBO?.sectionsMap[sectionId];

            const sectionRender
             = <SBOEditorSection
                 index={index++}
                 section={section}
                 addField={props.addField}
                 sboFormRef={props.sboFormRef}
                 addSection={props.addSection}
                 deleteSection={props.deleteSection}
             >
                 <SBOEditorSectionFields
                     sboFormRef={props.sboFormRef}
                     currentSBO={currentSBO}
                     deleteField={props.deleteField}
                     onMoveFieldToTable ={props.onMoveFieldToTable }
                     addField={props.addField}
                     section={section}/>
             </SBOEditorSection>;

            result.push(sectionRender);
        }
    });

    return result;
}

import {TBreadcrumbStep} from '@aktek/f4kit';
import {useEffect, useMemo, useState} from 'react';

import {FOLDERS_AND_DATA} from '@/app/Explorer/constants/constantIds.c';
import {FindAncestorsFromNode} from '@/helpers/FindAncestorsFromNode.fn';
import {FindFolderById} from '@/helpers/FindFolderById.fn';
import {strings} from '@/localization/i18n';
import {TFolder} from '@/types/Folder.t';

type TAncestors = TFolder | TBreadcrumbStep

export type TExplorerUIState = {
        switchToRecentView: () => void
        switchToFoldersView: () => void
        rightPanelView: RightPanelView
        selectedFolder: TFolder
        setSelectedFolder: (item) => void
        ancestors: TAncestors[]
}

enum RightPanelView {
  RECENT = 'recent',
  FOLDERS = 'folders',
}

type TState = {
        recentFolders: Array<unknown>
        allFolders: Array<unknown>
}

const useExplorerUI = (state:TState, linkToFolder:string): TExplorerUIState => {
    const [rightPanelView, setRightPanelView] = useState<RightPanelView>(RightPanelView.RECENT);

    const [selectedFolder, _setSelectedFolder] = useState<TFolder>();

    const initialStep = {
        id: FOLDERS_AND_DATA,
        label: strings('folders_and_data'),
        type: 'folder',
        children: state.allFolders,
    } as TAncestors;

    const ancestors = useMemo(() => {
        if (selectedFolder?.id == FOLDERS_AND_DATA) return [initialStep];

        return [initialStep, ...FindAncestorsFromNode(selectedFolder, state.allFolders)];
    }, [selectedFolder, state]);

    const switchToRecentView = () => setRightPanelView(RightPanelView.RECENT);
    const switchToFoldersView = () => setRightPanelView(RightPanelView.FOLDERS);

    const setSelectedFolder = (item) => _setSelectedFolder(item);

    const folders = state.allFolders;

    useEffect(() => {
        if (!linkToFolder) return;

        const foundItem = FindFolderById(folders, linkToFolder);
        _setSelectedFolder(foundItem);
        switchToFoldersView();
    }, [linkToFolder, folders]);

    // updating the selectedFolder in case of a change
    useEffect(() => {
        if (selectedFolder?.id == FOLDERS_AND_DATA) {
            _setSelectedFolder((prev) => ({
                ...prev,
                children: folders as TFolder[],
            }));

            return;
        }

        if (selectedFolder?.id) {
            const foundItem = FindFolderById(folders, selectedFolder?.id);
            foundItem && _setSelectedFolder(foundItem);

            return;
        }
    }, [folders]);

    return {
        switchToRecentView,
        switchToFoldersView,
        rightPanelView,
        selectedFolder,
        setSelectedFolder,
        ancestors,
    };
};

export default useExplorerUI;

import {CompaniesService} from '@aktek/helios-frontend-sdk';
import {useCallback, useMemo} from 'react';

import F4ViewableComponent from '@/components/F4ViewableComponent/F4ViewableComponent';
import {useAdminPermissions} from '@/context/Permissions/usePermissions';
import {strings} from '@/localization/i18n';

import {GetAgencyColumns} from './Agency.Columns';
import AgencyCreateModal from './components/Agency.Create.Modal';
import {CUSTOM_AGENCY_ACTIONS} from './constants/constants.c';
import {GetRowAgencyActions} from './helpers/Agency.GetRowAgencyActions.fn';
import OnRibbonAgencyActions from './helpers/Agency.OnRibbonActions.fn';
import {OnRowAgencyActions} from './helpers/Agency.OnRowActions.fn';

const Agency = () => {
    const {companies} = useAdminPermissions();

    const getRowActions = useCallback(GetRowAgencyActions({companiesPermission: companies}),
        [companies]);

    const AgencyOnRowAction = useCallback(OnRowAgencyActions, []);

    const onRibbonAction = useCallback(OnRibbonAgencyActions, []);

    return (
        <div className="bg-neutral-50 h-full p-4">
            <F4ViewableComponent
                hasViewMode
                name="company"
                createBehavior="modal"
                modal={AgencyCreateModal}
                rowActions={getRowActions}
                onRowAction={AgencyOnRowAction}
                tableColumns={GetAgencyColumns()}
                canCreate={companies?.can_create}
                emptyProps={{addLabel: strings('new_company')}}
                defaultSortedColumn="companyName"
                getAllData={CompaniesService.getCompanies}
                onRibbonAction={onRibbonAction}
                customRibbonActions={CUSTOM_AGENCY_ACTIONS}
            // customActions={CUSTOM_AGENCY_ACTIONS}
            />
        </div>
    );
};

export default Agency;

import {DateTimePickerPopup} from '@aktek/f4kit';

import {combineDateAndTime, extractDateAndTime} from '@/helpers/Dates';
import {strings} from '@/localization/i18n';

const DateTimeCellEdit = ({value, onChange}) => {
    const {date, time} = extractDateAndTime(value || 0);

    return <div className="flex items-center gap-1">

        <DateTimePickerPopup defaultOpen
            local

            mode="date-time"
            onApply={(dateTime) => {
                // const timestamp = combineDateAndTime(dateTime.date, dateTime.time);
                // onChange(timestamp);
                // console.log('....Datetime.Cell.Edit.tsx__12__: ', timestamp, '\n_________');
            }}
            onChange={(dateTime) => {
                // console.log('....Datetime.Cell.Edit.tsx__12__: ', dateTime, '\n_________');
            }}
            timeProps={{
                defaultValue: time,
                timeFormat: 'hh:mm',
                placeholder: strings('select_time'),
            }}
            dateProps={{
                placeholder: strings('select_date'),
                value: date,
                minDate: 0,
                maxDate: 0,
                dateFormat: 'DD/MM/YYYY',
            }}
        />

    </div>;
};

export default DateTimeCellEdit;

import {F4Form} from '@aktek/f4form';
import {LoadingWrapper} from '@aktek/f4kit';

import SettingsFooter from '../../components/SettingsFooter';
import FreezeAccount from './components/FreezeAccountSettings';
import Geographical from './components/GeographicalSettings';
import LoginSettings from './components/LoginSettings';
import PasswordSettings from './components/PasswordSettings';
import SecuritySettings from './components/SecuritySettings';
import TimezoneSettings from './components/TimezoneSettings';
import useSettings from './hooks/useSettings';

export default function Settings() {
    const {
        isLoading,
        settings,
        handleSave,
        handleReset,
        handleDiscard,
    } = useSettings();

    return (
        <div className="bg-neutral-50 w-full h-full overflow-y-auto">
            <F4Form
                formKey="settings"
                initialValue={settings}
                className=" h-full "
            >

                <>
                    <LoadingWrapper isLoading={isLoading} isFull className="w-full px-4" size="lg">
                        <div className="flex flex-col gap-4 ">
                            <FreezeAccount />
                            <Geographical />
                            <LoginSettings />
                            <PasswordSettings/>
                            <SecuritySettings />
                            <TimezoneSettings />
                        </div>
                    </LoadingWrapper>

                    <SettingsFooter
                        onSave={handleSave}
                        onDiscard={handleDiscard}
                        onReset={handleReset}
                    />
                </>

            </F4Form>

        </div>
    );
}

import {F4FormStep} from '@aktek/f4form';
import {Label, TModalRef} from '@aktek/f4kit';
import {forwardRef, useMemo, useState} from 'react';

import {FOLDERS_AND_DATA} from '@/app/Explorer/constants/constantIds.c';
import useFilteredItems from '@/app/Explorer/hooks/useFilteredItems';
import {useGlobalFilesAndFolders} from '@/context/UserContext/Hooks/useGlobalFilesAndFolders';
import {strings} from '@/localization/i18n';
import {TFolder} from '@/types/Folder.t';

import F4CreatableModal from '../F4CreatableModal/F4CreatableModal';
import {TF4CreatableModalProps} from '../F4CreatableModal/types/F4CreatableModalProps.t';
import F4TreeViewSelector from '../F4Elements/F4TreeViewSelector';
import SearchBox from '../SearchBox/SearchBox';

type TSBOMoveModal = TF4CreatableModalProps & {
    folder?: TFolder
}

const SBOMoveModal = (props: TSBOMoveModal, ref: TModalRef) => {
    const {folder, getResult, ...rest}= props;
    const [searchValue, setSearchValue] = useState('');

    const {folders} = useGlobalFilesAndFolders();
    const data = useFilteredItems(folders, searchValue, true, 'folder', folder?.id, folder?.parentId, false);

    const finalInitialValue = useMemo(() => {
        const defaultParentId = folder?.parentId ? folder?.parentId : FOLDERS_AND_DATA;

        const initialValue = {
            ...folder,
            parentId: {[defaultParentId]: true},
        };

        return initialValue;
    }, [folder]);

    return (
        <F4CreatableModal isDisabled
            ref={ref}
            viewState={{mode: 'edit', _id: folder?.id, actionTitle: ' '}}
            title={strings('select_directory')}
            formKey="report"
            hasSidebar={false}
            storeFunction={(value)=>{
                getResult(value as object);

                return null;
            }}
            f4FormProps={{initialValue: finalInitialValue}}
            {...rest}

        >
            <F4FormStep autoNest>
                <div className="-mt-4">
                    <SearchBox onSearch={setSearchValue}
                        className="!bg-neutral-100 rounded-lg sticky elevation-5 !shadow-none -top-4 mt-6 mb-4"
                        textBoxInputClassName="!bg-neutral-100 !text-base !font-normal !text-neutral-900"
                    />
                    <F4TreeViewSelector name="parentId" data={data} parentId={folder?.parentId} size="xs" areAllNodesExpanded={true} />

                    {!data?.length && <Label text={strings('no_results_found')} size="sm" className="mt-5 ml-3 overflow-hidden" color="neutral-600"/>}
                </div>
            </F4FormStep>
        </F4CreatableModal>
    );
};

export default forwardRef(SBOMoveModal);

import {Drawer, TabNav, useTab} from '@aktek/f4kit';
import {useEffect, useMemo} from 'react';

import {Tabs} from '@/app/Home/Tabs';
import {openCloseDrawer} from '@/utils/openCloseDrawer';

import WIPPage from './Pages/WIPPage';
import {TNavigationPagesViewerProps} from './types/Navigation.t';

export default function NavigationPagesViewer(props: TNavigationPagesViewerProps) {
    const {pages, tabKey} = props;
    const hasSinglePage = pages.length === 1;

    const tab = useTab();

    useEffect(() => {
        props.selectedTab && tab.setState({selectedTab: props.selectedTab});
    }, [props.selectedTab]);

    const tabOptions = useMemo(() => {
        return pages.map((e) => {
            return {...e, key: e.tabKey};
        });
    }, [pages]);

    const tabComponents = useMemo(() => {
        return pages.reduce((obj, page) => {
            const {tabKey, props = {}} = page;
            const Component = Tabs[tabKey]?.component || WIPPage;
            const {key, ...rest}= props;

            obj[tabKey] = <Component key={key as string} tabKey={tabKey} {...rest} />;

            return obj;
        }, {}) as Record<string, JSX.Element>;
    }, [pages]);

    // @ts-expect-error Not defined inside state
    const selectedTab = tab.state?.selectedTab || tabOptions.find((tab) => tab.isVisible != false)?.key;

    if (hasSinglePage) {
        return <div className="p-4 bg-neutral-50 h-full border-l border-neutral-200">
            {tabComponents[selectedTab]}
        </div>;
    }

    return (
        <div className="flex h-full">
            <Drawer
                sideWidth={96}
                tooltipDelay={1000}
                defaultOpen={ls.drawer?.[tabKey] ?? true}
                onOpenChange={(e) => openCloseDrawer(tabKey, !!e)}
                buttonSize="sm"
            >

                <TabNav
                    size="sm"
                    orientation="vertical"
                    tabs={tabOptions}
                    value={selectedTab}
                    defaultValue={selectedTab}
                    onChange={(selectedTab) => tab.setState({selectedTab})}
                    className="overflow-x-visible"
                    buttonClassName="[&>:first-child]:[&>:first-child]:[&>:first-child]:!w-8"
                />
                <div className="p-4 bg-neutral-50 h-full border-l border-neutral-200">
                    {tabComponents[selectedTab]}
                </div>
            </Drawer>
        </div>
    );
}


import {Icon, Label} from '@aktek/f4kit';
import {faTrianglePersonDigging} from '@fortawesome/pro-light-svg-icons';

export default function WIPPage({tabKey}) {
    return <div className="flex flex-col gap-3 items-center p-auto w-full h-full justify-center ">
        <div className="flex flex-row gap-3 items-center p-auto w-full  justify-center ">
            <Icon icon={faTrianglePersonDigging} color="warning-700" size="xl" animation="breathing-2" />
            <Label fontWeight="bold" text={`Page construction in progress`} color="warning-700" size="xl"/>
        </div>
        <Label text={`(tabKey: ${tabKey})`} color="warning-700" size="md"/>
        <Label text={`If the component is built, maybe you forgot to include it in Tabs.tsx?`} color="warning-700" size="md"/>
    </div>;
}

import {TF4FormContextValue, useF4FormContext} from '@aktek/f4form';
import {Button, Label, Tooltip} from '@aktek/f4kit';
import {faArrowUpFromBracket, faCloudArrowDown, faSave} from '@fortawesome/pro-regular-svg-icons';
import _isFunction from 'lodash/isFunction';
import {useMemo} from 'react';

import AskForModal from '@/asks/AskForModal';
import F4FileReader from '@/components/F4Elements/F4FileReader';
import {useAdminPermissions} from '@/context/Permissions/usePermissions';
import {strings} from '@/localization/i18n';

import ExportModal from '../pages/Appearance/components/ExportModal';

type TSettingsFooterProps = {
    onSave?: (context: TF4FormContextValue) => void;
    onFileUpload?: (T: unknown, context: TF4FormContextValue) => void;
    onFileDownload?: (T: unknown) => void;
    onDiscard?: (context: TF4FormContextValue) => void;
    onReset?: (context: TF4FormContextValue) => void;
}

export default function SettingsFooter(props: TSettingsFooterProps) {
    const {
        onSave,
        onFileUpload,
        onDiscard,
        onReset,
    } = props;

    const f4FormContext = useF4FormContext();
    const {isEdited} = f4FormContext;

    const handleResetClick = () => onReset(f4FormContext);
    const handleDiscardClick = () => onDiscard(f4FormContext);
    const handleSaveClick = () => onSave(f4FormContext);

    const showButtons = useMemo(() => {
        return _isFunction(onSave) && _isFunction(onReset) && _isFunction(onDiscard);
    }, [handleDiscardClick, handleResetClick, handleSaveClick]);

    const {companySettings: companySettingsPermissions} = useAdminPermissions();

    return (
        <div className="flex flex-wrap justify-end items-center w-full min-w-52 gap-1 sticky -bottom-0.5
          mt-2 p-4 bg-neutral-50 elevation-3 !shadow-none">
            {(showButtons && companySettingsPermissions?.can_edit)
            && <>
                <Button
                    isGhost
                    size="sm"
                    className="capitalize ml-1"
                    textColor="neutral-600"
                    onClick={handleDiscardClick}
                    label={strings('discard')}
                    isDisabled={!isEdited}
                />
                <Button
                    isGhost
                    size="sm"
                    className="capitalize ml-1"
                    onClick={handleResetClick}
                    label={strings('reset_to_default')}
                />
                <>
                    {onFileUpload && <Tooltip showDelay={1000} placement="top" content={
                        <div className="flex justify-center items-center px-1 py-0.5">
                            <Label text={strings('import_theme')} size="xs"/>
                        </div>
                    }>
                        <Button
                            size="sm"
                            className="ml-1"
                            onClick={() => AskForModal(F4FileReader, {
                                openOnMount: true,
                                description: '.iotheme files accepted',
                                onChange: (file) => onFileUpload(file, f4FormContext),
                                title: strings('import_theme'),
                                extensions: ['iotheme'],
                            })}
                            variant="white"
                            iconSize="lg"
                            icon={faArrowUpFromBracket}
                        />
                    </Tooltip>}
                    {f4FormContext && <Tooltip showDelay={1000} placement="top" content={
                        <div className="flex justify-center items-center px-1 py-0.5">
                            <Label text={strings('export_theme')} size="xs"/>
                        </div>
                    }>
                        <Button
                            size="sm"
                            className="ml-1"
                            onClick={() => AskForModal(ExportModal, {
                                context: f4FormContext,
                            })}
                            variant="white"
                            iconSize="lg"
                            icon={faCloudArrowDown}
                        />
                    </Tooltip>}
                </>
                <Button
                    size="sm"
                    className="capitalize ml-1"
                    onClick={handleSaveClick}
                    isDisabled={!isEdited}
                    iconSize="lg"
                    icon={faSave}
                    label={strings('save')}
                />
            </>}
        </div>

    );
}

import {faCircleArrowUpRight, faFolder} from '@fortawesome/pro-regular-svg-icons';

import {useGlobalFilesAndFolders} from '@/context/UserContext/Hooks/useGlobalFilesAndFolders';
import {strings} from '@/localization/i18n';
import {FileTypes} from '@/types/FileTypes.t';

import {FOLDERS_AND_DATA, SHORTCUTS} from '../constants/constantIds.c';
import {TExplorerUIState} from '../hooks/useExplorerUI';
import {GetFoldersRightPanelClasses} from '../styles/FoldersRightPanel.styles';
import EmptyComponent from './EmptyComponent';
import ExplorerLoadingComponent from './ExplorerLoadingComponent';
import {FoldersRightPanelContent} from './FoldersRightPanel.Content';
import {FoldersRightPanelHeader} from './FoldersRightPanel.Header';

const FoldersRightPanel = ({explorerUIState}:{explorerUIState: TExplorerUIState}) => {
    const {isLoading} = useGlobalFilesAndFolders();
    const {selectedFolder} = explorerUIState;

    if (!selectedFolder?.children?.length) {
        const isFolder = selectedFolder?.id == FOLDERS_AND_DATA || selectedFolder?.type == FileTypes.folder;
        const isShortcut = selectedFolder?.type == FileTypes.shortcut || selectedFolder?.id == SHORTCUTS;
        const label = isShortcut ? 'no_shortcut_yet' : 'no_folders_yet';
        const icon = isFolder ? faFolder : isShortcut ? faCircleArrowUpRight : undefined;

        return <EmptyComponent
            explorerUIState={explorerUIState}
            icon={icon}
            label={strings(label)}
            description={strings('no_folders_desc')}
            parentId={selectedFolder?.id} />;
    }

    const classes = GetFoldersRightPanelClasses();

    return (
        <div className={classes.container}>
            <FoldersRightPanelHeader explorerUIState={explorerUIState} />
            <FoldersRightPanelContent explorerUIState={explorerUIState} />

            <ExplorerLoadingComponent condition={isLoading} />
        </div>
    );
};

export default FoldersRightPanel;

import {F4FormDiv, useF4FormRef} from '@aktek/f4form';
import {Button, Icon, Label} from '@aktek/f4kit';
import {FoldersService} from '@aktek/morph-frontend-sdk';
import {faCircleArrowUpRight, IconDefinition} from '@fortawesome/pro-regular-svg-icons';

import AskForModal from '@/asks/AskForModal';
import F4CreatableModal from '@/components/F4CreatableModal/F4CreatableModal';
import {TF4CreatableModalProps} from '@/components/F4CreatableModal/types/F4CreatableModalProps.t';
import F4Textbox from '@/components/F4Elements/F4TextBox';
import F4ToggleSwitch from '@/components/F4Elements/F4Toggle';
import ImageOrColorSelectorModal, {TImageColorSelectorProps} from '@/components/Modals/ImageOrColorSelector.Modal';
import {useUserAuth} from '@/context/UserContext/Hooks/useUserAuth';
import {strings} from '@/localization/i18n';

type TShortcutData = {
    name : string;
    customColor ?: string;
    isPublic?: boolean;
    customIcon?: string | unknown;
}

export type TShortcutModalProps = Partial<TF4CreatableModalProps> & {
    component: string;
    title: string;
    tabState: {
        selectedTab: string;
    }
    refresh?: () => void;
    icon?: IconDefinition;
}

const CreateShortcutModal = (props: TShortcutModalProps) => {
    const {openOnMount, viewState: state, component, refresh, title, icon, tabState}= props;
    const formRef = useF4FormRef();

    const {currentUser} = useUserAuth();
    const currentUserID = currentUser?._id;

    return <F4CreatableModal hideButtonsTopBorder
        viewState={state}
        ref={formRef}
        openOnMount={openOnMount}
        onSave={refresh}
        title={strings('shortcut')}
        formKey="shortcut"
        extra={{state}}
        className="!w-2/5 min-w-[35rem] !h-[30%] !min-h-fit"
        storeFunction={async (props : TShortcutData) => {
            if (!props.name) props.name = strings(`${title}`);
            const shortcutData = {
                ...props,
                component,
                title,
                props: tabState,
                creatorId: currentUserID,
            };
            const res = await FoldersService.createShortcut(shortcutData, null);

            return {
                isSuccessful: () => res.isSuccessful(),
                getData: () => res.getData(),
            };
        }}
        createSuccessMessage={strings('create_shortcut_successfully')}
        errorMessage={strings('create_shortcut_failed')}
    >
        <F4FormDiv className="flex flex-col gap-4 min-h-fit">
            <F4FormDiv className="flex justify-start items-center w-full gap-2" colSpan={12}>
                <Label text={strings('shortcut_for')} color="neutral-600" size="xs" fontWeight="semibold" className="min-w-fit"/>
                <F4FormDiv className="bg-neutral-100 py-2.5 px-3 rounded-xl w-fit flex justify-center items-center gap-2">
                    <Icon icon={icon} color="neutral-500"/>
                    <Label
                        size="xs"
                        color="neutral-600"
                        fontWeight="medium"
                        text={title}
                    />
                </F4FormDiv>
            </F4FormDiv>

            <F4FormDiv className="flex items-end gap-4">
                <Button isGhost
                    icon={faCircleArrowUpRight}
                    onClick={()=>{
                        AskForModal<TImageColorSelectorProps>(ImageOrColorSelectorModal, {
                            colorFormName: 'customColor',
                            imageFormName: 'customIcon',
                            setValue: formRef.current?.setValue,
                        });
                    }}
                    className="bg-primary-25 !p-4"
                    size="2xl"
                />
                <F4Textbox
                    name="name"
                    type="text"
                    placeholder={title}
                    label={strings('name')}

                />
            </F4FormDiv>

            <F4ToggleSwitch
                name="isPublic"
                size="sm"
                descriptionClassName=" !text-neutral-700 !font-medium"
                description={strings('public_shortcut')}
                colSpan={12}

            />
        </F4FormDiv>
    </F4CreatableModal>;
};

export default CreateShortcutModal;

import {Icon} from '@aktek/f4kit';
import {faBuilding, faClone, faGears, faTurnRight} from '@fortawesome/pro-regular-svg-icons';
import {
    faBarChart,
    faDashboard, faDatabase, faDiagramProject, faDisplayChartUp, faGear,
    faHome, faLock, faNetworkWired, faSatellite, faTable,
    faUser} from '@fortawesome/pro-regular-svg-icons';
import {IconDefinition} from '@fortawesome/pro-regular-svg-icons';

import Explorer from '@/app//Explorer/Explorer';
import AKTEKiQ from '@/app//iQ/iQ';
import Users from '@/app/Admin/AccessControl/Users/Users.View';
import Admin from '@/app/Admin/Admin';
import Agency from '@/app/Admin/Agency/Agency';
import UsageMetrics from '@/app/Admin/UsageMetrics/UsageMetrics';
import Analysis from '@/app/Analysis/Analysis';
import Dashboards from '@/app/Analysis/pages/Dashboards';
import Networks from '@/app/Analysis/pages/Networks';
import DataTableView from '@/app/DataTable/DataTable.View';
import AskForModal from '@/asks/AskForModal';
import NavigationPagesViewer from '@/components/Navigation/Navigation.PagesViewer';
import NavigationViewer from '@/components/Navigation/Navigation.Viewer';
import Env from '@/config/env';
import {useGlobalFilesAndFolders} from '@/context/UserContext/Hooks/useGlobalFilesAndFolders';
import {CopyToClipboard} from '@/helpers/CopyToClipboard.fn';
import {strings} from '@/localization/i18n';

import Groups from '../Admin/AccessControl/Groups/Groups.View';
import RolesAndPermissions from '../Admin/AccessControl/RolesAndPermissions/RolesAndPermissions.View';
import TAC from '../Admin/AccessControl/TAC/TAC';
import Advanced from '../Admin/Advanced/Advanced';
import DeleteAllData from '../Admin/Advanced/pages/DeleteAllData/DeleteAllData';
import RestoreFields from '../Admin/Advanced/pages/RestoreFields/RestoreFields';
import Appearance from '../Admin/CompanySettings/pages/Appearance/Appearance';
import Settings from '../Admin/CompanySettings/pages/Settings/Settings';
import Tags from '../Admin/CompanySettings/pages/Tags/Tags.View';
import SBOEditor from '../Admin/Data/pages/DataStructure/components/SBOEditor';
import DataStructureTable from '../Admin/Data/pages/DataStructure/DataStructureTable';
import GeoRepository from '../Admin/GeoRepository/GeoRepository';
import iOJobs from '../Admin/iOJobs/iOJobs';
import Licensing from '../Admin/Licensing/Licensing';
import CreateShortcutModal, {TShortcutModalProps} from './components/CreateShortcutModal';
// export const TabsManager: TtabsManager = {};

type TTabDefinition = {component: React.ComponentType, title: string, icon: string | IconDefinition};

export const Tabs: Record<string, TTabDefinition> = {
    home: {
        component: Explorer,
        title: strings('home'),
        icon: faHome,
    },
    admin: {
        component: Admin,
        title: strings('admin'),
        icon: faUser,
    },
    deleteAllData: {
        component: DeleteAllData,
        title: 'Nav',
        icon: faUser,
    },
    restoreFields: {
        component: RestoreFields,
        title: 'Nav',
        icon: faUser,
    },
    navigationPagesViewer: {
        component: NavigationPagesViewer,
        title: 'Nav',
        icon: faUser,
    },
    navigationViewer: {
        component: NavigationViewer,
        title: 'Nav',
        icon: faUser,
    },

    // accessControl: {
    //     component: RolesAndPermissions,
    //     title: strings('access_control'),
    //     icon: faLock,
    // },
    rolesAndPermissions: {
        component: RolesAndPermissions,
        title: strings('access_control'),
        icon: faLock,
    },
    users: {
        component: Users,
        title: strings('users'),
        icon: faUser,
    },
    groups: {
        component: Groups,
        title: strings('groups'),
        icon: faLock,
    },
    tac: {
        component: TAC,
        title: strings('TAC'),
        icon: faLock,
    },

    dataStructure: {
        component: DataStructureTable,
        title: strings('data'),
        icon: faDatabase,
    },
    analysis: {
        component: Analysis,
        title: strings('analysis'),
        icon: faDisplayChartUp,
    },
    aktekIQ: {
        component: AKTEKiQ,
        title: strings('aktek_iq'),
        icon: faSatellite,
    },
    settings: {
        component: Settings,
        title: strings('settings'),
        icon: faGear,
    },
    appearance: {
        component: Appearance,
        title: strings('appearance'),
        icon: faGear,
    },
    tags: {
        component: Tags,
        title: strings('tags'),
        icon: faGear,
    },

    usageMetrics: {
        component: UsageMetrics,
        title: strings('usage_metric'),
        icon: faBarChart,
    },
    agency: {
        component: Agency,
        title: strings('agency'),
        icon: faBuilding,
    },
    licensing: {
        component: Licensing,
        title: strings('licensing'),
        icon: faGears,
    },
    dashboards: {
        component: Dashboards,
        title: strings('dashboards'),
        icon: faDashboard,
    },
    networks: {
        component: Networks,
        title: strings('networks'),
        icon: faNetworkWired,
    },
    iOJobs: {
        component: iOJobs,
        title: strings('io_jobs'),
        icon: faNetworkWired,
    },
    advanced: {
        component: Advanced,
        title: strings('advanced_sections'),
        icon: faGears,
    },
    geoRepository: {
        component: GeoRepository,
        title: strings('geo_repository'),
        icon: faDiagramProject,
    },
    dataStructureEditor: {
        component: SBOEditor,
        title: strings('data_structure_editor'),
        icon: faDatabase,
    },
    dataTable: {
        component: DataTableView,
        title: strings('data_table'),
        icon: faTable,
    },
} as const;

export type TIOTab = keyof typeof Tabs;

const getComponent= ({component, config = {}}) => {
    const {props = {}} = config;
    const Component = Tabs[component]?.component;
    if (!Component) return null;

    // return <Component {...props} />;
    return {
        Component,
        props,
    };
};

const getDropDownOptions = (node) => {
    const component = node.component;
    const title = Tabs[node.name]?.title;
    const icon = Tabs[node.name]?.icon;
    const state = node.state;
    const shortcut = node.getShortcut();
    const {refresh} = useGlobalFilesAndFolders();

    return [
        {
            label: strings('create_a_new_shortcut'),
            option: 'Icon',
            icon: {icon: faTurnRight},
            onClick: () => {
                AskForModal<TShortcutModalProps>(CreateShortcutModal, {
                    component,
                    title,
                    tabState: state,
                    refresh,
                    icon,
                });
            },
        },
        {
            label: strings('copy_direct_link'),
            option: 'Icon',
            icon: {icon: faClone},
            onClick: () => {
                if (!shortcut) return;
                const envURL = Env.baseURL;
                const url = `${envURL}/#/shortcut/${shortcut}`;
                CopyToClipboard(url);
            },
        },
    ];
};

const getTitle= ({name, title}) => {
    return title || Tabs?.[name]?.title || name || '';
};

const getIcon= ({name}) => <Icon icon={Tabs?.[name]?.icon || faDatabase} />;

const avatar ={
    alt: 'User Avatar',
    src: 'https://www.aktek.io/hubfs/Brand%20Assets/logo%20AKTEK%20black.png',
};

export const TabFactory = {getComponent, getTitle, getIcon, getDropDownOptions};


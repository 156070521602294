import {Icon, Label, Tag, TContextMenuEntry} from '@aktek/f4kit';
import {faTable} from '@fortawesome/pro-regular-svg-icons';

import AskForMenu from '@/asks/AskForMenu';
import {usePermissions} from '@/context/Permissions/usePermissions';
import {useIODict} from '@/iohub/hooks/useIODict';

import {getOpenEntry, openDataTable} from './Actions';

export const RefFieldButton = ({sboId, fieldId, value}) =>{
    const permissions = usePermissions();

    if (!value) return null;
    const {dict: sboDict} = useIODict({type: 'sbo'});

    const {dict} = useIODict({type: 'field', sboId});

    const {dict: refDict} = useIODict({type: 'ref', sboId, displayFieldId: fieldId});

    if (!sboDict || !dict || !refDict) return null;

    const label = refDict[value];
    const field = dict?.[fieldId];
    if (!field) return null;

    const sboLabel = sboDict?.[sboId] as string;
    // const label = field.label as string;

    const entries: TContextMenuEntry[] = [
        getOpenEntry(sboLabel, sboId),
    ];

    return <div className="flex items-center gap-1 pointer gap-2"
        onContextMenu={(event) => {
            AskForMenu(event, entries);
        }}
        onDoubleClick={() => openDataTable(sboLabel, sboId)}>

        <Label
            className="underline cursor-pointer"
            text={label}
        />
    </div>;
};

import NavigationCategory from '@/components/Navigation/Navigation.Viewer.Category';
import NavigationPageContainer from '@/components/Navigation/Navigation.Viewer.PageContainer';
import NavigationSection from '@/components/Navigation/Navigation.Viewer.Section';

import {TNavigationViewerProps} from './types/Navigation.t';

function NavigationViewer({sections}: TNavigationViewerProps) {
    return (
        <NavigationPageContainer>
            {sections.map((section) => (section.isVisible !== false) && (
                <NavigationSection label={section.sectionTitle} key={section.sectionTitle}>
                    {section.categories?.map((category) => (
                        <NavigationCategory
                            key={category.tabKey}
                            {...category}
                        />
                    ))}
                </NavigationSection>
            ))}
        </NavigationPageContainer>
    );
}

export default NavigationViewer;

/* eslint-disable camelcase */
import {PermissionsService} from '@aktek/helios-frontend-sdk';
import {useEffect, useState} from 'react';

import {toCamelCase} from '@/helpers/strings.fn';
import {AdminPermissionsGroup, AppPermissionsGroup, TPermissions} from '@/types/Permissions.t';
import {TPermission} from '@/types/Permissions.t';

import {ComputeHelperPermissions} from './ComputeHelperPermissions.fn';
import {PermissionsManager} from './PermissionsManager';

export type TIOPermissions = {
    permissions: TPermissions
    helperPermissions: ReturnType<typeof ComputeHelperPermissions>;
    isLoading: boolean
}

type TUsePermissionsLoaderArgs = {
    userId: string
    companyId: string
}

const defaultState: TIOPermissions = Object.freeze({
    isLoading: true,
    helperPermissions: {},
    permissions: {app: {} as AppPermissionsGroup, admin: {} as AdminPermissionsGroup},
});

const usePermissionsLoader = ({userId, companyId}: TUsePermissionsLoaderArgs): TIOPermissions => {
    const [permissionState, setPermissionState] = useState<TIOPermissions>(defaultState);

    useEffect(()=>{
        if (!userId || !companyId) {
            setPermissionState(defaultState);

            return;
        }

        fetch();
    }, [userId, companyId]);

    const fetch = async () => {
        PermissionsManager.clear();
        setPermissionState(defaultState);

        const response = await PermissionsService.getFusionPermissions();

        if (!response.isSuccessful()) {
            return null;
        }

        const data = response.getData() as Array<TPermission>;

        const ioPermissions: TPermissions = data.reduce((acc, permission) => {
            const {can_view, can_edit, can_create, category} = permission;
            const camelCaseName = toCamelCase(permission.name);

            // Pre-compute permission combinations
            const canView = can_view || false;
            const canEdit = can_edit || false;
            const canCreate = can_create || false;

            permission.viewOrEdit = canView || canEdit;
            permission.viewOrCreate = canView || canCreate;
            permission.viewOrCreateOrEdit = canView || canCreate || canEdit;

            // @ts-expect-error We don't have a guarantee that the TPermission has all the keys needed
            acc[category] ??= {};
            acc[category][camelCaseName] = permission;

            return acc;
        }, {} as TPermissions);

        PermissionsManager.setPermissions(ioPermissions);
        const helperPermissions = ComputeHelperPermissions(ioPermissions);
        setPermissionState({isLoading: false, helperPermissions, permissions: ioPermissions});
    };

    return permissionState;
};

export default usePermissionsLoader;

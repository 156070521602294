import {faListTimeline, faPen, faTrash} from '@fortawesome/pro-regular-svg-icons';

import {strings} from '@/localization/i18n';

import {TTableRowKnownActions} from '../Types/Table.t';
import {TTableRowAction} from '../Types/TableActionMenuEntry.t';

export const RowKnownActions : Record<TTableRowKnownActions, TTableRowAction> = {
    'edit': {
        key: 'edit',
        showOnRow: true,
        label: strings('edit'),
        icon: faPen,
    },

    'delete': {
        key: 'delete',
        showOnRow: true,
        label: strings('delete'),
        icon: faTrash,
        color: 'error-600',
    },

    'viewLogs': {
        key: 'viewLogs',
        label: strings('view_logs'),
        icon: faListTimeline,
        color: 'neutral-600',
    },

};


const testValue2 = {
    'main': {
        'id': 'main',
        'operator': '$and',
        'type': 'group',
        'rules': {
            '6audZFGZ29BVTCZ5Bh2ddA': 1,
            'j1APe4msnt5Nc12VEy5sqe': 1,
        },
    },
    '6audZFGZ29BVTCZ5Bh2ddA': {
        'id': '6audZFGZ29BVTCZ5Bh2ddA',
        'type': 'rule',
        'operator': '$eq',
        'fieldId': 'grCmx',
    },
    'j1APe4msnt5Nc12VEy5sqe': {
        'id': 'j1APe4msnt5Nc12VEy5sqe',
        'type': 'group',
        'operator': '$and',
        'rules': {
            '8tknMSFeQ6gXd5jTZtYcNo': 1,
            'vbD11Hzhm3YW4JM5QqxRia': 1,
        },
    },
    '8tknMSFeQ6gXd5jTZtYcNo': {
        'id': '8tknMSFeQ6gXd5jTZtYcNo',
        'type': 'rule',
    },
    'vbD11Hzhm3YW4JM5QqxRia': {
        'id': 'vbD11Hzhm3YW4JM5QqxRia',
        'type': 'rule',
    },
};

/*
const testValue = {
    'main': {
        'id': 'main',
        'operator': '$and',
        'type': 'group',
        'rules': {

        },
    },
};*/

export default testValue2;

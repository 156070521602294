import {CellByType} from '../CellByType';
import {getActionsColumnDefFromRowActions} from '../Helpers/GetActionsColumnDefFromRowActions';
import {setUpSelectableColumnCell} from '../Helpers/setUpSelectableColumn';
import {setUpSortableColumn} from '../Helpers/setUpSortableColumn';
import {TStatelessTableProps} from '../Types/Table.t';

export const getUpdateColumns = ({
    state,
    columns,
    sorting,
    onRowAction,
    rowActions,
}: Partial<TStatelessTableProps>) => {
    const _columns:TStatelessTableProps['columns'] = columns.reduce((result, columnDef) => {
        const {isSelectable, isSortable, type, renderCell, renderHeader, ...rest} = columnDef;

        const columnCreator = CellByType[type];
        if (!columnCreator) return result;
        const baseColumn = CellByType[type](columnDef);

        Object.assign(baseColumn, rest);

        if (isSelectable) setUpSelectableColumnCell(baseColumn, columnDef);
        if (isSortable) setUpSortableColumn(baseColumn, columnDef, sorting);

        if (renderCell) {
            baseColumn.cell = renderCell;
        }

        if (renderHeader) {
            baseColumn.header = renderHeader;
        }

        result.push(baseColumn);

        return result;
    }, []);

    if (rowActions) {
        const actionColumn = getActionsColumnDefFromRowActions({state, onRowAction, rowActions});
        actionColumn && _columns.push(actionColumn);
    }

    return _columns;
};

import React from 'react';

import useForceUpdate from '@/hooks/useForceUpdate';

import IOCache from './IOCache';

export type TIOCacheContext = {
    exists: (key: string) => boolean;
    setErrorState: (key: string, isErrorState: boolean) => void;
    getErrorState: (key: string) => boolean;
    getLoadingState: (key: string) => boolean;
    setLoadingState: (key: string, hasError: boolean) => void;
    getObjectLastUpdateTime: (key: string) => number;
    updateObjectCacheTime: (key: string,) => void;
    cacheObject: (key: string, object: { lastClientCacheTime: number, object: object }) => void;
    getObject: (key: string) => { lastClientCacheTime: number, object: object };
    printCache: () => void;
}

const IOCacheContext = React.createContext<TIOCacheContext>(null);

function IOCacheContextProvider(props) {
    const forceUpdate = useForceUpdate()[1];

    const {getErrorState, getLoadingState, exists, getObject, getObjectLastUpdateTime, printCache} = IOCache;

    const setLoadingState = React.useCallback((key, isLoading) => {
        IOCache.setLoadingState(key, isLoading);
        forceUpdate();
    }, []);

    const setErrorState = React.useCallback((key, hasError) => {
        IOCache.setErrorState(key, hasError);
        forceUpdate();
    }, []);

    const cacheObject = React.useCallback((key, object) => {
        IOCache.cacheObject(key, object);
        forceUpdate();
    }, []);

    const updateObjectCacheTime = React.useCallback((key) => {
        const isUpdated = IOCache.updateObjectCacheTime(key);
        if (isUpdated) forceUpdate();
    }, []);

    return (
        <IOCacheContext.Provider
            value={{
                exists, setErrorState, getErrorState, getLoadingState, setLoadingState,
                getObjectLastUpdateTime, updateObjectCacheTime, cacheObject,
                getObject, printCache,
            }}>
            {props.children}
        </IOCacheContext.Provider>
    );
}

export {IOCacheContextProvider, IOCacheContext};

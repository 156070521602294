
const image = 'rounded-sm !w-full !h-full !object-cover';
const label = 'cursor-pointer truncate !text-neutral-900';
const imageContainer = 'w-6 h-6 border border-neutral-200 rounded-md';
const container = 'grid gap-6 w-full max-w-none sm:grid-cols-3 lg:grid-cols-4';
const card = 'flex items-center p-4 rounded-lg border-neutral-200 hover:border-neutral-300 gap-3 cursor-pointer border';

export const GetFoldersRightPanelHeaderClasses = () => ({
    container,
    image,
    label,
    imageContainer,
    card,
});

import 'react-virtualized/styles.css';

import _get from 'lodash/get';
import _set from 'lodash/set';

import {ACLStatus} from '../constants/ACLPositions';
import ScopesACL from '../constants/ScopesACL';
import {TPermissionsProps} from '../types/RolesAndPermissionProps.t';

const updatePermissions = (permissions: TPermissionsProps | object, toggleProps, value) => {
    const {category, aclType, selectedSection, path} = toggleProps;
    if (!category) return permissions;
    const sections = ['admin', 'app', 'dashboard', 'report'];

    const setPermissionAndChildren = (permission, aclType, value) => {
        permission[aclType] = value;

        if (!permission.children) return;

        permission.children.forEach((child) => {
            setPermissionAndChildren(child, aclType, value);
        });
    };

    const setAllPermissionAndChildren = (permission, aclType, value) => {
        aclType.forEach((type) => {
            permission[type] = value;
        });

        if (!permission.children) return;

        permission.children.forEach((child) => {
            setPermissionAndChildren(child, aclType, value);
        });
    };

    const updatedPermissions = {...permissions};

    if (category === 'horizontal') {
        const ACLs = ScopesACL[selectedSection].acls;
        const permission = _get(updatedPermissions, path);

        ACLs.forEach((acl) => {
            if (permission[acl] === ACLStatus.disabled) return;

            _set(updatedPermissions, `${path}.${acl}`, value);
        });

        return updatedPermissions;
    }

    if (category === 'vertical') {
        const children = _get(updatedPermissions, path);

        children.forEach((child) => {
            setPermissionAndChildren(child, aclType, value);
        });

        return updatedPermissions;
    }

    if (category === 'vertical_all') {
        updatedPermissions[selectedSection].forEach((permissionRow) => {
            setPermissionAndChildren(permissionRow, aclType, value);
        });

        return updatedPermissions;
    }

    if (category === 'all') {
        updatedPermissions[selectedSection].forEach((permissionRow) => {
            setAllPermissionAndChildren(permissionRow, ScopesACL[selectedSection]?.acls, value);
        });

        return updatedPermissions;
    }

    if (category === 'superadmin') {
        sections.forEach((type) => {
            updatedPermissions[type]?.forEach((permissionRow) => {
                setAllPermissionAndChildren(permissionRow, ScopesACL[type]?.acls, value);
            });
        });

        return updatedPermissions;
    }

    return updatedPermissions;
};

export default updatePermissions;

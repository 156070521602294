/* eslint-disable react/display-name */
import type {TButtonProps, TContextMenuEntry} from '@aktek/f4kit';
import {faEllipsisVertical} from '@fortawesome/pro-regular-svg-icons';
import {Row} from '@tanstack/react-table';
import {useMemo} from 'react';

import AskForMenu from '@/asks/AskForMenu';

import {GetActionsFromRowActions} from '../Helpers/GetActionsFromRowActions';
import {TOnRowAction} from '../Types/ActionButton.t';
import {TRowActions, TStatelessTableProps} from '../Types/Table.t';
import ActionButton from './ActionButton';
const defaultButtonProps : Partial<TButtonProps> ={
    isOutlined: false,
    isGhost: true,
    variant: 'neutral',
};

export const CellActions = <RowType, >(
    rowActions: TStatelessTableProps['rowActions'],
    state,
    onRowAction: TOnRowAction,
) => ({row}: { row: Row<RowType> }) => {
        const {tableActions, contextMenuEntries} = useMemo(() => {
            const allActions = GetActionsFromRowActions(rowActions, row);
            const tableActions: TRowActions = [];
            const contextMenuEntries: TContextMenuEntry[] = [];

            allActions.forEach((action) => {
                if (action.isVisible === false) return;

                if (action.showOnRow ) {
                    tableActions.push(action);
                } else {
                    contextMenuEntries.push({
                        ...action,
                        onClick: (e) => {
                            onRowAction(row, action, e, state);
                        },

                    });
                }
            });

            return {tableActions, contextMenuEntries};
        }, [rowActions, row]);

        return <div className="flex flex-row gap-1 justify-end">
            {tableActions.map((action) => {
                return <ActionButton
                    defaultButtonProps={defaultButtonProps}
                    key={action.key}
                    action={action}
                    onClick={(e) => {
                        onRowAction(row, action, e, state);
                    }}
                />;
            })}
            {contextMenuEntries.length > 0
            && <ActionButton
                defaultButtonProps={defaultButtonProps}
                action={{key: 'more', icon: faEllipsisVertical, label: 'More'}}
                onClick={(e) => {
                    AskForMenu(e, contextMenuEntries, {placement: 'bottom'});
                }} />}
        </div>;
    };


import {Button, ContextMenu, Label} from '@aktek/f4kit';
import {faTrash} from '@fortawesome/pro-regular-svg-icons';

import {TField, TFieldTypes, TSection} from '../types/SBOEditor.t';

type TSectionsFieldsPopoverTypesProps ={
    addSection?:(duplicatedSection?:TSection, fieldId?: string)=> void
    addField?:(fieldType: TFieldTypes, sectionId: string, duplicatedField?:TField)=> void
    section?: TSection

}

export default function SectionsFieldsPopoverTypes(props: TSectionsFieldsPopoverTypesProps) {
    const sectionId = props.section.id;

    const removePopoverContainer = ()=>{
        const popupcontainer = document.getElementById('popupcontainer');
        popupcontainer && popupcontainer.remove();
    };

    return (
        <ContextMenu >
            <div className="w-full py-4 px-1 flex flex-col gap-1">
                <Label text="Section" color="neutral-500" fontWeight="semibold"/>
                <Button isGhost edgeIcons={{left: {icon: faTrash}}} label={'New section'}
                    className="w-full min-w-52" textColor="neutral-900" justifyContent="start" onClick={()=>{
                        props.addSection();
                        removePopoverContainer();
                    }}/>

                <hr className="my-2 text-neutral-200"/>

                <Label text="Basic" color="neutral-500" fontWeight="semibold"/>
                <Button isGhost edgeIcons={{left: {icon: faTrash}}} label={'Free Text'}
                    className="w-full min-w-52" textColor="neutral-900" justifyContent="start" onClick={()=>{
                        props.addField('string', sectionId);
                        removePopoverContainer();
                    }}/>
            </div>
        </ContextMenu>
    );
}

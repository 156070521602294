import {Button, ButtonColorPicker, Modal, TabNav, TModalProps, TModalRef, useModalRef, usePropState} from '@aktek/f4kit';
import cn from 'classnames';
import _compact from 'lodash/compact';
import {forwardRef, useMemo} from 'react';

import {strings} from '@/localization/i18n';

import {ImageUploader} from '../F4Elements/F4ImageUploader';

export type TImageColorSelectorValueType = 'image' | 'color';

export type TImageColorSelectorValue = {
    type:TImageColorSelectorValueType,
    value: string;
}

export type TImageColorSelectorProps = {
    modalProps?: Partial<TModalProps>;
    className?: string;
    availableTypes?: TImageColorSelectorValueType[];
    onChange: (value: TImageColorSelectorValue) => void;
    value: TImageColorSelectorValue;
}

const defaultValue: TImageColorSelectorValue = {type: 'image', value: null};

function ImageOrColorSelectorModal(props: TImageColorSelectorProps, ref: TModalRef) {
    const {className, modalProps={size: 'xs'}, availableTypes=['image', 'color'], onChange, value = defaultValue} = props;

    const modalRef = useModalRef(ref);
    const [finalValue, setValue] = usePropState<TImageColorSelectorValue>(value, defaultValue);

    const tabOptions = useMemo(()=> _compact([
        availableTypes.includes('image') && {
            key: 'image',
            label: strings('image'),
        },
        availableTypes.includes('color') && {
            key: 'color',
            label: strings('color'),
        },
    ]), [availableTypes]);

    const onConfirm = () => {
        onChange(finalValue);
        modalRef.current.close();
    };

    const onCancel = () => modalRef.current.close();

    return <Modal
        ref={ref}
        hasXIcon={false}
        headerClassName="hidden"
        className={cn('overflow-hidden !rounded-3xl p-6 max-w-[32rem]', className)}
        title={null}
        {...modalProps}>
        <div className="flex flex-col">
            <div className="flex items-center justify-between w-full gap-4">
                <TabNav
                    value={finalValue.type}
                    onChange={(tab: TImageColorSelectorValueType)=>{
                        // setTab(e);
                        setValue({type: tab, value: null});
                    }}
                    tabs={tabOptions}
                    orientation={'horizontal'}
                />
                <Button isGhost
                    label={'Remove'}
                    variant="neutral"
                    onClick={()=>{
                        setValue(defaultValue);
                    }}
                />
            </div>
            <div className="h-40">

                {finalValue.type == 'image'
                    && <ImageUploader
                        label={strings('click_to_upload')}
                        maxSizeMB={1}
                        allowedTypes={['image/jpeg', 'image/png', 'image/jpg']}
                        description={strings('click_to_upload_desc')}
                        value={finalValue.value}
                        onChange={(image)=> setValue({type: finalValue.type, value: image})}
                    />}

                {finalValue.type == 'color'
                && <ButtonColorPicker
                    size="3xl"
                    defaultValue="#444444"
                    value={finalValue.value}
                    onChange={(color)=> setValue({type: finalValue.type, value: color})}
                />
                }
            </div>

            <div className="flex mt-6 w-full gap-4">
                <Button
                    label={strings('cancel')}
                    variant="white"
                    className="w-1/2"
                    onClick={onCancel}
                />
                <Button
                    label={strings('confirm')}
                    className="w-1/2"
                    onClick={onConfirm}
                />
            </div>
        </div>
    </Modal>;
}

export default forwardRef(ImageOrColorSelectorModal);


import {PermissionsService} from '@aktek/helios-frontend-sdk';
import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';
import {useEffect, useState} from 'react';

import {getSelectedCheckbox} from '../helpers/generateSaveablePermission';
import {storingToF4Form} from '../helpers/storingToF4Form';

const usePermissions = (roleId, f4FormContext, initialIsSuperAdmin, viewState) => {
    const [loading, setLoading] = useState(false);
    const [_permissions, setPermissions] = useState(null);
    const isSuperAdmin = f4FormContext.data?.['overridePermissions'];

    const setIsSuperAdmin = (value) => {
        f4FormContext.setValue('overridePermissions', value);
    };

    const [canResetPassword, setCanResetPassword] = useState(false);
    const [updatedPermissionsState, _setUpdatedPermissionsState] = useState({
        key: Date.now(),
        permissions: null,
    });

    useEffect(() => {
        if (_isEqual(getSelectedCheckbox(_cloneDeep(_permissions)), getSelectedCheckbox(updatedPermissionsState.permissions))
            && !!isSuperAdmin == !!initialIsSuperAdmin) {
            setCanResetPassword(false);

            return;
        }

        setCanResetPassword(true);
    }, [updatedPermissionsState.permissions, isSuperAdmin, initialIsSuperAdmin]);

    useEffect(() => {
        if (initialIsSuperAdmin) {
            setIsSuperAdmin(true);
        }
    }, [initialIsSuperAdmin]);

    const setUpdatedPermissionsState = (permissions, key = updatedPermissionsState.key) => {
        _setUpdatedPermissionsState({permissions, key});
    };

    const resetPermissions = () => {
        setLoading(true);
        setUpdatedPermissionsState(_cloneDeep(_permissions), Date.now());
        storingToF4Form(_cloneDeep(_permissions), f4FormContext);
        setIsSuperAdmin(initialIsSuperAdmin);
        f4FormContext.setValue('overridePermissions', initialIsSuperAdmin);
        setLoading(false);
    };

    const fetch = async () => {
        if (!roleId && viewState?.mode === 'edit') {
            return;
        }

        setLoading(true);
        const response = await PermissionsService.getRolePermissions(roleId || null);

        if (!response.isSuccessful()) return setLoading(false);

        const permissionsRes = response.getData();

        setPermissions(_cloneDeep(permissionsRes));
        setUpdatedPermissionsState(permissionsRes, Date.now());
        setLoading(false);
    };

    useEffect(() => {
        fetch();
    }, [roleId]);

    return {
        permissions: _permissions, setPermissions,
        updatedPermissionsState, setUpdatedPermissionsState,
        resetPermissions,
        loading,
        isSuperAdmin, setIsSuperAdmin,
        canResetPassword,
    };
};

export default usePermissions;

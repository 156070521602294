import {useEffect} from 'react';
import {Navigate, Outlet, useParams} from 'react-router-dom';

import {useGlobalPath} from '@/context/UserContext/Hooks/useGlobalPath';
import {useUserToken} from '@/context/UserContext/Hooks/useUserToken';

import Path from './Path';

const PrivateRoutes =()=>{
    const isUserSignIn = useUserToken();

    const {shortcut, folder, companyId} = useParams();
    const {setPath} = useGlobalPath();

    useEffect(() => {
        if (shortcut) setPath('shortcut/' + shortcut);

        if (folder) setPath(`${folder}/${companyId}`);
    }, [shortcut, folder]);

    return (
        isUserSignIn ? <Outlet /> : <Navigate to={Path.Login} replace/>
    );
};

export default PrivateRoutes;

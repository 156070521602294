import RolesV4Service from '@aktek/helios-frontend-sdk/out/RolesV4Service';
import {useCallback} from 'react';

import F4ViewableComponent from '@/components/F4ViewableComponent/F4ViewableComponent';
import useTableRef from '@/components/Table/Hooks/useTableRef';
import {useAdminPermissions} from '@/context/Permissions/usePermissions';
import {strings} from '@/localization/i18n';

import CreateRoleAndPermissionModal from './components/CreateRoleAndPermissionModal';
import {GetRolesRowActions, ROLES_COLUMNS} from './RolesAndPermission.GetRowActions';
import {TRole} from './types/RolesAndPermissionProps.t';

function RolesAndPermissions() {
    const tableRef = useTableRef();
    const {roles: rolesPermission} = useAdminPermissions();

    const getRolesRowActions = useCallback(() => {
        return GetRolesRowActions({rolesPermission});
    }, [rolesPermission]);

    return (
        <F4ViewableComponent<TRole>
            name={strings('role')}
            key="rolesandpermission"
            tableRef={tableRef}
            tableColumns={ROLES_COLUMNS()}
            defaultSortedColumn="name"
            emptyProps={{addLabel: strings('new_role')}}
            rowActions={getRolesRowActions()}
            canCreate={rolesPermission?.can_create}
            modal={CreateRoleAndPermissionModal}
            getAllData={RolesV4Service.getAllRoles}
            deleteItem={RolesV4Service.deleteRole}
            deleteSuccessMessage={strings('delete_role')}
            idColumnName="id"
        />
    );
}

export default RolesAndPermissions;

import {Button, FitHeight, Modal, toast, useModalRef} from '@aktek/f4kit';
import {UsageService} from '@aktek/helios-frontend-sdk';
import {useEffect} from 'react';

import {TResponse} from '@/components/F4ViewableComponent/types/F4ViewableComponentProps.t';
import Table from '@/components/Table/Table';
import {LIMITS_FOR_METRICS} from '@/constants/LicenseFeatures.c';
import {strings} from '@/localization/i18n';

import {USAGE_METRICS_COLUMNS} from '../constants/constants.c';

const UsageMetricsModal = ({companyId, openOnMount, onConfirm, header}) => {
    useEffect(() => {
        getDetailData(companyId);
    });

    const getDetailData = async (companyId) => {
        try {
            const response = await UsageService.getAllUsage(companyId);

            const finalInitialValue = LIMITS_FOR_METRICS.map((item) => ({
                name: item.name,
                value: response?.Body[item.key] !== undefined ? response?.Body[item.key] : 0,
            }));

            return {
                isSuccessful: () => true,
                getData: () => finalInitialValue,
            };
        } catch (error) {
            return {
                isSuccessful: () => false,
                getData: () => null,
            };
        }
    };

    useEffect(()=> {
        if (openOnMount) {
            modalRef.current.open();
        }
    }, []);

    const onConfirmCallback = () => {
        onConfirm?.();
        modalRef.current.close();
    };

    const modalRef = useModalRef();

    return (
        <Modal ref={modalRef} size="xl" title={`Usage Metrics (${header})`}
            headerClassName="!py-4 !px-4  rounded-se-md rounded-s-md" >
            <FitHeight className="h-full w-full p-2 pb-6 overflow-auto">

                <Table
                    noPagination={true}
                    getColumns={
                        async ()=> USAGE_METRICS_COLUMNS
                    }
                    getRows={
                        async () => {
                            const response = await getDetailData(companyId);

                            const data = response.getData() as TResponse;

                            if (!data) { // @TODO: double check this
                                toast.error(strings('error_fetching_data'));

                                return [];
                            }

                            const items = (Array.isArray(data) ? data : data?.data) || [];

                            return items;
                        }
                    }
                />
            </FitHeight>
            <div className="flex justify-end items-end pr-4 py-2">
                <Button label={strings('confirm')} className="w-fit" onClick={onConfirmCallback} />
            </div>
        </Modal>
    );
};

export default UsageMetricsModal;

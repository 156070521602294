import {FindFolderById} from './FindFolderById.fn';

export const FindAncestorsFromNode = (item, array) => {
    const result = [];
    let currentNode = item;

    while (currentNode) {
        result.unshift(currentNode); // Add the current node at the beginning

        if (currentNode.parentId) {
            currentNode = FindFolderById(array, currentNode.parentId);
        } else {
            currentNode = null;
        }
    }

    return result;
};

import {F4Form, useF4FormRef} from '@aktek/f4form';
import {Button, Drawer, FitHeight, toast, useForceUpdate, useTab} from '@aktek/f4kit';
import {SBOService} from '@aktek/morph-frontend-sdk';
import {faDatabase} from '@fortawesome/pro-regular-svg-icons';
import {useEffect, useState} from 'react';
import ShortUniqueId from 'short-unique-id';

import AskForConfirmation from '@/asks/AskForConfirmation';
import AskForFolder from '@/asks/AskForFolder';
import {useGlobalFilesAndFolders} from '@/context/UserContext/Hooks/useGlobalFilesAndFolders';
import {strings} from '@/localization/i18n';
import {openCloseDrawer} from '@/utils/openCloseDrawer';
import ls from '@/utils/storage/ls';

import {AddToRecentlyUsedSBOs, GetRecentlyUsedSBOs, STORAGE_KEY} from '../helpers/RecentlyUsedSBOs.fn.';
import {TField, TFieldTypes, TSBO, TSection} from '../types/SBOEditor.t';
import SBOEditorBody from './SBOEditorBody';
import SBOEditorMain from './SBOEditorMain';
import SBOEditorSidebar from './SBOEditorSidebar';

export type TSBOEditorProps ={
    sboId: string;
    refreshTable?: ()=>Promise<void>
}

export default function SBOEditor(props: TSBOEditorProps) {
    const sboFormRef = useF4FormRef();
    const isCreatingDataStructure = !props.sboId;
    const {refresh} = useGlobalFilesAndFolders();

    const tab = useTab();
    const [isLoading, setIsLoading] = useState(false);
    const [isSBOEdited, setIsSBOEdited] = useState(false);
    const recentUsedSBOs = GetRecentlyUsedSBOs();
    const [currentSBO, setCurrentSBO] = useState<TSBO>({
        folder: null, hasRecordTags: true, areRecordTagsVisibleOnDataTable: false, name: '', _id: null, tags: {},
        sections: ['7nWza'],
        tableFields: [],
        sectionsMap: {'7nWza': {'id': '7nWza'}},
        fieldsMap: {},
    });

    const [, forceUpdate] = useForceUpdate();

    useEffect(() => {
        props?.sboId && fetchSboById();
    }, [props?.sboId]);

    const fetchSboById = async () => {
        setIsLoading(true);
        const response = await SBOService.getCompleteSBOById(props.sboId);
        const responseBody = response.Body as TSBO;
        // for f4form to not return error that tags is null
        responseBody.tags = responseBody.tags ?responseBody.tags : {};

        if (response.isSuccessful()) {
            sboFormRef.current.setValues(responseBody, () => {
                forceUpdate();
            });
            setCurrentSBO(responseBody);
        }

        setIsLoading(false);
    };

    const addSection = (duplicatedSection?: TSection, fieldId?: string) => {
        const id = new ShortUniqueId({dictionary: 'alpha'}).randomUUID(5).toString();
        const newSectionProps = {id, justCreated: true};
        const data = sboFormRef.current.data as TSBO;

        const mutableSBO = Object.assign({}, data);
        mutableSBO.sections.push(id);

        if (duplicatedSection) {
            // Copy and remap fields from the duplicated section
            const newFieldsMap = Object.entries(mutableSBO.fieldsMap).reduce((prev, [key, field]) => {
                if (field.parentSection === duplicatedSection.id) {
                    const newId = new ShortUniqueId({dictionary: 'alpha'}).randomUUID(5).toString();
                    const newField = {...field, id: newId, parentSection: id}; // Copy the field object

                    if (mutableSBO.tableFields.includes(key)) {
                        mutableSBO.tableFields = [...mutableSBO.tableFields, newId];
                    }

                    prev[newId] = newField; // Add the new field to the fieldsMap
                }

                return prev;
            }, {});

            // Merge new fields into the fieldsMap
            Object.assign(mutableSBO.fieldsMap, newFieldsMap);

            // Add the fields to the new section
            const tempFields = Object.keys(newFieldsMap);
            mutableSBO.sectionsMap[id] = {
                ...duplicatedSection,
                id,
                fields: tempFields, // No need to duplicate the first field explicitly
                duplicateOf: duplicatedSection.id,
                ...newSectionProps,
            };
        } else {
            // Adding a new section without duplication
            mutableSBO.sectionsMap[id] = {name: '', fields: fieldId ? [fieldId] : [], ...newSectionProps};
        }

        sboFormRef.current.setValues(mutableSBO, () => {
            forceUpdate();
        });
    };

    const deleteSection = (sectionId: string) => {
        const data = sboFormRef.current.data as TSBO;

        const mutableSBO = Object.assign({}, data);
        delete mutableSBO.sectionsMap[sectionId];

        const index = mutableSBO.sections.indexOf(sectionId);
        if (index > -1) mutableSBO.sections.splice(index, 1);

        // Delete fields under this section
        Object.entries(mutableSBO.fieldsMap).forEach(([id, field]) => {
            if (field.parentSection === sectionId) {
                delete mutableSBO.fieldsMap[id];

                // Remove fields from table view
                const i = mutableSBO.tableFields.indexOf(id);
                if (i > -1) mutableSBO.tableFields.splice(i, 1);
            }
        });

        // new f4 code
        if (mutableSBO.sections.length === 0) {
            mutableSBO.sections = ['7nWza'];
            mutableSBO.sectionsMap = {'7nWza': {'id': '7nWza'}};
        }

        sboFormRef.current.setValues(mutableSBO, () => {
            forceUpdate();
        });
    };

    const addField = (fieldType: TFieldTypes, sectionId: string, duplicatedField?: TField) => {
        const id = new ShortUniqueId({dictionary: 'alpha'}).randomUUID(5).toString();
        const newFieldProps = {id, justCreated: true};
        const data = sboFormRef.current.data as TSBO;
        const newField = duplicatedField
            ? {...duplicatedField, ...newFieldProps}
            : {name: '', parentSection: sectionId, type: fieldType, ...newFieldProps};

        // reference to set one value
        // sboFormRef.current.setValue(`fieldsMap.${id}`, newField);

        // const sectionFields = data.sectionsMap[sectionId].fields || [];
        // sboFormRef.current.setValue(`sectionsMap.${sectionId}.fields`, [...sectionFields, id]);

        // const tableFields = data.tableFields || [];
        // sboFormRef.current.setValue(`tableFields`, [...tableFields, id]);

        const sectionFields = data.sectionsMap[sectionId].fields || [];
        const tableFields = data.tableFields || [];
        const updateObject = {
            [`fieldsMap.${id}`]: newField,
            [`sectionsMap.${sectionId}.fields`]: [...sectionFields, id],
            'tableFields': [...tableFields, id],
        };

        sboFormRef.current.setValues(updateObject, () => {
            forceUpdate();
        });
    };

    const deleteField = (fieldId) => {
        // const dependants = this.getConditionDependants(fieldId);

        // if (dependants.length > 0) {
        //     const msg = <div>
        //         {strings.conditional_field_delete_msg1} <b>{dependants.join(', ')}</b>.
        //         <br/>
        //         {strings.conditional_field_delete_msg2}
        //     </div>;
        //     AlertWarning(strings.unable_to_delete, msg);

        //     return;
        // }
        const data = sboFormRef.current.data as TSBO;

        const mutableSBO = Object.assign({}, data);
        Object.values(mutableSBO.sectionsMap).forEach((section) => {
            section.fields = section.fields.filter((id) => id != fieldId);
        });

        mutableSBO.tableFields = mutableSBO.tableFields.filter((id) => id != fieldId);

        delete mutableSBO.fieldsMap[fieldId];
        sboFormRef.current.setValues(mutableSBO, () => {
            forceUpdate();
        });
    };

    const onMoveFieldToTable = (fieldId: string) => {
        const data = sboFormRef.current.data as TSBO;
        const mutableSBO = Object.assign({}, data);

        if (data.tableFields.indexOf(fieldId) === -1) {
            if (!mutableSBO?.tableFields) mutableSBO.tableFields = [];
            mutableSBO.tableFields.push(fieldId);
        } else {
            const index = mutableSBO.tableFields.indexOf(fieldId);

            if (index !== -1) {
                mutableSBO.tableFields.splice(index, 1);
            }
        }

        sboFormRef.current.setValues(mutableSBO, () => {
            forceUpdate();
        });
    };

    const handleTagsVisibility = () => {
        const data = sboFormRef.current.data as TSBO;

        const mutableSBO = Object.assign({}, data);
        mutableSBO.areRecordTagsVisibleOnDataTable = !data.areRecordTagsVisibleOnDataTable;

        sboFormRef.current.setValues(mutableSBO, () => {
            forceUpdate();
        });
    };

    const handleSave = async () => {
        let validRequest = true;
        const validation = sboFormRef.current.validate();
        const data = sboFormRef.current.data as TSBO;

        if (!data.name) {
            validRequest = false;
            toast.error(strings('data_structure_name_is_required'));
        }

        if (Object.keys(data.fieldsMap).length === 0) {
            validRequest = false;
            toast.error(strings('data_structure_must_have_at_least_one_field'));
        }

        if (validRequest === true && validation.isFormValid) {
            // TODO: here do the tags joined
            // .....

            // creating a new data structure
            let folderResults;

            if (isCreatingDataStructure) {
                folderResults = await AskForFolder();

                setCurrentSBO({...currentSBO, folder: folderResults?.parentId});
            }

            setIsLoading(true);

            const finalResults = {
                ...data,
                folder: folderResults?.parentId || data?.folder || null,
            };

            const createSBO = await SBOService.storeSBO(finalResults);
            const SBO = createSBO.getData() as TSBO;

            if (createSBO.isSuccessful()) {
                recentUsedSBOs.map((item, index) => {
                    if (item.key === props.sboId) {
                        const tab = {
                            key: SBO._id,
                            label: typeof SBO.name !== 'string' ? SBO.name.def : SBO.name,
                            icon: faDatabase,
                        };

                        return recentUsedSBOs[index] = tab;
                    }
                });
                localStorage.setItem(STORAGE_KEY, JSON.stringify(recentUsedSBOs));
                tab.focusOrOpenNewTab('dataStructureEditor', typeof SBO.name !== 'string' ? SBO.name.def : SBO.name, {sboId: SBO._id, refreshTable: () => props?.refreshTable()});
                toast.success(strings('data_structure_is_saved_successfully'));
                const myTab = {
                    key: SBO._id,
                    label: typeof SBO.name !== 'string' ? SBO.name.def : SBO.name,
                    icon: faDatabase,
                };
                AddToRecentlyUsedSBOs(myTab);
                setIsLoading(false);
                setIsSBOEdited(false);
                if (isCreatingDataStructure) refresh?.();
            }
        }

        setIsLoading(false);
    };

    const handleCancel = async () => {
        if (isSBOEdited) {
            const canClose = await AskForConfirmation('Are you sure you want to close this window?',
                'All the data you entered is not saved yet so everything will be discarded.',
                {
                    confirmButtonProps: {variant: 'error', label: strings('discard')},
                    cancelButtonProps: {label: strings('stay_here')},
                },
            );

            if (canClose) {
                tab.close();
            }
        } else tab.close();
    };

    const onSetValue = () => {
        if (!isSBOEdited) setIsSBOEdited(true);
    };

    return (
        <div className="flex h-full">
            <Drawer
                sideWidth={96}
                tooltipDelay={1000}
                defaultOpen={ls.drawer?.dataStructure ?? true}
                onOpenChange={(e) => openCloseDrawer('dataStructure', !!e)}
                buttonSize="sm"
            >

                <SBOEditorSidebar
                    refreshTable={() => props?.refreshTable()}
                    selectedSboId={props?.sboId}
                    recentSBO={recentUsedSBOs}
                    isSBOEdited={isSBOEdited}
                    setIsSBOEdited={setIsSBOEdited}
                />
                <>
                    <FitHeight className="h-full w-full overflow-x-auto bg-neutral-50 p-4 border-l border-neutral-200">
                        <F4Form
                            isLoading={isLoading}
                            formKey="dataStructure"
                            ref={sboFormRef}
                            onSetValue={onSetValue}
                            initialValue={currentSBO || {}}
                            // onChange={() => {
                            //     if (!isSBOEdited) setIsSBOEdited(true);
                            // }}
                            className="h-full px-20 py-2"
                        >
                            <SBOEditorMain sboId={currentSBO._id} />
                            <SBOEditorBody
                                addField={addField}
                                sboFormRef={sboFormRef}
                                addSection={addSection}
                                deleteField={deleteField}
                                deleteSection={deleteSection}
                                onMoveFieldToTable={onMoveFieldToTable}
                                handleTagsVisibility={handleTagsVisibility}
                            />
                        </F4Form>
                    </FitHeight>
                    <div className="w-full bg-neutral-50">
                        <div className="absolute bottom-4 right-6 flex gap-2 bg-neutral-50">
                            <Button isGhost label={strings('cancel')} textColor="neutral-600" onClick={handleCancel} />
                            <Button label={strings('save')} onClick={handleSave} />
                        </div>
                    </div>
                </>
            </Drawer>
        </div>
    );
}

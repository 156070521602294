import {toast} from '@aktek/f4kit';
import {AuthenticationService} from '@aktek/helios-frontend-sdk';
import {useState} from 'react';

import {useSignUserIn} from '@/context/UserContext/Hooks/useSignUserIn';
import {isEventEnterKey} from '@/helpers/Events';
import {strings} from '@/localization/i18n';
import {setCloudFrontCookies} from '@/utils/storage/Cookies';

import {TTwoFactorState} from '../types/twoFactorState.t';

export default function useHandle2FA(twoFactorAuthState: TTwoFactorState, on2FASuccess) {
    const [verificationCode, setVerificationCode] = useState(null);
    const [flag, setFlag] = useState(0);
    const [loading, setLoading] = useState(false);
    const {email, challenge} = twoFactorAuthState;
    const signIn = useSignUserIn();

    const handle2FAKeyDown = (event) => {
        if (!isEventEnterKey(event)) return;

        loginWithTwoFA();
    };

    const loginWithTwoFA = async ()=> {
        setLoading(true);
        const twoFactorAuthResponse = await AuthenticationService.login2FA(email, challenge, verificationCode);
        setLoading(false);

        if (twoFactorAuthResponse.isSuccessful?.()) {
            const data = twoFactorAuthResponse.getData?.();

            if (data?.valid) {
                setCloudFrontCookies(data?.cloudfrontCookie);

                // setAppContext({...appContext, currentUser: data.currentUser});
                signIn(data);

                on2FASuccess?.();

                return;
            }
        }

        setVerificationCode(null);
        setFlag(twoFactorAuthResponse.getData().flag);
        toast.error?.(strings('2fa_failed'));
        setLoading(false);
    };

    return {verificationCode, setVerificationCode, loginWithTwoFA, handle2FAKeyDown, flag, email, loading};
}

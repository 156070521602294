import 'react-virtualized/styles.css';

import {Button} from '@aktek/f4kit';
import _ from 'lodash';
import {useCallback, useEffect, useRef, useState} from 'react';

import AskForConfirmation from '@/asks/AskForConfirmation';
import SearchBox from '@/components/SearchBox/SearchBox';
import {strings} from '@/localization/i18n';

import {TPermissionEditorHeader} from '../types/RolesAndPermissionProps.t';
import PermissionSections from './PermissionSections';

const PermissionEditorHeader = ({
    permissions: propPermissions,
    disabled,
    resetPermissions,
    setSelectedSection,
    selectedSection,
    isSuperAdmin,
    onSuperAdminClick,
    searchValue,
    setSearchValue,
    canResetPassword,
}: TPermissionEditorHeader) => {
    const [permissions, setPermissions] = useState({});

    const tableRef = useRef(null);

    useEffect(() => {
        if (!propPermissions) return;
        setPermissions(propPermissions);
        tableRef.current?.recomputeRowHeights();
    }, [propPermissions]);

    const recomputeTableLayout = useCallback(() => {
        tableRef.current?.recomputeRowHeights();
        tableRef.current?.forceUpdateGrid();
    }, []);

    return (
        <div className="sticky -top-4 elevation-5 !shadow-none bg-white py-4 -mx-1 -mt-4" >
            <SearchBox
                value={searchValue}
                onSearch={(value) => {
                    setSearchValue(value);
                    recomputeTableLayout();
                }}
                textBoxInputClassName="!bg-neutral-100 !text-base !font-normal !text-neutral-900 "
                placeholder={strings('search_permissions')}
                hasBottomBorder={false}
            />
            <div className="flex flex-row items-center justify-between gap-2 -py-4">

                <PermissionSections
                    permissions={permissions}
                    onChange={(e) => {
                        setSelectedSection(e);
                        ls.permissionSection = e;

                        tableRef.current?.recomputeRowHeights();
                    }}
                    value={selectedSection}
                />

                <div className="flex flex-row items-center justify-center gap-3">

                    {!disabled
                            && <Button
                                label={strings('reset_permission')}
                                textColor="neutral-700"
                                variant="white"
                                isDisabled={!canResetPassword}
                                className={`capitalize ml-1 ${!canResetPassword && '!bg-neutral-200'}`}

                                onClick={() => AskForConfirmation(
                                    strings('reset_permission'),
                                    strings('reset_permission_confirm'),
                                    {
                                        onConfirm: resetPermissions,
                                    },
                                )}

                            />}

                    {onSuperAdminClick && !disabled &&<Button
                        label={isSuperAdmin ? strings('remove_super_admin') : strings('make_super_admin')}
                        variant={isSuperAdmin ? 'primary' : 'white'}
                        onClick={() => onSuperAdminClick(!isSuperAdmin)}
                    />}
                </div>
            </div>
        </div>
    );
};

export default PermissionEditorHeader;

import {LicensePlanService} from '@aktek/helios-frontend-sdk';
import {useMemo} from 'react';

import F4ViewableComponent from '@/components/F4ViewableComponent/F4ViewableComponent';
import {strings} from '@/localization/i18n';

import LicensingCreateModal from './components/Licensing.Create.Modal';
import {GetRowLicensingActions} from './helpers/Licensing.GetRowLicensingActions.fn';
import {GetLicensingColumns} from './Licensing.columns';

export const Licensing = () => {
    const getRowActions = useMemo(GetRowLicensingActions, []);

    return (
        <div className="bg-neutral-50 h-full p-4">
            <F4ViewableComponent
                name="license"
                modal={LicensingCreateModal}
                tableColumns={GetLicensingColumns()}
                rowActions={getRowActions()}
                defaultSortedColumn="plan"
                emptyProps={{addLabel: strings('new_license')}}
                deleteSuccessMessage={strings('delete_license_successfully')}
                getAllData={LicensePlanService.getLicensePlans}
            />
        </div>
    );
};

export default Licensing;

import React, {useCallback} from 'react';

import {TTableManagerReturnee} from '../../Spreadsheet/Types/TableManager.t';
import {TTrackedTableData} from '../../Spreadsheet/Types/TrackedTableData';
import {TTableSorting} from '../../Spreadsheet/Types/TTableSorting.t';
import fetchRows from './API/fetchRows';
import getSpreadsheetColumns from './BuildColumns/BuildColumns';
import DefaultState from './MorphSpreadsheet.DefaultState';

const strings = {
    reference_preview: 'Reference Preview',
};

const useMorphSpreadsheet = (props) => {
    const {pageLength = 25} = props;
    const [dataState, setDataState] = React.useState(DefaultState);
    const mergeDataState = (newState: Partial<typeof DefaultState>) => setDataState( (state) => ({...state, ...newState}));

    const [tableState, setTableState] = React.useState<TTrackedTableData>();

    const [currentPage, setCurrentPage] = React.useState(1);
    const editorModalRef = React.useRef<{tableManager:TTableManagerReturnee}>();
    const [search, setSearch] = React.useState(null);
    const [sorting, setSorting] = React.useState<TTableSorting>({});

    const openEditor = (field) => {
        const title = `${strings.reference_preview} ${field.name}`;
        editorModalRef.current?.open({
            sboId: field.sboId,
            fieldName: field.name,
            // morphId: field.id,
            title,
        });
    };

    const getSBO = async (sboId: string) => {
        if (!sboId) return;
        mergeDataState({isSBOLoading: true});
        const res = await getSpreadsheetColumns(sboId, props.mode, openEditor);
        const {columns} = res;
        mergeDataState({columns, isSBOLoading: false});
        _fetchMorphs(sboId);
    };

    const _fetchMorphs = async (sboId: string) => {
        if (props.mode == 'creation') return;
        const page =currentPage;
        if (!sboId) return;
        const key = getKey(props.filter, search, page);
        mergeDataState({isDataLoading: true, dataByKey: {...dataState.dataByKey, [key]: []}});
        const _sorting = {
            ...sorting,
            column: sorting.column == 'ID' ? 'designation': sorting.column,
        };
        const {data, count} = await fetchRows(sboId, _sorting, props.filter, page, pageLength, search);
        const dataByKey = {...dataState.dataByKey, [key]: data};
        mergeDataState({dataByKey, count, isDataLoading: false});
    };

    React.useEffect(() => {
        getSBO(props.sboId);
    }, [props.sboId]);

    React.useEffect(() => {
        _fetchMorphs(props.sboId);
    }, [props.filter, currentPage, pageLength, search, sorting?.column, sorting?.order]);

    const isPageLoading = [dataState.isDataUpdating, dataState.isDataDeleting, dataState.isDataLoading, dataState.isSBOLoading]
        .some((status) => !!status);

    const key = getKey(props.filter, search, currentPage);

    const data = dataState.dataByKey[key];
    const isEmpty = !data?.length;

    return {
        isPageLoading, key, setTableState, setCurrentPage,
        setSearch, sorting, setSorting,
        tableState,
        pageLength,
        currentPage,
        dataState,
        editorModalRef,
        data,
        isEmpty,
    };
};

export default useMorphSpreadsheet;

const getKey = (filter, search, page ) => {
    return search + page;
};

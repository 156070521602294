import {F4FormDiv, F4FormElementDecorator, F4FormStep} from '@aktek/f4form';
import {TDropdownOption, TDropdownValue} from '@aktek/f4kit';

import {TMode} from '@/components/F4CreatableModal/types/F4CreatableModalProps.t';
import AvatarImageSelector from '@/components/F4Elements/F4AvatarImageSelector';
import F4Dropdown from '@/components/F4Elements/F4Dropdown';
import F4PhoneNumber from '@/components/F4Elements/F4PhoneNumber';
import F4TextBox from '@/components/F4Elements/F4TextBox';
import {LANGUAGES} from '@/constants/languages';
import {strings} from '@/localization/i18n';

type TUserInfoProps = {
    mode?: TMode
}
// !!TODO: MOVED INSIDE A COMPONENT. OR MAKE A CHECK ON LS BEFORE DOING ANYTHING
const FILTERED_LANGUAGES: Record<TDropdownValue, TDropdownOption> = Object.keys(LANGUAGES).reduce(
    (filteredLanguages, key) => {
        if (ls.currentCompany?.languages.includes(key)) {
            filteredLanguages[key as TDropdownValue] = LANGUAGES[key as TDropdownValue];
        }

        return filteredLanguages;
    },
    {} as Record<TDropdownValue, TDropdownOption>,
);

export function UserInfo({mode} : TUserInfoProps) {
    return (
        <F4FormStep step="userInfo">
            <AvatarImageSelector
                name="profilePicURL"
                title={strings('company_logo')}
                uploadLabel={strings('upload_image')}
                replaceLabel={strings('replace_image')}
                size="3xl"
                showUserIcon
                allowedTypes={['image/jpeg', 'image/png', 'image/jpg']}
                maxSizeMB={1}
                className="mb-4"
            />
            <F4FormDiv className="flex flex-col gap-4">
                <F4TextBox
                    name="name"
                    colSpan={12}
                    required
                    className="!font-medium mt-1"
                    label={strings('name')}
                />

                <F4TextBox
                    required
                    name="email"
                    type="email"
                    isDisabled={mode === 'edit'}
                    colSpan={12}
                    className="!font-medium mt-1"
                    label={strings('email_address')}
                />

                <F4PhoneNumber
                    name="phone"
                    label={strings('phone_number')}
                    colSpan={12}
                    className="mt-1"
                />

                <F4Dropdown
                    name="language"
                    colSpan={12}
                    options={FILTERED_LANGUAGES}
                    isClearable
                    className="mt-1"
                    label={strings('platform_language')}
                />
            </F4FormDiv>

        </F4FormStep>
    );
}

const F4UserInfo = F4FormElementDecorator(UserInfo);
export default F4UserInfo;

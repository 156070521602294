/* eslint-disable max-len */

import _pick from 'lodash/pick';

import CallRequest from './CallRequest';
import {TRequestConfig, TSDKRequestHandlers as TRequestHandlers} from './types/SDKRequest';

class RequestBuilder {

    constructor(method: string = 'GET', url:string, requestHandlers: TRequestHandlers) {
        this.requestConfig = {
            method,
            url,
            headers: {
                'Content-Type': 'application/json',
            },
            data: {},
            hideNotification: false,
            hideSuccess: false,
            useToken: false,
            config: null,
        };
        this.sdkRequestHandlers = requestHandlers;

        if (ls.isLoggedIn) {
            this.requestConfig.headers.Authorization = 'Bearer ' + ls.token;
        }
    }

    requestConfig: TRequestConfig;

    sdkRequestHandlers: TRequestHandlers;

    run() {
        switch (this.requestConfig.method) {
            case 'GET':
            case 'POST':
            case 'PUT':
            case 'DELETE':
                const axiosOptions = _pick(this.requestConfig, ['method', 'url', 'headers', 'data']);

                return CallRequest( axiosOptions, this.sdkRequestHandlers);
            default:
                console.error('REQUEST SHOULD EITHER BE GET OR POST. PLEASE USE STATIC BUILDER FUNCTIONS');

                return null;
        }
    }

    addOptions = (config: object) => {
        this.requestConfig = {
            ...this.requestConfig,
            ...config,
        };

        return this;
    };

    withProgressCallBack(progressCallBack: () => void) {
        this.addOptions({progressCallBack});

        return this;
    }

    withData(data:unknown) {
        this.addOptions({data});

        return this;
    }

    hideNotification() {
        this.addOptions({hideNotification: true});

        return this;
    }

    hideSuccess() {
        this.addOptions({hideSuccess: true});

        return this;
    }

    useToken() {
        this.addOptions({useToken: true});

        return this;
    }

}

export default ({onResponse, onError}: TRequestHandlers ) => {
    return {

        get: (url: string) => new RequestBuilder('GET', url, {onResponse, onError}),

        post: (url: string) => new RequestBuilder('POST', url, {onResponse, onError}),

        put: (url: string) => new RequestBuilder('PUT', url, {onResponse, onError}),

        delete: (url:string) => new RequestBuilder('DELETE', url, {onResponse, onError}),
    };
};


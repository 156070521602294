import {Badge, Icon, Label} from '@aktek/f4kit';
import {faQuestionCircle} from '@fortawesome/pro-light-svg-icons';

import {FieldIcons} from '@/constants/FieldIcons.c';

import {TSmartFilterPreviewFieldProps} from './types/SmartFilter.Preview.t';

function SmartFilterPreviewField(props: TSmartFilterPreviewFieldProps) {
    const {fields, fieldId} = props;

    const field = fields[fieldId];

    if (!field) {
        return <Badge className="rounded-xs" iconPlacement="left" size="sm" color="neutral-600" labelProps={{color: 'white'}} icon={faQuestionCircle} iconProps={{color: 'white'}} label="Select field" />;
    }

    const fieldIcon = FieldIcons[field.type];
    const fieldName = field.label;

    return <div className="rounded-md bg-neutral-50 border border-neutral-200 px-2 py-1 flex gap-1 flex-row items-center">
        <Icon color="neutral-400" icon={fieldIcon.icon} />
        <Label color="neutral-900" text={fieldName} size="sm" fontWeight="medium" />
    </div>;
}

export default SmartFilterPreviewField;

import {createContext} from 'react';

import {TFilesAndFoldersState} from '@/hooks/useFilesAndFoldersState';
import {TUseTheme} from '@/hooks/useTheme';
import {UserAuth} from '@/types/UserAuth';

import {TIOPermissions} from '../Permissions/usePermissionsLoader';

export type TUserContextProvider = {
    children: React.ReactNode
}

export type TUserContext = {
    userAuth: UserAuth
    setUserAuth: React.Dispatch<React.SetStateAction<UserAuth>>
    filesAndFoldersState: TFilesAndFoldersState
    permissionsState: TIOPermissions
    theme: TUseTheme
    setPath: React.Dispatch<React.SetStateAction<string>>
    path: string
}

export const UserContext = createContext<TUserContext | null>(null);

import {Tag} from '@aktek/f4kit';

import {TColumn} from '@/components/Table/Types/Table.t';

import {GetTagTextColor} from '../helpers/GetTagTextColor.fn';

export type XTag = {
    tag: string;
    color: string;
};

export const GetTagsColumns = ():Array<TColumn<XTag>> => {
    return [
        {
            key: 'Tag',
            type: 'tags',
            header: 'Tag',
            renderCell({row}) {
                const color = row?.original?.color;
                const label = row?.original?.tag;

                const backgroundColor = color?.length > 0 ? color : 'primary-200';

                return (
                    <Tag
                        className={` !rounded-3xl px-2 py-1 `}
                        label={label}
                        backgroundColor={backgroundColor}
                        textColor={GetTagTextColor(color)}
                    />
                );
            },
        },
        {
            key: 'color',
            header: 'Color',
            type: 'color',
        },
    ];
};


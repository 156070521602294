import {Avatar, Button, Icon, Label, Modal, TAvatarProps, TButtonProps, TIconProps, TModalProps, TModalRef, useModalRef} from '@aktek/f4kit';
import {IconDefinition} from '@fortawesome/pro-regular-svg-icons';
import cn from 'classnames';
import {forwardRef} from 'react';

import {strings} from '@/localization/i18n';

export type TConfirmationModalProps = {
    className?: string;
    modalProps?: Partial<TModalProps>;

    title: string;
    message?: string;

    icon?: IconDefinition,
    iconProps?: Omit<TIconProps, 'icon'>,

    avatar?: TAvatarProps,

    variant?: 'primary' | 'error' | 'success' | 'warning' ;

    confirmButtonProps?: TButtonProps;
    cancelButtonProps?: TButtonProps;

    onClose?: () => void;
    onConfirm?: () => void;
    onCancel?: () => void;
}

function ConfirmationModal(props: TConfirmationModalProps, ref: TModalRef) {
    const {className,
        icon,
        avatar,
        title,
        message,
        onConfirm,
        onCancel,
        onClose,
        variant='primary',
        modalProps = {size: 'xs'},
        cancelButtonProps = {},
        confirmButtonProps = {},
        iconProps} = props;

    const modalRef = useModalRef(ref);

    const onConfirmCallback = () => {
        onConfirm?.();
        modalRef.current.close();
    };

    const onCancelCallback = () => {
        onCancel?.();
        modalRef.current.close();
    };

    return <Modal
        ref={modalRef}
        hasXIcon={false}
        headerClassName="p-0 hidden"
        className={cn('overflow-hidden !rounded-xl p-4 max-w-[400px]', className)}
        title={null}
        size={modalProps.size}
        onClose={()=>{
            onCancel?.();
            onClose?.();
        }}
        onChange={modalProps.onChange}
    >
        <div className="flex flex-col min-h-36 items-center gap-5 p-2">

            {icon && <div className={`flex items-center justify-center w-12 h-12 bg-${variant}-25 rounded-3xl mt-2 elevation-5 !shadow-none`}>
                <Icon icon={icon}
                    color={`${variant}-600`}
                    size="lg"
                    {...iconProps}
                />
            </div>}

            {avatar
                && <Avatar
                    icon={avatar.icon}
                    iconProps={avatar.iconProps}
                    statusIcon={avatar.statusIcon}
                />}
            <div className="flex flex-col items-center justify-center px-2 gap-1">
                <Label
                    size="lg"
                    text={title}
                    className="select-text cursor-text px-5 block w-full text-center"
                    fontWeight="semibold"
                />
                <Label
                    size="sm"
                    text={message}
                    color="neutral-900"
                    className="text-center"
                />
            </div>
            <div className="flex mt-4 w-full gap-4">
                <Button
                    className="w-1/2"
                    label={cancelButtonProps.label || strings('cancel')}
                    variant={'white'}
                    onClick={onCancelCallback}
                    {...cancelButtonProps}
                />
                <Button
                    className="w-1/2"
                    label={confirmButtonProps.label || strings('confirm')}
                    onClick={onConfirmCallback}
                    variant={variant}
                    {...confirmButtonProps}
                />
            </div>
        </div>

    </Modal>;
}

export default forwardRef(ConfirmationModal);

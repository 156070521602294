import {F4FormDiv} from '@aktek/f4form';
import {ContentDivider, Icon, Image, Label} from '@aktek/f4kit';
import {TagService} from '@aktek/morph-frontend-sdk';
import {faChevronDown, faChevronUp, faQuestionCircle} from '@fortawesome/pro-regular-svg-icons';
import classNames from 'classnames';
import {useEffect, useState} from 'react';

import F4Dropdown from '@/components/F4Elements/F4Dropdown';
import F4Textbox from '@/components/F4Elements/F4TextBox';
import F4ToggleSwitch from '@/components/F4Elements/F4Toggle';
import {strings} from '@/localization/i18n';

type TSBOEditorMainProps ={
    sboId:string
}
const inputClassName='!bg-neutral-50  group-hover:!bg-neutral-100 focus:!bg-neutral-50 focus:!border-1 focus:!border-neutral-200 focus:!shadow-[rgba(16,24,40,0.05)_0px_0px_6px_4px] focus:!shadow-neutral-200 font-semiBold !text-neutral-900 placeholder-neutral-300 font-medium';

function SBOEditorMain(props: TSBOEditorMainProps) {
    const [tags, setTags] = useState({});
    const [isMainSectionExpanded, setIsMainSectionExpanded] = useState(true);

    const fetchTags = async () => {
        const res = await TagService.getAllTags();
        if (!res.isSuccessful()) return;
        const resultTags = res.getData();
        const transformedTags = resultTags.reduce((acc, tag) => {
            acc[tag.value] = tag.label;

            return acc;
        }, {});
        setTags(transformedTags);
    };

    useEffect(()=>{
        fetchTags();
    }, []);

    return (
        <F4FormDiv>
            <F4FormDiv className="flex gap-6 items-center">
                <span onClick={()=>console.log('image modal here')}>
                    <Image alt="ds icon" src="https://f3dev.aktek.io/imgs/aktek_io_white_mint.png" className="!w-12 !h-12 rounded-lg bg-primary-100 cursor-pointer"/>
                </span>
                {/* TODO: <F4ImageSelector alt="ds icon" src="https://f3dev.aktek.io/imgs/aktek_io_white_mint.png" className="!w-12 !h-12 rounded-lg bg-primary-100 "/> */}
                <F4Textbox required autoComplete="off" name="name" isGhost inputClassName="!bg-neutral-50 rounded-none h-full !text-neutral-900 placeholder-neutral-300 font-bold !text-4xl" placeholder="Untitled" />
            </F4FormDiv>

            {/* ContentDivider */}
            <F4FormDiv >
                <ContentDivider
                    backgroundColor="neutral-50"
                    rightChild={
                        <div className="flex items-center !w-24 !cursor-pointer select-none" onClick={()=> setIsMainSectionExpanded(!isMainSectionExpanded)}>
                            <Icon icon={isMainSectionExpanded ? faChevronUp : faChevronDown} className="mr-2 font-semibold" color="neutral-600"/>
                            <Label text={isMainSectionExpanded ? strings('collapse'): strings('expand')} className="text-neutral-600 text-sm font-semibold !cursor-pointer" />
                        </div>}
                />
            </F4FormDiv>

            {isMainSectionExpanded
                ? <F4FormDiv className="flex flex-col gap-4 pb-3">
                    {/* Description */}
                    <F4FormDiv className="flex items-center gap-4 group">
                        <Label className="w-60" text={strings('description')} color="neutral-400" fontWeight="medium" size="md"/>
                        <F4Textbox autoComplete="off" name="description" isGhost inputClassName={inputClassName}/>
                    </F4FormDiv>

                    {/* Data structure tags */}
                    <F4FormDiv className="flex items-center gap-4 group">
                        <Label className="w-60" text={strings('data_structure_tags')} color="neutral-400" fontWeight="medium" size="md"/>
                        <F4Dropdown name="tags.tags" options={tags} isMulti hideArrow placeholder=""
                            dropdownClassname="border-0 !bg-neutral-50 group-hover:!bg-neutral-100 focus:!bg-neutral-50 focus:!border-1 focus:!border-neutral-200 focus:!shadow-[rgba(16,24,40,0.05)_0px_0px_6px_4px] focus:!shadow-neutral-200"/>
                    </F4FormDiv>

                    {/* designation */}
                    <F4FormDiv className="flex items-center gap-4 group">
                        <span className="flex w-60">
                            <Label className="mr-1.5" text={strings('designation')} color="neutral-400" fontWeight="medium" size="md" />
                            <Icon icon={faQuestionCircle} className="w-4 h-4" color="neutral-600" tooltipProps={{showDelay: 1000}} tooltip="This is the prefix to show before each record number. It cannot be changed once it is set"/>
                        </span>
                        <F4Textbox isDisabled={!!props?.sboId} autoComplete="off" name="designation" isGhost inputClassName={classNames(inputClassName, (props?.sboId ? 'cursor-not-allowed group-hover:!bg-neutral-50' : 'group-hover:!bg-neutral-100'))} />
                    </F4FormDiv>

                    {/* activate features */}
                    <F4FormDiv className="flex items-center gap-4 group">
                        <Label className="w-60" text={strings('activate_features')} color="neutral-400" fontWeight="medium" size="md"/>
                        <F4Textbox isGhost inputClassName={inputClassName} placeholder="Here later in FIR-183 we will have the tags and their modal"/>
                    </F4FormDiv>

                    {/* toggles */}
                    <F4FormDiv className="flex w-2/3">

                        <F4ToggleSwitch name="hasRecordTags" size="sm" description={strings('allow_tagging_of_records')} defaultValue={true} />
                        <F4FormDiv className="flex">
                            <F4ToggleSwitch name="shouldStoreHistory" size="sm" description={strings('store_record_change_history')}/>
                            <Icon icon={faQuestionCircle} className="w-4 h-4" color="neutral-600" tooltipProps={{showDelay: 1000}} tooltip="Activate this option only if absolutely necessary, as it may affect performance and increase the time required to load records."/>
                        </F4FormDiv>
                    </F4FormDiv>
                </F4FormDiv>
                : ''}
        </F4FormDiv>
    );
}

export default SBOEditorMain;

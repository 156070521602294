import {TModalRef} from '@aktek/f4kit';
import {forwardRef, useCallback, useMemo, useState} from 'react';

import F4CreatableModal from '@/components/F4CreatableModal/F4CreatableModal';
import {TF4CreatableModalProps} from '@/components/F4CreatableModal/types/F4CreatableModalProps.t';
import {useAppPermissions} from '@/context/Permissions/usePermissions';
import {useUpdateUserCompanies} from '@/context/UserContext/Hooks/useUpdateUserCompanies';
import {useUserAuth} from '@/context/UserContext/Hooks/useUserAuth';
import {strings} from '@/localization/i18n';

import {CheckLicenseAccess} from '../helpers/Agency.CheckLicenseAccess.fn';
import {GetCompany} from '../helpers/Agency.GetCompany.fn';
import {GetSteps} from '../helpers/Agency.GetSteps.fn';
import {StoreCompany} from '../helpers/Agency.StoreCompany.fn';
import F4ActivationInfo from './Agency.ActivationInfo';
import F4CompanyInfo from './Agency.CompanyInfo';
import F4LicenseInfo from './Agency.LicenseInfo';

export type TCompanyProgram = {
    enabled?: boolean;
    features?: Record<string, boolean>;
    limits?: Record<string, boolean>;
    expiryDate?: number;
    isActive?: boolean;
    isValid?: boolean;
    issuedOn?: number;
    issuer?: string;
    licensePlan?: string;
    validity?: number;
    verificationHash?: string;
  }

const AgencyCreateModal = (props: TF4CreatableModalProps, ref: TModalRef) => {
    const {viewState, ...rest} = props;
    const userAuth = useUserAuth();
    const userCompanies = userAuth.userCompanies;
    const setUserCompanies = useUpdateUserCompanies();

    const {manageAccountManagers} = useAppPermissions();

    const [companyProgram, setCompanyProgram] = useState<TCompanyProgram>({});

    const {currentCompany, currentUser} = useUserAuth();

    const canAccessLicense = useMemo(() => CheckLicenseAccess(currentCompany, currentUser), [currentCompany, currentUser]);

    const storeCompany = useCallback(
        (company) =>
            StoreCompany(
                company,
                canAccessLicense,
                userCompanies,
                setUserCompanies,
            ),
        [canAccessLicense, userCompanies],
    );

    const getFunction = useCallback(
        (companyId) =>
            GetCompany(
                companyId,
                canAccessLicense,
                manageAccountManagers,
                setCompanyProgram,
            ),
        [manageAccountManagers, canAccessLicense],
    );

    const steps = useMemo(() => GetSteps(canAccessLicense), [canAccessLicense]);

    return (
        <F4CreatableModal
            ref={ref}
            viewState={viewState}
            title={strings('company')}
            formKey="company"
            sideBarExtraElementPosition="top"
            childrenClassName="p-4"
            getFunction={getFunction}
            storeFunction={storeCompany}
            createSuccessMessage={strings('create_company_success')}
            editSuccessMessage={strings('company_edit_success')}
            errorMessage={strings('company_failed')}
            steps={steps}
            {...rest}
        >
            <>
                <F4CompanyInfo mode={viewState.mode} />
                {canAccessLicense && <F4LicenseInfo mode={viewState.mode} />}
                {canAccessLicense && <F4ActivationInfo mode={viewState.mode} companyProgram={companyProgram} />}
            </>
        </F4CreatableModal>
    );
};

export default forwardRef(AgencyCreateModal);

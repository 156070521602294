
import {RowKnownActions} from '../Constants/RowKnownActions.c';
import {TGetRowActions} from '../Types/Table.t';

export const GetActionsFromRowActions = <T, >(rowActions: TGetRowActions<T>, row) => {
    if (typeof rowActions == 'function') {
        return rowActions(row).map((action)=>{
            if (typeof action == 'string') {
                return RowKnownActions[action];
            } else {
                return action;
            }
        }).filter(Boolean);
    }

    return rowActions.map((action)=>{
        if (typeof action == 'string') {
            return RowKnownActions[action];
        } else {
            return action;
        }
    }).filter(Boolean);
};

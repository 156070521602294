import {AuthenticationService} from '@aktek/helios-frontend-sdk';
import {useState} from 'react';

import {useGlobalPath} from '@/context/UserContext/Hooks/useGlobalPath';
import {useSignUserIn} from '@/context/UserContext/Hooks/useSignUserIn';
import {isEventEnterKey} from '@/helpers/Events';
import {useNavigateToRoot, useNavigateToURL} from '@/routing/navigations';
import {setCloudFrontCookies} from '@/utils/storage/Cookies';

import {TLoginState} from '../types/loginTypes.t';
import type {TTwoFactorState} from '../types/twoFactorState.t';
import {useLoginValidation} from './useAuthValidation';

export default function useHandleLogin(setCanResetPassword) {
    const navigateToRoot = useNavigateToRoot();

    const navigateTo = useNavigateToURL();
    const {path} = useGlobalPath();

    const signIn = useSignUserIn();
    const [loading, setLoading] = useState(false);

    const [loginState, setLoginState] = useState<TLoginState>({
        email: '',
        emailTouched: false,
        password: '',
        passwordTouched: false,
    });

    const {validationResult} = useLoginValidation(loginState);
    const [twoFactorAuthState, setTwoFactorAuthState] = useState({challenge: '', email: ''});

    const handleEmailChange = (email)=> setLoginState({...loginState, email: email.trim()?.toLowerCase()});

    const handlePasswordChange = (password)=> setLoginState({...loginState, password: password.trim()});

    const handleLoginKeyDown = (event, onSuccess) => {
        if (!isEventEnterKey(event)) return;
        login(onSuccess);
    };

    const login = async (onLoginSuccess: (twoFactorState: TTwoFactorState)=> void)=> {
        if (!validationResult.isValid) return;
        setLoading(true);
        const loginResponse = await AuthenticationService.login(
            loginState.email,
            loginState.password,
        );

        const success = loginResponse.isSuccessful?.();

        setLoading(false);

        if (!success) {
            setCanResetPassword(loginResponse.getData().canReset);

            return;
        }

        const data = loginResponse.getData?.();

        if (data.requires2FA) return process2FAFunction(data, onLoginSuccess);

        setCloudFrontCookies(data?.cloudfrontCookie);
        signIn(data);

        if (path) return navigateTo(path);

        navigateToRoot(true);
    };

    const process2FAFunction = (data, onLoginSuccess) => {
        const twoFactorState : TTwoFactorState = {
            challenge: data.challenge,
            email: data.email,
        };

        onLoginSuccess(twoFactorState);
    };

    return {
        loginState,
        setLoginState,
        loading,
        handleEmailChange,
        handlePasswordChange,
        validationResult,
        login,
        handleLoginKeyDown,
        twoFactorAuthState,
        setTwoFactorAuthState,
    };
}



import {Icon, Label} from '@aktek/f4kit';
import {faCrown} from '@fortawesome/free-solid-svg-icons';
import {faPen, faTrash} from '@fortawesome/pro-regular-svg-icons';
import {Row} from '@tanstack/react-table';

import {TColumn, TRowActionsBuilder} from '@/components/Table/Types/Table.t';
import {strings} from '@/localization/i18n';
import {TPermission} from '@/types/Permissions.t';

import {TRole} from './types/RolesAndPermissionProps.t';

export const ROLES_COLUMNS = (): Array<TColumn> => {
    return [
        {key: 'name', header: 'Role', type: 'string', isSortable: true, renderCell(row) {
        //    FIXME: @w to check this code if correct type
            const cell = row?.row?.original as TRole;

            return <div className="flex flex-row items-center  gap-1">
                {cell.overridePermissions && <Icon icon={faCrown} color="warning-300" />}
                <Label text={cell.name} />
            </div>;
        }},
        {key: 'description', type: 'string', header: 'Description', isSortable: true},

    ];
};

export type TGetRowUsersActionsArgs = {
    rolesPermission: TPermission,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const GetRolesRowActions = ({rolesPermission}: TGetRowUsersActionsArgs) => (row: Row<TRole>): TRowActionsBuilder => {
    return [
        {
            key: 'edit',
            label: strings('edit'),
            isVisible: rolesPermission?.can_edit,
            icon: faPen,
            showOnRow: true,
        },
        {
            key: 'delete',
            label: strings('delete'),
            isVisible: rolesPermission?.can_delete,
            icon: faTrash,
            showOnRow: true,
        },
    ];
};

import {TModalController, useModalRef} from '@aktek/f4kit';
import {useEffect} from 'react';
import {ForwardRefExoticComponent, PropsWithoutRef, RefAttributes} from 'react';

import InjectAsyncElement from '@/utils/InjectAsyncElement';

const InjectedModal = ({modal: Modal, props, remove}) => {
    const modalRef = useModalRef();

    useEffect(()=>{
        modalRef.current?.open();
    }, []);

    return <Modal ref={modalRef}
        {...props}
        openOnMount={true}
        onClose={remove}/>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default async function AskForModal<P>(Element:ForwardRefExoticComponent<PropsWithoutRef<P> & RefAttributes<TModalController>>, props?: P) {
    InjectAsyncElement((resolve, reject, remove) => {
        return <InjectedModal modal={Element} props={props} remove={remove}/>;
    });
}

import {TabsManager} from '@aktek/f4kit';
import {faTable} from '@fortawesome/pro-regular-svg-icons';

import {strings} from '@/localization/i18n';

export const openDataTable = (sboId, sboName) => {
    return TabsManager.focusOrOpenNewTab(
        'dataTable',
        sboName,
        {label: sboName, sboId},
        {tabKey: 'dataTable-' + sboId},
    );
};

export const getOpenEntry = (sboName, sboId) => {
    return ({
        key: 'open',
        label: [strings('open_data_table'), sboName].join(': '),
        icon: faTable,
        onClick: () => openDataTable(sboId, sboName),
    });
};

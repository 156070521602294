import {TColumn} from '@/components/Table/Types/Table.t';
import {strings} from '@/localization/i18n';

export const GetLicensingColumns = ():Array<TColumn> => [
    {
        key: 'plan',
        header: strings('plan_name'),
        type: 'string',
        isSortable: true,
    },
    {
        key: 'creator',
        header: strings('creator'),
        type: 'string',
        isSortable: true,
    },
    {
        key: 'lastEditor',
        header: strings('last_editor'),
        type: 'string',
        isSortable: true,
    },
];

import {Authentication_TokensService} from '@aktek/helios-frontend-sdk';
import {useRef, useState} from 'react';

import AskForConfirmation from '@/asks/AskForConfirmation';
import {useGlobalFilesAndFolders} from '@/context/UserContext/Hooks/useGlobalFilesAndFolders';
import {useSignUserIn} from '@/context/UserContext/Hooks/useSignUserIn';
import {useSignUserOut} from '@/context/UserContext/Hooks/useSignUserOut';
import {CreateURLWithParams} from '@/helpers/CreateURLWithParams.fn';
import {strings} from '@/localization/i18n';
import {useNavigateToRoot, useNavigateToURL} from '@/routing/navigations';

const useCompanySwitch = (folder) => {
    const [loading, setLoading] = useState(false);
    const switchRef = useRef(false);

    // Context
    const signIn = useSignUserIn();
    const signOut = useSignUserOut();
    const {setLinkToFolder} = useGlobalFilesAndFolders();

    // Navigation
    const navigateToRoot = useNavigateToRoot();
    const navigateTo = useNavigateToURL();

    // params

    const onConfirm = async (id: string, folderId, folderParam) => {
        if (folderParam) setLinkToFolder(folderParam);

        setLoading(true);
        const res = await Authentication_TokensService.switchCompany(id);
        if (!res.isSuccessful()) return navigateToRoot(true);
        const data = res.getData();
        await signOut();
        await signIn(data);

        ls.tabsSchema = undefined;
        ls.recentlyUsedSBO = undefined;
        setLoading(false);

        navigateTo(CreateURLWithParams({param1: folderId, param2: id}));
        ls.permissionSection = 'admin';
    };

    const handleSwitchCompany = async (id: string, folderId) => {
        if (switchRef.current) return;
        // making sure the confirmation modal will call only once (strict mode)
        switchRef.current = true;
        await AskForConfirmation(
            strings('do_you_want_to_switch'),
            strings('do_you_want_to_switch_desc'),
            {
                confirmButtonProps: {label: strings('switch')},
                onConfirm: () => onConfirm(id, folderId, folder),
                onCancel: () => {
                    switchRef.current = false;
                    navigateToRoot(true);
                },
            },
        );
    };

    return {loading, handleSwitchCompany};
};

export default useCompanySwitch;

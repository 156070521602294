
import {faEye, faPen, faPieChart} from '@fortawesome/pro-regular-svg-icons';

import {TRowActionsBuilder} from '@/components/Table/Types/Table.t';
import {strings} from '@/localization/i18n';
import {TPermission} from '@/types/Permissions.t';

type TGetRowAgencyActionsArgs = {
    companiesPermission: TPermission,
}

export const GetRowAgencyActions = ({companiesPermission}: TGetRowAgencyActionsArgs) => (): TRowActionsBuilder => {
    return [
        {
            key: 'edit',
            label: strings('edit'),
            isVisible: companiesPermission?.can_edit,
            icon: faPen,
            showOnRow: true,
        },
        {
            key: 'view',
            label: strings('view'),
            icon: faEye,
            showOnRow: true,
        },
        {
            key: 'usage_metrics',
            label: strings('usage_metrics'),
            icon: faPieChart,
            showOnRow: true,
        },

    ];
};

import {TabsManager} from '@aktek/f4kit';
import {useMemo} from 'react';

import NavigationViewer from '@/components/Navigation/Navigation.Viewer';
import {AdminNavigation} from '@/components/Navigation/Pages/Admin.Navigation.Pages.c';
import {usePermissions} from '@/context/Permissions/usePermissions';

function Admin(props) {
    const permissions = usePermissions();
    const sections = useMemo(()=>{
        return AdminNavigation(TabsManager.openNewTab, permissions);
    }, [permissions]);

    return <NavigationViewer sections={sections}/>;
}

export default Admin;

import {Label, TModalRef} from '@aktek/f4kit';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import _isUndefined from 'lodash/isUndefined';
import {forwardRef, useMemo, useState} from 'react';

import F4CreatableModal from '@/components/F4CreatableModal/F4CreatableModal';
import {TF4CreatableModalProps} from '@/components/F4CreatableModal/types/F4CreatableModalProps.t';
import F4ExplorerNodeDesign from '@/components/F4Elements/F4ExplorerNodeDesign';
import F4Textbox from '@/components/F4Elements/F4TextBox';
import F4TreeViewSelector from '@/components/F4Elements/F4TreeViewSelector';
import SearchBox from '@/components/SearchBox/SearchBox';
import {useGlobalFilesAndFolders} from '@/context/UserContext/Hooks/useGlobalFilesAndFolders';
import {GetFirstKey} from '@/helpers/GetFirstKey.fn';
import {strings} from '@/localization/i18n';
import {TFolder} from '@/types/Folder.t';

import {FOLDERS_AND_DATA} from '../constants/constantIds.c';
import useFilteredItems from '../hooks/useFilteredItems';

type TFolderEditMoveModal = TF4CreatableModalProps & {
    folder?: TFolder
}

const FolderEditMoveModal = (props: TFolderEditMoveModal, ref: TModalRef) => {
    const {viewState, folder, ...rest}= props;
    const {folders: items, editFolder} = useGlobalFilesAndFolders();
    const [searchValue, setSearchValue] = useState('');

    const [info, setInfo] = useState({
        folderName: folder?.label,
        parentId: undefined,
        icon: folder?.customIcon,
        // add the color later
    });

    const updateInfoState = (key, value) => setInfo((state) => ({...state, [key]: value}));

    const data = useFilteredItems(items, searchValue, true, 'folder', folder.id, folder.parentId);
    const hasData = data && data.length > 0;

    const finalInitialValue = useMemo(() => {
        const defaultParentId = folder?.parentId ? folder?.parentId : FOLDERS_AND_DATA;

        const initialValue = {
            ...folder,
            parentId: {[defaultParentId]: true},
        };

        return initialValue;
    }, [folder]);

    const isEdited = [
        _isEqual(info.folderName, folder?.label) || _isEmpty(info.folderName),
        _isUndefined(info.parentId) || _isEqual(info.parentId, folder?.parentId),
        _isEqual(info.icon, folder?.customIcon),
    ].every(Boolean);

    const handleTreeViewChange = (value) => {
        const itemChecked = GetFirstKey(value);
        if (itemChecked == FOLDERS_AND_DATA) return updateInfoState('parentId', null);
        updateInfoState('parentId', itemChecked);
    };

    return (
        <F4CreatableModal
            ref={ref}
            viewState={viewState}
            title={strings('folder')}
            formKey="folder"
            f4FormProps={{initialValue: finalInitialValue}}
            hasSidebar={false}
            storeFunction={editFolder}
            isDisabled={isEdited}
            editSuccessMessage={strings('edit_folder_success')}
            errorMessage={strings('edit_folder_failed')}
            {...rest} >

            <div>
                <div className="sticky !shadow-none elevation-5 -top-4 bg-white -mt-1">

                    <div className="flex items-start justify-start gap-3 mb-6" >
                        <div className={`bg-primary-25 rounded-xl px-4 pt-2 pb-3 flex items-center justify-center w-fit h-fit mt-3.5`}>
                            <F4ExplorerNodeDesign name="assets"/>
                        </div>

                        <F4Textbox
                            name="label"
                            label={strings('folder_name')}
                            autoFocus
                            required
                            placeholder=""
                            onChange={(e:string) => updateInfoState('folderName', e.trim())}
                            type="text"
                            colSpan={8}
                            f4FormDivClassName="flex flex-col justify-center items-start"
                        />
                    </div>
                    <SearchBox onSearch={setSearchValue}
                        autoFocusOnOpen={false}
                        className="!bg-neutral-100 rounded-lg mb-4"
                        textBoxInputClassName="!bg-neutral-100 !text-base !font-normal !text-neutral-900"
                    />
                </div>

                <F4TreeViewSelector name="parentId" data={data} parentId={folder?.parentId}
                    areAllNodesExpanded={true} size="xs" onChange={handleTreeViewChange} />

                {!hasData && <Label
                    size="sm"
                    text={strings('no_results_found')}
                    className="mt-5 ml-3 overflow-hidden"
                    color="neutral-600"/>}

            </div>
        </F4CreatableModal>

    );
};

export default forwardRef(FolderEditMoveModal);

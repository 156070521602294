import {TDropdownOption, TDropdownValue, toast} from '@aktek/f4kit';
import {CompanyLicenseService, LicensePlanService, UserService} from '@aktek/helios-frontend-sdk';
import {useState} from 'react';

import {TransformDataToDropdownOptions} from '@/helpers/TransformDataToDropdownOptions.fn';

const useAgency = () => {
    const [listAccountManagers, setListAccountManagers] = useState<Array<Record<TDropdownValue, TDropdownOption>>>([]);
    const [dropdownOptions, setDropdownOptions] = useState();

    const getLicenses = async (search, sort, skip, limit) => {
        const response = await LicensePlanService.getAllLicensePlans(search, sort, skip, limit);
        const success = response.isSuccessful?.();
        const data = response.getData();
        success && setDropdownOptions(TransformDataToDropdownOptions(data, '_id', 'plan'));

        return data?.data;
    };

    const getAllAccountManagers = async () => {
        const response = await UserService.getAllAccountManagers();
        const success = response.isSuccessful?.();
        const data = response.getData();

        success && setListAccountManagers(data as Array<Record<TDropdownValue, TDropdownOption>>);

        return data;
    };

    const revokeFunction = async (id, license) => {
        const finalLicense = {
            ...license,
            validity: -1,
            isNewLicense: true,
        };
        const response = await CompanyLicenseService.setLicenseProgram(id, finalLicense);
        const success = response.isSuccessful?.();

        success ? toast.success?.('License Updated Successfully!') : toast.error?.('Failed to update license!');
    };

    return {revokeFunction,
        dropdownOptions, getLicenses,
        getAllAccountManagers, listAccountManagers};
};

export default useAgency;


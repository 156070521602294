import {LRUCache as LRU} from 'lru-cache';

export default class F3Cache {

    static cache = new LRU<string, { lastClientCacheTime: number, object: object }>({
        max: 15,

        ttl: 1000 * 60 * 60,
    });

    static loadingState = {};

    static adhocloadingState = {};

    static errorState = {};

    static setLoadingState = (key: string, isLoading: string) => {
        if (isLoading) F3Cache.loadingState[key] = true;
        else {
            delete F3Cache.loadingState[key];
        }
    };

    static setAdHodLoadingState = (key: string, isLoading: boolean) => {
        if (isLoading) F3Cache.adhocloadingState[key] = true;
        else {
            delete F3Cache.adhocloadingState[key];
        }
    };

    static setErrorState = (key: string, hasError: boolean) => {
        if (hasError) F3Cache.errorState[key] = true;
        else {
            delete F3Cache.errorState[key];
        }
    };

    static printCache = () => {
        F3Cache.cache.forEach(function(value, key) {
            console.log(value, key);
        });
    };

    static getErrorState = (key: string) => {
        return F3Cache.errorState[key];
    };

    static getLoadingState = (key: string) => {
        return F3Cache.loadingState[key];
    };

    static getAdhocLoadingState = (key: string) => {
        return F3Cache.adhocloadingState[key];
    };

    static cacheObject = (key: string, object: object) => {
        const cacheObject = {object: object, lastClientCacheTime: new Date().getTime()};
        F3Cache.cache.set(key, cacheObject);
    };

    static updateObjectCacheTime = (key: string) => {
        const cacheObject = F3Cache.cache.get(key);

        if (cacheObject) {
            cacheObject.lastClientCacheTime = new Date().getTime();
            F3Cache.cache.set(key, cacheObject);

            return true;
        }

        return false;
    };

    static exists = (key: string) => {
        return F3Cache.cache.has(key);
    };

    static getObject = (key: string) => {
        return F3Cache.cache.get(key);
    };

    static getObjectLastUpdateTime = (key: string) => {
        const object = F3Cache.cache.get(key);

        if (object) {
            return object.lastClientCacheTime;
        }

        return null;
    };

}

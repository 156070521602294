import IOSelect from '@/iohub/IOSelect';

const RefListCellEdit = ({value, onChange, column = {}}) => {
    const {field} = column;

    if (!field) return null;

    const values = value?.map?.((refValue) => refValue?._id) || [];

    return <IOSelect isMulti
        value={values}
        sboId={field?.sboId}
        displayFieldId={field?.fieldId}
        type="ref"
        onChange={(values) => {
            const updatedValues = values?.map?.((_id) => ({_id}));
            onChange(updatedValues);
        }}
    />;
};

export default RefListCellEdit;

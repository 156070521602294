
export const COLORS = {
    black: '#000000',
    white: '#ffffff',
    error: '#F04438',
    warning: '#F79009',
    success: '#12B76A',
    primary: '#0A3F45',
    neutral: '#667085',
    semantic1: '#4a1fb8',
    semantic2: '#175cd3',
    semantic3: '#ee4689',
};

import {faDashboard, faNetworkWired} from '@fortawesome/pro-regular-svg-icons';

import NavigationCategory from '@/components/Navigation/Navigation.Viewer.Category';
import NavigationPageContainer from '@/components/Navigation/Navigation.Viewer.PageContainer';
import NavigationSection from '@/components/Navigation/Navigation.Viewer.Section';
import {strings} from '@/localization/i18n';

const Analysis = ({}): React.ReactElement => {
    return <NavigationPageContainer>

        <NavigationSection label={strings('visualize')}>
            <NavigationCategory
                tabKey="dashboards"
                icon={faDashboard}
                name={strings('dashboards')}
            />
            <NavigationCategory
                tabKey="networks"
                icon={faNetworkWired}
                name={strings('networks')}
            />
        </NavigationSection>

        <NavigationSection label="Data Manipulation">
            <NavigationCategory
                tabKey="dashboards"
                icon={faDashboard}
                name="Data Toolbox"
            />

        </NavigationSection>
    </NavigationPageContainer>;
};

export default Analysis;

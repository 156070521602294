import {toast} from '@aktek/f4kit';
import {UserHistoryService, UserService, VerificationService} from '@aktek/helios-frontend-sdk';
import {faEnvelope, faUpload, faUser} from '@fortawesome/pro-regular-svg-icons';
import {Row} from '@tanstack/react-table';
import {useCallback} from 'react';

import AskForConfirmation from '@/asks/AskForConfirmation';
import AskForModal from '@/asks/AskForModal';
import F4ViewableComponent from '@/components/F4ViewableComponent/F4ViewableComponent';
import GeneratePasswordLinkModal, {TPasswordLinkModalProps} from '@/components/Modals/PasswordLinkModal';
import useTableRef from '@/components/Table/Hooks/useTableRef';
import {TOnRowAction} from '@/components/Table/Types/ActionButton.t';
import {TTableRowAction} from '@/components/Table/Types/TableActionMenuEntry.t';
import {useAdminPermissions, useAppPermissions} from '@/context/Permissions/usePermissions';
import {useCompanySettings} from '@/context/UserContext/Hooks/useCompanySettings';
import {useUserAuth} from '@/context/UserContext/Hooks/useUserAuth';
import {strings} from '@/localization/i18n';
import {TUser} from '@/types/User.t';
import ls from '@/utils/storage/ls';

import ViewPermissionModal from '../RolesAndPermissions/components/ViewPermissionModal';
import CreateUserModal from './components/Users.CreateUserModal';
import HistoryModal, {THistoryModalProps} from './components/Users.HistoryModal';
import {USER_HISTORY_COLUMNS} from './constants/userConstants.c';
import {GetUsersRowActionsFn} from './helpers/Users.GetUsersRowActions.fn';
import GetUsersColumns from './Users.columns';

export default function Users() {
    const tableRef = useTableRef();

    const {resetOtherUserPasswordPermissions} = useAppPermissions();
    const {users: usersPermissions, permissions: permissionsPermissions} = useAdminPermissions();

    const {currentUser} = useUserAuth();
    const {isRegularUser: isCurrentUserRegularUser, isAccountManager: isCurrentUserAccountManager} = currentUser;

    const {canFreeze: canFreezeFromCompanySettings, canAdminGenerateLink} = useCompanySettings();

    const refreshTable = () => tableRef.current?.refresh?.();

    const getUsersRowActions = useCallback(GetUsersRowActionsFn({
        usersPermissions,
        permissionsPermissions,
        resetOtherUserPasswordPermissions,
        canFreezeFromCompanySettings,
        canAdminGenerateLink,
        isCurrentUserAccountManager,
        isCurrentUserRegularUser,
        currentUser,

    }), [usersPermissions,
        permissionsPermissions,
        resetOtherUserPasswordPermissions,
        canFreezeFromCompanySettings,
        canAdminGenerateLink,
        isCurrentUserAccountManager,
        isCurrentUserRegularUser,
        currentUser]);

    const UsersOnRowActions: TOnRowAction = async (row: Row<TUser>, action: TTableRowAction) => {
        switch (action.key) {
            case 'view_permissions': {
                ls.permissionSection = 'admin';

                return AskForModal(ViewPermissionModal, {id: row.original._id});
            }

            case 'freeze_account': {
                const {_id, name} = row.original;
                const isConfirmed = await AskForConfirmation(`${strings('freeze')} ${name}`, strings('freeze_confirmation_description'), {icon: faUser} );
                if (!isConfirmed) return;

                const res = await UserService.freezeUserAccount(_id);

                if (res.isSuccessful()) {
                    const data: {valid?:boolean} = res.getData();

                    if (data.valid) {
                        refreshTable();

                        return toast.success(strings('freeze_success_message'));
                    }

                    return toast.error('Unable to freeze this user!'); // !!TODO: Change this message
                }

                return toast.error(strings('freeze_error_message'));
            }

            case 'unfreeze_account': {
                const {_id, name} = row.original;
                const isConfirmed = await AskForConfirmation(`${strings('unfreeze')} ${name}`, strings('unfreeze_confirmation_description'), {icon: faUser});
                if (!isConfirmed) return;

                const res = await UserService.unFreezeUserAccount(_id);

                if (res.isSuccessful()) {
                    refreshTable();

                    return toast.success(strings('unfreeze_success_message'));
                }

                return toast.error(strings('unfreeze_error_message'));
            }

            case 'send_verification_email': {
                const {_id} = row.original;
                const isConfirmed = await AskForConfirmation(strings('send_verification_email'), strings('send_verification_email_confirmation_description'), {icon: faEnvelope});
                if (!isConfirmed) return;

                const res = await VerificationService.resendVerificationEmail(_id);

                if (res.isSuccessful()) {
                    refreshTable();

                    return toast.success(strings('send_verification_email_success_message'));
                }

                return toast.error(strings('send_verification_email_error_message'));
            }

            case 'generate_password_link': {
                const response = await UserService.obtainPasswordResetLink(row.original._id);

                if (!response.isSuccessful()) return;
                const data = response.getData() as { link: string };

                AskForModal<TPasswordLinkModalProps>(GeneratePasswordLinkModal, {
                    URL: data.link,
                });

                return;
            }

            case 'view_history': {
                AskForModal<THistoryModalProps>(HistoryModal, {
                    id: row.original._id,
                    getDataFunction: UserHistoryService.getUserHistory,
                    columns: USER_HISTORY_COLUMNS,
                });

                return;
            }

        }
    };

    const onRibbonAction = useCallback((value) => {
        switch (value) {
            case 'export':
                console.log('value', value);
                break;
            default:

        }
    }, []);

    return (
        <F4ViewableComponent<TUser>
            onRibbonAction={onRibbonAction}
            name={strings('user')}
            key={'users'}
            tableRef={tableRef}
            tableColumns={GetUsersColumns()}
            defaultSortedColumn="name"
            emptyProps={{addLabel: strings('new_user')}}
            rowActions={getUsersRowActions}
            onRowAction={UsersOnRowActions}
            canCreate={usersPermissions?.can_create}
            modal={CreateUserModal}
            hasViewMode={true}
            getAllData={UserService.getUsers}
            deleteItem={UserService.deleteUser}
            deleteSuccessMessage={strings('delete_user')}
            customRibbonActions={[{
                key: 'export',
                icon: faUpload,
                label: strings('export'),
                tooltip: strings('export'),
                placement: 'left',

            }]}
        />
    );
}

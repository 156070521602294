import {useState} from 'react';

import SearchBox from '@/components/SearchBox/SearchBox';

import {TExplorerUIState} from '../hooks/useExplorerUI';
import ExplorerTree from './ExplorerTree';

export default function ExplorerSideBar({explorerUIState}:{explorerUIState: TExplorerUIState}) {
    const [searchValue, setSearchValue] = useState('');

    return (
        <div className="flex flex-col h-full overflow-x-hidden w-full relative">
            <SearchBox
                onSearch={setSearchValue}
                className="sticky w-full elevation-5 !shadow-none top-0 bg-white text-sm" />

            <ExplorerTree searchValue={searchValue} explorerUIState={explorerUIState} />
        </div>
    );
}


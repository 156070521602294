import {TabsManager, toast} from '@aktek/f4kit';
import {faEye} from '@fortawesome/free-regular-svg-icons';
import {faTrash} from '@fortawesome/pro-light-svg-icons';
import {faEllipsisVertical, faPlus} from '@fortawesome/pro-regular-svg-icons';
import _get from 'lodash/get';
import {useCallback, useMemo} from 'react';

import AskForConfirmation from '@/asks/AskForConfirmation';
import AskForModal from '@/asks/AskForModal';
import Table from '@/components/Table/Table';
import {strings} from '@/localization/i18n';

import useTableRef from '../Table/Hooks/useTableRef';
import {TTableRow} from '../Table/Types/Table.t';
import {TTableRowAction} from '../Table/Types/TableActionMenuEntry.t';
import {TF4ViewableComponentProps} from './types/F4ViewableComponentProps.t';

export default function F4ViewableComponent<DataType extends object = object>(props: TF4ViewableComponentProps<DataType>) {
    const {
        canCreate = true,
        createBehavior = 'modal',
        createTab,
        customRibbonActions = [],
        defaultSortedColumn,
        deleteItem,
        deleteSuccessMessage,
        emptyProps,
        getAllData,
        hasViewMode = false,
        idColumnName = '_id',
        nameColumnName = 'name',
        modal,
        name,
        noPagination,
        onRibbonAction,
        onRowAction,
        rowActions,
        tableColumns,
        getColumns,
        tableRef,
        getRowsCount,
        getRows,
        children,
        customAddButtonTooltip,
    } = props;

    const finalTableRef = useTableRef(tableRef);

    const refreshTable = () => finalTableRef.current?.refresh?.();

    const onRibbonActionCb = useCallback((actionName: string, event: React.MouseEvent, state) => {
        switch (actionName) {
            case 'add': {
                if (createBehavior == 'modal') {
                    AskForModal(modal, {viewState: {mode: 'create'}, onSave: refreshTable});
                } else {
                    const {tabKey, title, props} = createTab;
                    TabsManager.openNewTab(tabKey, title, props);
                }

                break;
            }

            case 'hide_show_columns': {
                finalTableRef.current?.editColumnsVisibility?.();
                break;
            }

            case 'refresh': {
                return refreshTable();
            }

            default: {
                return onRibbonAction(actionName, event, state); // Ensure return here
            }
        }
    }, [onRibbonAction, deleteItem, deleteSuccessMessage, name, modal]);

    const onRowActionCb = useCallback((row: TTableRow, action: TTableRowAction, event, state) => {
        const {key} = action;

        switch (key) {
            case 'edit': {
                if (createBehavior === 'modal') {
                    return AskForModal(modal, {viewState: {mode: 'edit', _id: row.original[idColumnName]}, onSave: refreshTable});
                } else {
                    TabsManager.openNewTab(createTab.tabKey, row.original.name, {sboId: row.original[idColumnName], refreshTable});

                    return;
                }

                break;
            }

            case 'delete': {
                const itemId = row.original[idColumnName];
                const itemName = row.original[nameColumnName];

                AskForConfirmation(`Delete ${itemName}`, strings('delete_sentence'), {
                    variant: 'error',
                    icon: faTrash,
                    onConfirm: async () => {
                        const res = await deleteItem(itemId);

                        if (res.isSuccessful()) {
                            refreshTable();
                            toast.success(deleteSuccessMessage);
                        }
                    }});
                break;
            }

            default: {
                return onRowAction(row, action, event, state); // Ensure return here
            }
        }
    }, [onRowAction, deleteItem, deleteSuccessMessage, name, modal]);

    const addTooltipText = useMemo(() => `${strings('add')} ${strings('new')} ${customAddButtonTooltip ?? name}`, [name, customAddButtonTooltip]);

    return (
        <Table<DataType>
            ref={finalTableRef}
            getColumns={getColumns}
            baseTableClasses={{
                theadCellTH: 'py-3 px-6 text-neutral-600 font-medium text-xs text-left relative min-w-40',
            }}
            emptyProps={emptyProps}
            onEmptyStateClick={canCreate && (() => modal && AskForModal(modal, {viewState: {mode: 'create'}, onSave: refreshTable}))}
            events={{
                onRowDoubleClick: (event, table, row) => {
                    if (!hasViewMode) return;

                    if (createBehavior == 'modal') {
                        return AskForModal(modal, {viewState: {mode: 'view', _id: row.original[idColumnName]}, onSave: refreshTable});
                    }

                    const {tabKey, title, props} = createTab;
                    TabsManager.openNewTab(tabKey, title, {...props, _id: row.original[idColumnName], mode: 'view'});
                },
            }}
            onRibbonAction={onRibbonActionCb}
            name={name}
            noPagination={noPagination}
            columns={tableColumns}
            defaults={{
                sortingState: [{id: defaultSortedColumn, desc: false}],
            }}
            getRowsCount={getRowsCount}
            rowActions={rowActions}
            onRowAction={onRowActionCb}
            getRows={getRows ? getRows : async (paginationState, sortingState, countState, searchState) => {
                const {pageSize, currentPage} = paginationState;
                const search = searchState.query.trim();
                const sort = {id: sortingState[0]?.id || 'name', order: sortingState[0]?.desc ? '-1' : '1'};
                const offset = pageSize * (currentPage - 1);
                const response = await getAllData(search, sort, offset, pageSize);

                if (!response.isSuccessful()) return [];

                const data = response.getData() as { data: object[] } | object[];

                if (!data) return [];

                const items = (Array.isArray(data) ? data : data?.data) || [];
                const count = _get(data, ['metadata', 0, 'count']);

                if (count !== undefined) countState.setCount(count);

                return items;
            }}
            ribbonActions={
                [
                    canCreate && {
                        key: 'add',
                        label: strings('add'),
                        buttonProps: {variant: 'primary'},
                        icon: faPlus,
                        tooltip: addTooltipText,
                    } || null,
                    'refresh',
                    ...customRibbonActions,
                    {
                        key: 'more',
                        icon: faEllipsisVertical,
                        label: 'More',
                        tooltip: 'Table Actions',
                        placement: 'right',
                        contextMenuEntries: [
                            {
                                key: 'hide_show_columns',
                                icon: faEye,
                                label: strings('hide_show_columns'),
                            },
                        ],
                    },
                ]
            }
        >
            {children}
        </Table>
    );
}

import {TSpreadsheetProps} from '@aktek/f4kit';
import {XFieldTypes} from '@aktek/types';

// Automatic
import AutomaticCellEdit from '@/components/Fields/Automatic/Automatic.Cell.Edit';
import AutomaticCellView from '@/components/Fields/Automatic/Automatic.Cell.View';
// Boolean
import BooleanCellEdit from '@/components/Fields/Boolean/Boolean.Cell.Edit';
import BooleanCellView from '@/components/Fields/Boolean/Boolean.Cell.View';
// ClosedList
import ClosedListCellEdit from '@/components/Fields/ClosedList/ClosedList.Cell.Edit';
import ClosedListCellView from '@/components/Fields/ClosedList/ClosedList.Cell.View';
// Color
import ColorCellEdit from '@/components/Fields/Color/Color.Cell.Edit';
import ColorCellView from '@/components/Fields/Color/Color.Cell.View';
// Date
import DateCellEdit from '@/components/Fields/Datetime.Date/Date.Cell.Edit';
import DateCellView from '@/components/Fields/Datetime.Date/Date.Cell.View';
// Time
import TimeCellEdit from '@/components/Fields/Datetime.Time/Time.Cell.Edit';
import TimeCellView from '@/components/Fields/Datetime.Time/Time.Cell.View';
// Datetime
import DatetimeCellEdit from '@/components/Fields/Datetime/Cell/Datetime.Cell.Edit';
import DatetimeCellView from '@/components/Fields/Datetime/Cell/Datetime.Cell.View';
// Float
import FloatCellEdit from '@/components/Fields/Float/Float.Cell.Edit';
import FloatCellView from '@/components/Fields/Float/Float.Cell.View';
// Formula
import FormulaCellEdit from '@/components/Fields/Formula/Formula.Cell.Edit';
import FormulaCellView from '@/components/Fields/Formula/Formula.Cell.View';
// Gps
import GpsCellEdit from '@/components/Fields/Gps/Gps.Cell.Edit';
import GpsCellView from '@/components/Fields/Gps/Gps.Cell.View';
// Import components for all field types
// Integer
import IntegerCellEdit from '@/components/Fields/Integer/Integer.Cell.Edit';
import IntegerCellView from '@/components/Fields/Integer/Integer.Cell.View';
// MediaAlbum
import MediaAlbumCellEdit from '@/components/Fields/MediaAlbum/MediaAlbum.Cell.Edit';
import MediaAlbumCellView from '@/components/Fields/MediaAlbum/MediaAlbum.Cell.View';
// MediaAudio
import MediaAudioCellEdit from '@/components/Fields/MediaAudio/MediaAudio.Cell.Edit';
import MediaAudioCellView from '@/components/Fields/MediaAudio/MediaAudio.Cell.View';
// MediaGeneric
import MediaGenericCellEdit from '@/components/Fields/MediaGeneric/MediaGeneric.Cell.Edit';
import MediaGenericCellView from '@/components/Fields/MediaGeneric/MediaGeneric.Cell.View';
// MediaImage
import MediaImageCellEdit from '@/components/Fields/MediaImage/MediaImage.Cell.Edit';
import MediaImageCellView from '@/components/Fields/MediaImage/MediaImage.Cell.View';
// MediaVideo
import MediaVideoCellEdit from '@/components/Fields/MediaVideo/MediaVideo.Cell.Edit';
import MediaVideoCellView from '@/components/Fields/MediaVideo/MediaVideo.Cell.View';
// Meta
import MetaCellEdit from '@/components/Fields/Meta/Meta.Cell.Edit';
import MetaCellView from '@/components/Fields/Meta/Meta.Cell.View';
// Multisubform
import MultisubformCellEdit from '@/components/Fields/Multisubform/Multisubform.Cell.Edit';
import MultisubformCellView from '@/components/Fields/Multisubform/Multisubform.Cell.View';
// RefAnnex
import RefAnnexCellEdit from '@/components/Fields/Ref.Annex/RefAnnex.Cell.Edit';
import RefAnnexCellView from '@/components/Fields/Ref.Annex/RefAnnex.Cell.View';
// ConnectedRef
import ConnectedRefCellEdit from '@/components/Fields/Ref.ConnectedRef/ConnectedRef.Cell.Edit';
import ConnectedRefCellView from '@/components/Fields/Ref.ConnectedRef/ConnectedRef.Cell.View';
// RefList
import RefListCellEdit from '@/components/Fields/Ref.List/RefList.Cell.Edit';
import RefListCellView from '@/components/Fields/Ref.List/RefList.Cell.View';
// RefListAnnex
import RefListAnnexCellEdit from '@/components/Fields/Ref.ListAnnex/RefListAnnex.Cell.Edit';
import RefListAnnexCellView from '@/components/Fields/Ref.ListAnnex/RefListAnnex.Cell.View';
// Ref
import RefCellEdit from '@/components/Fields/Ref/Ref.Cell.Edit';
import RefCellView from '@/components/Fields/Ref/Ref.Cell.View';
// Signature
import SignatureCellEdit from '@/components/Fields/Signature/Signature.Cell.Edit';
import SignatureCellView from '@/components/Fields/Signature/Signature.Cell.View';
// Stopwatch
import StopwatchCellEdit from '@/components/Fields/Stopwatch/Stopwatch.Cell.Edit';
import StopwatchCellView from '@/components/Fields/Stopwatch/Stopwatch.Cell.View';
// String
import StringCellEdit from '@/components/Fields/String/String.Cell.Edit';
import StringCellView from '@/components/Fields/String/String.Cell.View';
// Subform
import SubformCellEdit from '@/components/Fields/Subform/Subform.Cell.Edit';
import SubformCellView from '@/components/Fields/Subform/Subform.Cell.View';
// Tags
import TagsCellEdit from '@/components/Fields/Tags/Tags.Cell.Edit';
import TagsCellView from '@/components/Fields/Tags/Tags.Cell.View';

const renderCell: TSpreadsheetProps['cellRenderer'] = (props) => {
    const {column, type, isEditing, onChange, value} = props;

    switch (type) {
        case XFieldTypes.String:
            return isEditing ? <StringCellEdit onChange={onChange} value={value} />
                : <StringCellView value={value} />;

        case XFieldTypes.Integer:
            return isEditing ? <IntegerCellEdit onChange={onChange} value={value} />
                : <IntegerCellView value={value} />;

        case XFieldTypes.Float:
            return isEditing ? <FloatCellEdit onChange={onChange} value={value} />
                : <FloatCellView value={value} />;

        // reference fields ------------------------------------------------------------------------------------
        case XFieldTypes.Ref:
            return isEditing ? <RefCellEdit onChange={onChange} value={value} column={column}/>
                : <RefCellView value={value} column={column}/>;

        case XFieldTypes.RefAnnex:
            return isEditing ? <RefAnnexCellEdit value={value} column={column}/>
                : <RefAnnexCellView value={value} column={column}/>;

        case XFieldTypes.RefList:
            return isEditing ? <RefListCellEdit onChange={onChange} value={value} column={column}/>
                : <RefListCellView value={value} column={column}/>;

        case XFieldTypes.RefListAnnex:
            return isEditing ? <RefListAnnexCellEdit value={value} column={column}/>
                : <RefListAnnexCellView value={value} column={column}/>;

        case XFieldTypes.ConnectedRef:
            return isEditing ? <ConnectedRefCellEdit onChange={onChange} value={value} column={column}/>
                : <ConnectedRefCellView value={value} column={column}/>;

        // ----------------------------------------------------------------------------------------------------
        case XFieldTypes.Color:
            return isEditing ? <ColorCellEdit onChange={onChange} value={value} />
                : <ColorCellView value={value} isSquare={false} />;

        case XFieldTypes.ClosedList:
            const options = column.field.options;
            const selectedLabel = options[value]?.label;

            return isEditing ? <ClosedListCellEdit onChange={onChange} value={value} options={options}/>
                : <ClosedListCellView value={selectedLabel} />;

        case XFieldTypes.Signature:
            return isEditing ? <SignatureCellEdit onChange={onChange} value={value} />
                : <SignatureCellView value={value} />;

        case XFieldTypes.Boolean:
            return isEditing ? <BooleanCellEdit onChange={onChange} value={value} />
                : <BooleanCellView value={value} />;

        case XFieldTypes.Gps:
            return isEditing ? <GpsCellEdit onChange={onChange} lat={value?.lat} lng={value?.lng}/>
                : <GpsCellView lat={value?.lat} lng={value?.lng}/>;

        case XFieldTypes.MediaAudio:
            return isEditing ? <MediaAudioCellEdit onChange={onChange} value={value} />
                : <MediaAudioCellView value={value} />;

        case XFieldTypes.MediaImage:
            return isEditing ? <MediaImageCellEdit onChange={onChange} value={value} />
                : <MediaImageCellView value={value} />;

        case XFieldTypes.MediaVideo:
            return isEditing ? <MediaVideoCellEdit onChange={onChange} value={value} />
                : <MediaVideoCellView value={value} />;

        case XFieldTypes.MediaAlbum:
            return isEditing ? <MediaAlbumCellEdit onChange={onChange} value={value} />
                : <MediaAlbumCellView value={value} />;

        case XFieldTypes.MediaGeneric:
            return isEditing ? <MediaGenericCellEdit onChange={onChange} value={value} />
                : <MediaGenericCellView value={value} />;

        case XFieldTypes.Date:
            return isEditing ? <DateCellEdit onChange={onChange} value={value} />
                : <DateCellView value={value} />;

        case XFieldTypes.Time:
            return isEditing ? <TimeCellEdit onChange={onChange} value={value} />
                : <TimeCellView value={value} />;

        case XFieldTypes.Datetime:
            return isEditing ? <DatetimeCellEdit onChange={onChange} value={value} />
                : <DatetimeCellView value={value} />;

        case XFieldTypes.Multisubform:
            return isEditing ? <MultisubformCellEdit onChange={onChange} value={value} />
                : <MultisubformCellView value={value} />;

        case XFieldTypes.Subform:
            return isEditing ? <SubformCellEdit onChange={onChange} value={value} />
                : <SubformCellView value={value} />;

        case XFieldTypes.Meta:
            return isEditing ? <MetaCellEdit onChange={onChange} value={value} />
                : <MetaCellView value={value} />;

        case XFieldTypes.Automatic:
            return isEditing ? <AutomaticCellEdit onChange={onChange} value={value} />
                : <AutomaticCellView value={value} />;

        case XFieldTypes.Stopwatch:
            return isEditing ? <StopwatchCellEdit onChange={onChange} value={value} />
                : <StopwatchCellView value={value} />;

        case XFieldTypes.Formula:
            return isEditing ? <FormulaCellEdit onChange={onChange} value={value} />
                : <FormulaCellView value={value} />;

        case 'tags':
            return isEditing ? <TagsCellEdit onChange={onChange} value={value} />
                : <TagsCellView value={value} />;

        default:
            console.log('....not supported field type:', {value, column});

            return null;

    }
};

export default renderCell;


import {Breadcrumb, Icon, Label, TBreadcrumbStep} from '@aktek/f4kit';
import {faRefresh} from '@fortawesome/pro-regular-svg-icons';

import {useAdminPermissions} from '@/context/Permissions/usePermissions';
import {useGlobalFilesAndFolders} from '@/context/UserContext/Hooks/useGlobalFilesAndFolders';

import {FOLDERS_AND_DATA, REPOSITORIES, SHORTCUTS} from '../constants/constantIds.c';
import {TExplorerUIState} from '../hooks/useExplorerUI';
import {GetFoldersRightPanelHeaderClasses} from '../styles/FoldersRightPanel.Header.styles';
import AddFolderButton from './AddFolderButton';

export const FoldersRightPanelHeader = ({explorerUIState}:{explorerUIState: TExplorerUIState}) => {
    const {folders: foldersPermissions} = useAdminPermissions();

    const {refresh} = useGlobalFilesAndFolders();
    const {
        selectedFolder,
        ancestors,
        setSelectedFolder,
    } = explorerUIState;

    const classes = GetFoldersRightPanelHeaderClasses();

    const canAddFolder = foldersPermissions?.can_create && (selectedFolder.type == 'folder' || selectedFolder.id == FOLDERS_AND_DATA);

    const hasBreadCrumb = selectedFolder.id !== REPOSITORIES && selectedFolder.id !== SHORTCUTS;

    return (
        <div className={classes.container}>
            <div className={classes.breadcrumb}>
                {hasBreadCrumb && (
                    <Breadcrumb
                        className="!bg-neutral-50 !px-0 !py-0"
                        buttonProps={{className: '!pl-1.5'}}
                        size="sm"
                        separatorIconProps={null}
                        onStepClick={setSelectedFolder}
                        path={ancestors as TBreadcrumbStep[]}
                    />
                )}
                {!hasBreadCrumb && (
                    <Label
                        text={selectedFolder.label}
                        size="sm"
                        fontWeight="semibold"
                        color="primary-700"
                        className="pl-1"
                    />
                )}
            </div>

            <div className={classes.breadcrumb}>
                <Icon
                    icon={faRefresh}
                    color="neutral-600"
                    className="cursor-pointer p-2.5"
                    size="md"
                    onClick={refresh}
                />
                <AddFolderButton
                    isVisible={canAddFolder}
                    refresh={refresh}
                    parentId={selectedFolder?.id}
                />
            </div>
        </div>
    );
};


import {TagService} from '@aktek/morph-frontend-sdk';

export const fetchTagsDictionary = async () => {
    const res = await TagService.getAllTags();
    if (!res.isSuccessful()) return {};

    const resultTags = res.getData();

    const dictionary = resultTags[0]?.data?.reduce((acc, tag) => {
        acc[tag.tag] = tag.tag;

        return acc;
    }, {} as Record<string, string>);

    return dictionary;
};

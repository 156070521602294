import {Icon} from '@aktek/f4kit';
import {faBracketRound, faBracketRoundRight} from '@fortawesome/pro-regular-svg-icons';
import {Fragment} from 'react';

import SmartFilterPreviewGroupOperator from './SmartFilter.Preview.Group.Operator';
import SmartFilterPreviewRule from './SmartFilter.Preview.Rule';
import {TSmartFilterPreviewGroupProps} from './types/SmartFilter.Preview.t';

function SmartFilterPreviewGroup(props: TSmartFilterPreviewGroupProps) {
    const {group, smartFilter, fields, depth = 0, sboId} = props;
    const childRules = Object.keys(group?.rules || {}).map((e) => smartFilter?.[e]);
    const hasMoreThanOneChild = childRules.length > 1;

    return <div className="flex flex-row gap-1 flex-wrap">
        {depth > 0 && <Icon className="self-center" icon={faBracketRound} size="lg" color="semantic3-500" />}
        {childRules.map((rule, index) => {
            if (rule.type == 'group') {
                return <Fragment key={rule.id}>
                    <SmartFilterPreviewGroup
                        key={rule.id}
                        fields={fields}
                        sboId={sboId}
                        depth={depth + 1}
                        smartFilter={smartFilter}
                        group={rule} />

                    {(index < childRules.length - 1 && hasMoreThanOneChild) && <SmartFilterPreviewGroupOperator key={`${rule.id}op`} operator={group.operator} />}
                </Fragment>;
            }

            return <Fragment key={rule.id}>
                <SmartFilterPreviewRule
                    key={rule.id}
                    fields={fields}
                    sboId={sboId}
                    depth={depth + 1}
                    smartFilter={smartFilter}
                    rule={rule} />
                {(index < childRules.length - 1 && hasMoreThanOneChild) && <SmartFilterPreviewGroupOperator key={`${rule.id}op`} operator={group.operator} />}
            </Fragment>;
        })}
        {depth > 0 && <Icon className="self-center" size="lg" icon={faBracketRoundRight} color="semantic3-500" />}

    </div>;
}

export default SmartFilterPreviewGroup;

import {Label} from '@aktek/f4kit';

import IODict from '@/iohub/IODict';

import SmartFilterPreviewGroup from './SmartFilter.Preview.Group';
import {TSmartFilterPreview} from './types/SmartFilter.Preview.t';

function SmartFilterPreview(props: TSmartFilterPreview) {
    const {smartFilter, fields, sboId} = props;

    const mainRule = smartFilter?.['main'];

    const node = fields ? <SmartFilterPreviewGroup
        key="main"
        group={mainRule}
        fields={fields}
        sboId={sboId}
        smartFilter={smartFilter}
    />
        : <IODict type="field" sboId={sboId}>
            {({dict}) => {
                return <SmartFilterPreviewGroup
                    key="main"
                    group={mainRule}
                    fields={dict}
                    sboId={sboId}
                    smartFilter={smartFilter}
                />;
            }}
        </IODict>;

    return <div className="border border-neutral-200 px-4 py-3 rounded-lg flex flex-col gap-3 mx-3">
        <Label text="Rule Summary" color="neutral-400" size="sm"/>
        {node}
    </div>;
}

export default SmartFilterPreview;


import {TModalController} from '@aktek/f4kit';
import {ForwardRefExoticComponent, PropsWithoutRef, RefAttributes} from 'react';

import {TF4CreatableModalProps} from '@/components/F4CreatableModal/types/F4CreatableModalProps.t';

import AskForModal from './AskForModal';

export type TCreationModalBaseProps =
TF4CreatableModalProps & { [k: string]: unknown };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default async function AskForCreatableModal<P extends Partial<TCreationModalBaseProps>>(
    Element: ForwardRefExoticComponent<PropsWithoutRef<P> & RefAttributes<TModalController>>,
    props?: P) {
    return AskForModal<P>(Element, props);
}


import {SBOService} from '@aktek/morph-frontend-sdk';

import F4ViewableComponent from '@/components/F4ViewableComponent/F4ViewableComponent';
import useTableRef from '@/components/Table/Hooks/useTableRef';
import {useAdminPermissions} from '@/context/Permissions/usePermissions';
import {strings} from '@/localization/i18n';

import {DATA_COLUMNS} from './DataStructure.columns';
import {TSBO} from './types/SBOEditor.t';

export default function DataStructureTable() {
    const tableRef = useTableRef();
    const adminPermissions = useAdminPermissions();
    const {reportStructure} = adminPermissions;

    return (
        <F4ViewableComponent<TSBO>
            name="data structure"
            tableRef={tableRef}
            createBehavior="tab"
            tabName={'dataStructureEditor'}
            createTab={{tabKey: 'dataStructureEditor', title: strings('new_data_structure')}}
            tableColumns={DATA_COLUMNS()}
            canCreate={reportStructure?.can_create}
            getAllData={SBOService.getUsableSBOs}
            deleteItem={SBOService.deleteSBO}
            deleteSuccessMessage={strings('data_structure_deleted_successfully')}
            emptyProps={{addLabel: strings('new_data_structure')}}
        />
    );
}

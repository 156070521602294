import {useEffect, useState} from 'react';

import {strings} from '@/localization/i18n';

import {FOLDERS_AND_DATA} from '../constants/constantIds.c';
import {SearchFunction} from '../utils/SearchFunction';

const useFilteredItems = (
    data,
    searchValue,
    includeMainObject: boolean,
    filterType?: string,
    id?: string | null,
    parentId?: string | null,
    removeMatchingItem: boolean = true,
) => {
    const [items, setItems] = useState([]);

    // const addCheckedToMatchingItems = (items) => {
    //     return items.map((item) => {
    //         const children = item.children?.length
    //             ? addCheckedToMatchingItems(item.children)
    //             : [];

    //         const hasMatchingChild = children.some((child) => child.parentId === parentId);

    //         return {
    //             ...item,
    //             children,
    //             checked: hasMatchingChild || item.parentId === parentId ? true : item.checked,
    //         };
    //     });
    // };

    const removeMatchingItems = (items) => {
        return items
            .filter((item) => item.id !== id)
            .map((item) => ({
                ...item,
                children: item.children?.length ? removeMatchingItems(item.children) : [],
            }));
    };

    useEffect(() => {
        const filterAndSetItems = () => {
            let filteredData = data || [];

            if (includeMainObject) {
                filteredData = [
                    {
                        id: FOLDERS_AND_DATA,
                        label: strings('folders_and_data'),
                        type: 'folder',
                        isNodeExpanded: true,
                        checked: !parentId,
                        labelProps: {color: 'neutral-900', fontWeight: 'semibold'},
                        children: filteredData,
                    },
                ];
            }

            // if (parentId) {
            //     filteredData = addCheckedToMatchingItems(filteredData);
            // }

            if (id && removeMatchingItem) {
                filteredData = removeMatchingItems(filteredData);
            }

            if (filterType) {
                const filterItems = (item) => item.type === filterType;
                filteredData = includeMainObject
                    ? [{...filteredData[0], children: filteredData[0].children.filter(filterItems)}]
                    : filteredData.filter(filterItems);
            }

            if (searchValue) {
                filteredData = SearchFunction(filteredData, searchValue);
            }

            setItems(filteredData);
        };

        filterAndSetItems();
    // }, [searchValue, data, includeMainObject, filterType, id, parentId]);
    }, [searchValue, data]);

    return items;
};

export default useFilteredItems;

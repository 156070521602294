import {F4FormDiv, TF4FormRefFn} from '@aktek/f4form';
import {ContentDivider, Icon, Label, Popover} from '@aktek/f4kit';
import {faChevronDown, faChevronUp, faGripDotsVertical, faPlus} from '@fortawesome/pro-regular-svg-icons';
import {ReactNode, useState} from 'react';

import F4Textbox from '@/components/F4Elements/F4TextBox';
import {strings} from '@/localization/i18n';

import SectionsFieldsPopoverActions from '../helpers/SectionsFieldsPopoverActions.fn.';
import SectionsFieldsPopoverTypes from '../helpers/SectionsFieldsPopoverTypes.fn';
import {TField, TFieldTypes, TSection} from '../types/SBOEditor.t';

type TSBOEditorSectionProps ={
    children?: ReactNode
    section: TSection
    index: number
    addSection: (duplicatedSection: TSection, fieldId: string) => void
    deleteSection: (sectionId: string) => void
    addField: (fieldType: TFieldTypes, sectionId: string, duplicatedField: TField) => void
    sboFormRef: React.MutableRefObject<TF4FormRefFn>
}

function SBOEditorSection(props:TSBOEditorSectionProps) {
    const [isSectionExpanded, setIsSectionExpanded] = useState(true);

    return (
        <F4FormDiv className="group/section">
            {/* ********** Section ********** */}
            <F4FormDiv className="flex items-center ml-5">
                <Popover hideArrow placement="left" content={
                    <SectionsFieldsPopoverActions
                        type="section"
                        section={props.section}
                        sboFormRef={props.sboFormRef}
                        addSection={props.addSection}
                        deleteSection={props.deleteSection}
                    />
                }>
                    <Icon
                        icon={faGripDotsVertical}
                        color="neutral-600"
                        className="hidden group-hover/section:!block w-4 h-4 -ml-5 cursor-pointer"/>
                </Popover>
                <ContentDivider
                    backgroundColor="neutral-50"
                    className="my-2"
                    middleChild={
                        <F4Textbox isGhost required
                            inputClassName="text-center !p-0 !w-42"
                            placeholder={`Section ${props.index + 1}`}
                            name={`sectionsMap.${props.section.id}.name`}
                            className="text-neutral-600 rounded-none placeholder-neutral-300"
                            wrapper={{className: 'text-center'}}/>}
                    rightChild={
                        <div className="flex items-center !w-24 !cursor-pointer select-none" onClick={()=>setIsSectionExpanded(!isSectionExpanded)}>
                            <Icon
                                color="neutral-600"
                                className="mr-2 font-semibold"
                                icon={isSectionExpanded ? faChevronUp : faChevronDown}
                            />
                            <Label
                                text={isSectionExpanded ? strings('collapse'): strings('expand')}
                                className="text-neutral-600 text-sm font-semibold !cursor-pointer" />
                        </div>}
                />
            </F4FormDiv>

            <F4FormDiv className="flex flex-col gap-6 group/section">
                {/* ********** Fields ********** */}
                {isSectionExpanded && props.children}

                {/* ********** New Field ********** */}
                {isSectionExpanded
                && <Popover
                    hideArrow
                    placement="right"
                    portal
                    content={
                        <SectionsFieldsPopoverTypes
                            addField={props.addField}
                            addSection={props.addSection}
                            section={props.section}
                        />
                    }>
                    <div className="flex items-center gap-2 mt-4">
                        <Icon icon={faPlus} color="neutral-600" className="w-3 h-3 cursor-pointer"/>
                        <Label text="Type '/' to inset block" color="neutral-400" className="cursor-pointer"/>
                    </div>
                </Popover>}
            </F4FormDiv>
        </F4FormDiv>
    );
}

export default SBOEditorSection;


import AskForMenu from '@/asks/AskForMenu';

import {TStatelessTableProps} from '../Types/Table.t';
import {GetActionsFromRowActions} from './GetActionsFromRowActions';
import {IsActionVisible} from './IsActionVisible.fn';

export const getRowContextMenu = ({rowActions, state, onRowAction}: Partial<TStatelessTableProps>) =>
    (event, table, row) => {
        const allActions = GetActionsFromRowActions(rowActions, row);

        const filteredActionsKeys = allActions
            .filter((action) => IsActionVisible(action, row))
            .map((action) => {
                action.onClick = (event) => onRowAction(row, action, event, state);

                return action;
            });

        AskForMenu(event, filteredActionsKeys, {placement: 'bottom-start'});
        event.preventDefault();
    };


import AskForModal from '@/asks/AskForModal';
import type {TConfirmationModalProps} from '@/components/Modals/ConfirmationModal';
import ConfirmationModal from '@/components/Modals/ConfirmationModal';

export default function(
    title: string,
    message: string,
    options: Partial<TConfirmationModalProps> = {},
) {
    return new Promise<boolean>((resolve) => {
        return AskForModal(ConfirmationModal,
            {title, message, ...options,
                onConfirm: ()=>{
                    resolve(true);
                    options?.onConfirm?.();
                }});
    });
}


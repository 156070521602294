import {F4FormDiv, F4FormElementDecorator, F4FormStep} from '@aktek/f4form';
import {GroupsService} from '@aktek/helios-frontend-sdk';
import _isEmpty from 'lodash/isEmpty';
import {useEffect, useState} from 'react';

import F4Dropdown from '@/components/F4Elements/F4Dropdown';
import {useAdminPermissions} from '@/context/Permissions/usePermissions';
import {strings} from '@/localization/i18n';

import {GetUserTypes} from '../constants/userConstants.c';

export function CompanyRelatedInfo() {
    const [_groups, setGroups] = useState({});
    const noGroups = _isEmpty(_groups);
    const {groups} = useAdminPermissions();

    const getAllGroups = async () => {
        if (!groups?.can_view) return;
        const res = await GroupsService.getAllGroups();

        if (res.Message == 'Success!') {
            const groupsData = res.getData();
            const groupsMap = groupsData.reduce((map, group) => {
                if (group.name) map[`${group._id}`] = group.name;

                return map;
            }, {});
            setGroups(groupsMap);

            return groupsMap;
        }
    };

    useEffect(() => {
        getAllGroups();
    }, [groups]);

    const getGroupsPlaceHolder = ():string => {
        if (noGroups) {
            return strings('no_groups_found');
        }

        return undefined;
    };

    return (
        <F4FormStep step="companyRelatedInfo">
            <F4FormDiv className="flex flex-col gap-4">
                <F4Dropdown
                    colSpan={12}
                    name="userType"
                    options={GetUserTypes()}
                    required
                    isClearable
                    label={strings('user_type')}
                    className="mt-1"
                />
                {groups?.can_view
            && <F4Dropdown
                name="groups"
                options={_groups}
                colSpan={12}
                isDisabled={noGroups}
                hideArrow={noGroups}
                isClearable={!noGroups}
                placeholder={getGroupsPlaceHolder()}
                isMulti
                label={strings('group')}
                className="mt-1 w-full"
            />}
            </F4FormDiv>
        </F4FormStep>
    );
}

const F4CompanyRelatedInfo = F4FormElementDecorator(CompanyRelatedInfo);
export default F4CompanyRelatedInfo;


import {CompanyTypes} from '@/constants/CompanyTypes.c';
import {UserTypes} from '@/constants/UserTypes.c';
import {TCompany} from '@/types/Company';
import {TCompanySettings} from '@/types/CompanySettings';

type TGetUpdatedCompanySettings = (companySettings: TCompanySettings) => TCompanySettings

export const getUpdatedCompanySettings: TGetUpdatedCompanySettings = (companySettings) => {
    return {
        ...companySettings,
        canFreeze: !!companySettings?.settings_freeze_account_admin_freeze,
        canAdminGenerateLink: !!companySettings?.settings_admin_reset_user_pass,
    };
};

type TGetUpdatedUser = (user: TUser) => TUser

export const getUpdatedUser: TGetUpdatedUser = (user) => {
    return {
        ...user,

        isRegularUser: user?.userType == UserTypes.Regular,
        isAccountManager: user?.userType == UserTypes.AccountManager,
    };
};

type TGetUpdatedCurrentCompany = (company: TCompany) => TCompany

export const getUpdatedCompany: TGetUpdatedCurrentCompany = (company) => {
    return {
        ...company,
        isOPS: company?.companyType == CompanyTypes.OPS,
        isCompany: company?.companyType == CompanyTypes.Company,
        isAgency: company?.companyType == CompanyTypes.Agency,
    };
};


import {useRef} from 'react';

export type TF4CreatableFormRef = {
    currentStep: number;
    goToNextStep: ()=> void;
    goToPreviousStep: ()=> void;
    saveForm: () => void;
    setValue: (name: string, value: unknown) => void
    getData: () => unknown
    getValue: (name: string) => unknown
    getIsChanged: ()=> boolean;
    setIsChanged: (v: boolean)=> void;
}

export default function useF4CreatableFormRef() {
    return useRef<TF4CreatableFormRef>();
}

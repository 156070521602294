import {F4Form, useF4FormRef} from '@aktek/f4form';
import {cloneDeep} from 'lodash';
import shortUUID from 'short-uuid';

import useForceUpdate from '@/hooks/useForceUpdate';
import IODict from '@/iohub/IODict';

import BaseFilter from '../consts/BaseFilter';
import type {TSmartFilter, TSmartFilterGroup, TSmartFilterRule} from '../types/SmartFilter.t';
import SmartFilterGroup from './SmartFilter.Builder.Group';
import type {TSmartFilterProps} from './types/SmartFilter.Builder.t';

function SmartFilter(props: TSmartFilterProps) {
    const formRef = useF4FormRef();
    const {value = BaseFilter, sboId, fields, onChange} = props;
    const forceUpdate = useForceUpdate()[1];

    const handleAddRule = (parent: string) => {
        const data = formRef.current.getData() as TSmartFilter;
        const mutableSmartFilter = cloneDeep(data);
        const id = shortUUID.generate();

        mutableSmartFilter[id] = {
            id,
            type: 'rule',
        } as TSmartFilterRule;

        const parentGroup = mutableSmartFilter[parent] as TSmartFilterGroup;
        parentGroup.rules ??= {};
        parentGroup.rules[id] = 1;

        formRef.current.setData(mutableSmartFilter, forceUpdate);
    };

    const handleAddGroup = (parent: string) => {
        const data = formRef.current.getData() as TSmartFilter;
        const mutableSmartFilter = cloneDeep(data);
        const id = shortUUID.generate();

        mutableSmartFilter[id] = {
            id,
            type: 'group',
            operator: '$and',
            rules: {},
        } as TSmartFilterGroup;

        const parentGroup = mutableSmartFilter[parent] as TSmartFilterGroup;
        parentGroup.rules ??= {};
        parentGroup.rules[id] = 1;

        formRef.current.setData(mutableSmartFilter, forceUpdate);
    };

    const handleDelete = (parentId: string, id: string) => {
        const data = formRef.current.getData() as TSmartFilter;
        const mutableSmartFilter = cloneDeep(data);

        const handleRule = (parentId: string, id: string) => {
            const rule = mutableSmartFilter[id];
            const {type} = rule;

            if (type == 'group') {
                const {rules} = rule;

                const potentialChildren = Object.keys(rules || {});

                if (type == 'group') {
                    potentialChildren.forEach((ruleId) => handleRule(id, ruleId));
                }
            }

            delete (mutableSmartFilter[parentId] as TSmartFilterGroup).rules[id];
            delete mutableSmartFilter[id];
        };

        handleRule(parentId, id);

        formRef.current.setData(mutableSmartFilter, forceUpdate);
    };

    const node = fields ? <SmartFilterGroup
        sboId={sboId}
        fields={fields}
        smartFilter={formRef.current?.getData() as TSmartFilter}
        group={formRef.current?.getData()?.['main']}
        onDelete={handleDelete}
        onAddGroup={handleAddGroup}
        onAddRule={handleAddRule}
        parentId="main"
    />
        : <IODict type="field" sboId={sboId}>
            {({dict}) => {
                return <SmartFilterGroup
                    sboId={sboId}
                    fields={dict}
                    smartFilter={formRef.current?.getData() as TSmartFilter}
                    group={formRef.current?.getData()?.['main']}
                    onDelete={handleDelete}
                    onAddGroup={handleAddGroup}
                    onAddRule={handleAddRule}
                    parentId="main"
                />;
            }}
        </IODict>;

    return <F4Form
        className="w-full"
        onChange={onChange}
        ref={formRef}
        initialValue={value}
        layout={null}>
        {node}
    </F4Form>;
}

export default SmartFilter;


export const isEventEnterKey = (event) => event?.key === 'Enter';

export const onEnter = (callback) => (event) => {
    if (!isEventEnterKey(event)) return;

    callback(event);
};

export const EventKeys = {isEnter: isEventEnterKey};

export default {EventKeys};


import {F4FormElementDecorator} from '@aktek/f4form';
import {Button, TButtonProps, usePropState} from '@aktek/f4kit';
import classNames from 'classnames';

type TToggleButtonProps ={
    onChange:(value:boolean)=> void
    value: boolean
    text: string

} & TButtonProps

const ToggleButton = ({onChange, value, text, ...props}:TToggleButtonProps)=>{
    const [_value, setValue] = usePropState(value, false, onChange);
    ;

    return <Button {...props} label={text} onClick={()=>setValue(!_value)} className={classNames(_value ? 'bg-primary-25' : 'bg-neutral-50', props.className)}
        textColor={_value ? 'primary-600' : 'neutral-700'} />;
};

const F4ToggleButton = F4FormElementDecorator<TToggleButtonProps>(ToggleButton);
export default F4ToggleButton;

import {TField} from '../types/SBOEditor.t';
import SBOEditorField from './SBOEditorField';

export default function SBOEditorSectionFields(props) {
    const {section, currentSBO} = props;
    const result = [];

    if (section && section.fields) {
        section.fields.forEach((fieldId, index) => {
            if (currentSBO?.fieldsMap[fieldId]) {
                const field: TField = currentSBO?.fieldsMap[fieldId];
                const fieldRender
                = <SBOEditorField
                    field={field}
                    index={index}
                    sectionId={section.id}
                    sboFormRef={props.sboFormRef}
                    deleteField={props.deleteField}
                    addField={props.addField}
                    onMoveFieldToTable={props.onMoveFieldToTable}
                    tableFields={currentSBO.tableFields}
                />;
                result.push(fieldRender);
            }
        });
    }

    return result;
}

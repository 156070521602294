import {F4Nestor} from '@aktek/f4form';
import {ContentDivider, Label, TDropdownOption, TDropdownValue} from '@aktek/f4kit';
import Horae from '@aktek/horae';

import F4Dropdown from '@/components/F4Elements/F4Dropdown';
import F4ToggleSwitch from '@/components/F4Elements/F4Toggle';
import {useAdminPermissions} from '@/context/Permissions/usePermissions';
import {strings} from '@/localization/i18n';

import {DATE_FORMAT} from '../constants/Settings.c';

export default function TimezoneSettings() {
    const timezoneOption = Horae.getTimeZonesOptions();
    const transformedObject: Record<TDropdownValue, TDropdownOption> = {};

    timezoneOption.forEach((element) => {
        transformedObject[element.value] = element.label;
    });

    const {companySettings: companySettingsPermissions} = useAdminPermissions();

    return (
        <F4Nestor name="timezone">

            <div className="mt-2">
                <ContentDivider
                    backgroundColor="neutral-50"
                    middleChild={
                        <Label text={strings('timezone')}
                            className="text-neutral-600 font-medium"/>}/>
            </div>

            <F4Dropdown
                name="settings_timezone"
                required
                isDisabled={!companySettingsPermissions?.can_edit}
                label={strings('timezone')}
                options={transformedObject}
                className="flex justify-start items-start flex-col"/>

            <F4Dropdown
                name="settings_date_format"
                required
                isDisabled={!companySettingsPermissions?.can_edit}
                label={strings('date_format')}
                options={DATE_FORMAT}
                className="flex justify-start items-start flex-col mb-8" />

            <F4ToggleSwitch
                name="settings_is_date_input_timezone"
                label=""
                size="md"
                isDisabled={!companySettingsPermissions?.can_edit}
                descriptionClassName="!text-neutral-700 !font-medium"
                description={strings('tie_date_and_time_to_company_timezone')}

            />

        </F4Nestor>
    );
}


import F4TableSpreadsheet from '@/components/F4TableSpreadsheet/F4TableSpreadsheet';
import F4ViewableComponent from '@/components/F4ViewableComponent/F4ViewableComponent';
import SmartFilterModal from '@/components/SmartFilter/SmartFilter.Modal';
import {strings} from '@/localization/i18n';

import {DATA_TABLE_EMPTY_PROPS} from './constants/DataTable.EmptyProps.c';
import {useDataTable} from './hooks/useDataTable';

type TDataTableView = {
    sboId: string;
};

const DataTableView = (props: TDataTableView) => {
    const {sboId} = props;

    const {
        getRows,
        getColumns,
        getRowsCount,
        isEditMode,
        filter,
        setFilter,
        onRibbonAction,
        ribbonActions,
        spreadsheetRef,
        smartFilterModalRef,
    } = useDataTable(sboId);

    return (
        <div className="bg-neutral-50 h-full p-4">
            <SmartFilterModal
                ref={smartFilterModalRef}
                sboId={sboId}
                filter={filter}
                onChange={setFilter}
            />
            <F4ViewableComponent
                onRibbonAction={onRibbonAction}
                defaultSortedColumn="designation"
                name={strings('data_table')}
                getColumns={getColumns}
                emptyProps={DATA_TABLE_EMPTY_PROPS}
                getRowsCount={getRowsCount}
                customRibbonActions={ribbonActions}
                getAllData={getRows}
            >
                {isEditMode && (
                    <F4TableSpreadsheet
                        spreadsheetRef={spreadsheetRef}
                        sboId={sboId}
                        mode="edit"
                        filter={{}}
                    />
                )}
            </F4ViewableComponent>
        </div>
    );
};

export default DataTableView;

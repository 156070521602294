
import {faPen, faTrash} from '@fortawesome/pro-regular-svg-icons';

import {TRowActionsBuilder} from '@/components/Table/Types/Table.t';
import {strings} from '@/localization/i18n';
import {TPermission} from '@/types/Permissions.t';

export const GetGroupRowActions=(groupsPermissions:TPermission) =>(): TRowActionsBuilder => {
    return [
        {
            key: 'edit',
            label: strings('edit'),
            isVisible: groupsPermissions?.can_edit,
            showOnRow: true,
            icon: faPen,
        },
        {
            key: 'delete',
            label: strings('delete'),
            isVisible: groupsPermissions?.can_edit,
            showOnRow: true,
            icon: faTrash,
        },
    ];
};

import {F4Nestor} from '@aktek/f4form';
import {ContentDivider, Label} from '@aktek/f4kit';

import F4Dropdown from '@/components/F4Elements/F4Dropdown';
import F4Textbox from '@/components/F4Elements/F4TextBox';
import {useAdminPermissions} from '@/context/Permissions/usePermissions';
import {strings} from '@/localization/i18n';

import {PASSWORD_STRENGTH} from '../constants/Settings.c';

export default function LoginSettings() {
    const {companySettings: companySettingsPermissions} = useAdminPermissions();

    return (
        <F4Nestor name="login">
            <div className="mt-2">
                <ContentDivider
                    backgroundColor="neutral-50"
                    middleChild={
                        <Label text={strings('login')}
                            className="text-neutral-600 font-medium"
                        />}
                />
            </div>
            <F4Dropdown
                className="flex justify-start items-start flex-col"
                name="settings_password_strength"
                label={strings('required_password_strength')}
                colSpan={3}
                isDisabled={!companySettingsPermissions?.can_edit}
                options={PASSWORD_STRENGTH}
                required
            />
            <F4Textbox
                name="settings_login_token_validity"
                colSpan={3}
                allowDecimal={false}
                isDisabled={!companySettingsPermissions?.can_edit}
                required
                type="number"
                min={1}
                placeholder="30"
                label = {strings('login_session_period')}
                rightPlaceholder={strings('minutes')}
            />
        </F4Nestor>
    );
}


import {F4FormElementDecorator} from '@aktek/f4form';
import {ButtonGroup, TButtonProps} from '@aktek/f4kit';
import {TButtonGroupProps} from 'node_modules/@aktek/f4kit/dist/components/TierTwo/ButtonGroup/Types/ButtonGroupProps.t';

type TOptionsButtonGroupProps = {
    options: Record<string, TButtonProps>;
    value?: string;
    onChange?: (value: string) => void;
} & Omit<TButtonGroupProps, 'buttons'>;

function OptionsButtonGroup(props: TOptionsButtonGroupProps) {
    const {options, onChange, value, ...rest} = props;
    const buttons: TButtonProps[] = [];

    const values = Object.keys(options);

    for (const val of values) {
        const backgroundColor = val === value ? 'neutral-50' : 'white';
        const textColor = val === value ? 'semantic3-500' : 'black';

        buttons.push({...options[val], variant: 'white', textColor, backgroundColor, onClick: ()=>{
            onChange(val);
        }});
    }

    return <ButtonGroup buttons={buttons} {...rest} />;
}

const F4OptionsButtonGroup = F4FormElementDecorator<TOptionsButtonGroupProps>(OptionsButtonGroup);

export default F4OptionsButtonGroup;

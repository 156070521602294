import {Icon} from '@aktek/f4kit';

import {operators} from '../consts/GetOperators.fn';
import {TSmartFilterPreviewOperatorProps} from './types/SmartFilter.Preview.t';

function SmartFilterPreviewOperator(props: TSmartFilterPreviewOperatorProps) {
    const {operator} = props;
    if (!operator) return null;

    return <Icon className="items-center self-center" {...(operators[operator]?.object.icon)} color="semantic3-600" />;
}

export default SmartFilterPreviewOperator;

import {F4Nestor, useF4FormContext} from '@aktek/f4form';
import {ContentDivider, Label} from '@aktek/f4kit';
import Atlas from '@aktek/ops-atlas';
import {useEffect, useRef, useState} from 'react';

import {strings} from '@/localization/i18n';

import {DEFAULT_CENTER, DEFAULT_ZOOM} from '../constants/Settings.c';

export default function Geographical() {
    const f4FormContext = useF4FormContext();
    const [mapWidth, setMapWidth] = useState(0);
    const [mapHeight, setMapHeight] = useState(0);

    const geo = f4FormContext.getValue('geo');

    const defaultLayer = geo?.['default_base_layer'];
    const {center = DEFAULT_CENTER, zoom = DEFAULT_ZOOM} = geo?.['geo_center'] || {};

    useEffect(() => {
        const handleResize = () => {
            setMapWidth(window.innerWidth * 0.8);
            setMapHeight(window.innerHeight * 0.5);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (!mapRef?.current) return;
        setTimeout(() => {
            mapRef.current?.setMapZoom?.(zoom);
            mapRef.current?.setMapCenter?.(center);
        } );
    }, [zoom, center?.lat, center?.lng]);

    const mapRef = useRef();

    if (!geo) return;

    return (
        <F4Nestor name="geo">
            <div className="mt-8">
                <ContentDivider
                    backgroundColor="neutral-50"
                    middleChild={
                        <Label text={strings('geographical')}
                            className="text-neutral-600 font-medium"
                        />}
                />
            </div>
            <div className="flex justify-center items-center">
                <Atlas hasTimeline hideDrawing
                    ref={mapRef}
                    center= {[center.lat ?? center[0], center.lng ?? center[1]]}
                    zoom={zoom}
                    defaultLayer={defaultLayer}
                    height={mapHeight}
                    width={mapWidth}
                    onMapClick= {(position) => f4FormContext.setValue('geo_center.center', position)}
                    onMapViewChange= {(result) => f4FormContext.setValue('geo_center.zoom', result.zoom)}
                    onMapTypeChange={(type) => f4FormContext.setValue('default_base_layer', type)}
                />
            </div>
        </F4Nestor>
    );
}

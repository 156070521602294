import {useRef} from 'react';

import type {TTableController} from '../Types/TableRef.t';

export default function useTableRef(ref?) {
    if (ref) {
        return ref as React.MutableRefObject<TTableController>;
    }

    return useRef<TTableController>();
}


import {useUserContext} from '../UserContext.Provider';

export function useUpdateUserCompanies() {
    const {setUserAuth}= useUserContext();

    return (newUsersCompanies) => {
        ls.userCompanies = newUsersCompanies;
        setUserAuth((data) => ({...data, userCompanies: newUsersCompanies}));
    };
}

import IOSelect from '@/iohub/IOSelect';

const ConnectedRefCellEdit = ({value, onChange, column = {}}) => {
    const {field, affecter} = column;

    if (!field) return null;

    const isMulti = affecter?.type == 'refList';
    let _value = value;

    if (isMulti) {
        _value = value?.map?.((refValue) => refValue?._id) || [];
    }

    return <IOSelect autoOpenOnMount
        isMulti={isMulti}
        value={_value}
        sboId={field?.sboId}
        displayFieldId={field?.fieldId}
        type="ref"
        onChange={(value) => {
            if (isMulti) {
                const updatedValues = value?.map?.((_id) => ({_id}));
                onChange(updatedValues);

                return;
            }

            onChange(value);
        }}
    />;
};

export default ConnectedRefCellEdit;

import {F4FormDiv, TF4FormRefFn} from '@aktek/f4form';
import {Icon, Image, Label, Popover} from '@aktek/f4kit';
import {faChevronDown, faChevronRight, faEye, faEyeSlash, faGripDotsVertical, faInfoCircle, faInputText} from '@fortawesome/pro-regular-svg-icons';
import {useState} from 'react';

import F4NSwitch from '@/components/F4Elements/F4NSwitch';
import F4Textbox from '@/components/F4Elements/F4TextBox';
import F4ToggleButton from '@/components/F4Elements/F4ToggleButton';
import FreeTextIcon from '/src/assets/type-01.png';

import SectionsFieldsPopoverActions from '../helpers/SectionsFieldsPopoverActions.fn.';
import {TField, TFieldTypes} from '../types/SBOEditor.t';

type TSBOEditorFieldProps = {
    field: TField
    sectionId:string
    index:number
    tableFields?: Array<string>
    onMoveFieldToTable: (fieldId: string) => void
    deleteField: (fieldId: string) => void
    addField: (fieldType: TFieldTypes, sectionId: string, duplicatedField: TField) => void
    sboFormRef: React.MutableRefObject<TF4FormRefFn>
}

function SBOEditorField(props:TSBOEditorFieldProps) {
    const [isFieldExpanded, setIsFieldExpanded] = useState(false);
    const [showDescription, setShowDescription] = useState(true);
    const NSwitchOptions = [
        {icon: faInputText, value: true},
        {icon: faInfoCircle, value: false},
    ];

    return (
        <F4FormDiv className="group/field">
            <F4FormDiv className="flex ml-5">
                <Popover hideArrow placement="left" content={
                    <div className="inline">
                        <SectionsFieldsPopoverActions
                            type="field"
                            field={props.field}
                            sboFormRef={props.sboFormRef}
                            deleteField={props.deleteField}
                            addField={props.addField}
                        />
                    </div>}>

                    <Icon icon={faGripDotsVertical} color="neutral-600"
                        className="hidden group-hover/field:!block w-4 h-4 mt-5 -ml-5 cursor-pointer"/>
                </Popover>

                <F4FormDiv className="flex flex-col bg-neutral-100 rounded-xl items-center p-2 px-4 gap-2" >
                    {/* ********** First line ********** */}
                    <div className="flex items-center justify-between w-full">
                        <div className="flex items-center gap-2 w-full">
                            <Icon className="w-3 h-3" color="neutral-600"
                                onClick={()=> setIsFieldExpanded(!isFieldExpanded)}
                                icon={isFieldExpanded ? faChevronDown : faChevronRight} />

                            <Image alt={props.field.type} src={FreeTextIcon} className="w-4 h-4"/>

                            <F4Textbox isGhost required
                                placeholder="Name of field" inputClassName="!text-base"
                                name={`fieldsMap.${props.field.id}.name`}
                                className="text-neutral-600 rounded-none placeholder-neutral-300"/>
                        </div>
                        {isFieldExpanded
                        && <div className="flex items-center gap-3 float-end">
                            <F4ToggleButton isGhost text={'Required'}
                                name={`fieldsMap.${props.field.id}.isRequired`} className="text-sm" size="sm"/>

                            <Icon color="neutral-600"
                                icon={props.tableFields.indexOf(props.field.id)!== -1 ? faEye : faEyeSlash}
                                onClick={()=>props.onMoveFieldToTable(props.field.id)}/>
                        </div>}
                    </div>

                    {isFieldExpanded
                        && <>
                            {/* ********** Second line ********** */}
                            <div className="flex items-center justify-between gap-2 w-full">
                                <div className="flex items-center gap-2 w-full">
                                    <Label text="Description (optional)" className="!w-60" color="neutral-400"/>

                                    <F4Textbox isGhost
                                        placeholder="Description"
                                        inputClassName="!text-base font-medium" name={`fieldsMap.${props.field.id}.description`}
                                        className="text-neutral-600 rounded-none placeholder-neutral-300"/>
                                </div>

                                <div className="flex items-center gap-3">
                                    {/* <Icon icon={faInputText} color="neutral-600" className="p-0.5"/>
                                    <Icon icon={faInfoCircle} color="neutral-600" className="p-0.5"/> */}

                                    <F4NSwitch name={`fieldsMap.${props.field.id}.showDescription`}
                                        options={NSwitchOptions}
                                        isDisabled={!showDescription}
                                        defaultValue={false}
                                        size="xs"
                                        className="!p-0"
                                        nullifyVars={['limits.amp_wlc']}
                                    />

                                    {/* not implemented yet, this should be added or new flag  */}
                                    <Icon icon={showDescription ? faEye : faEyeSlash} onClick={()=> setShowDescription(!showDescription)}
                                        color="neutral-600" className="p-0.5"/>
                                </div>
                            </div>

                            {/* ********** Third line ********** */}
                            <div className="flex items-center w-full">
                                <Label text="Show on condition" className="!w-60" color="neutral-400"/>
                                <F4Textbox isGhost
                                    inputClassName="!text-base font-medium !w-full" placeholder="Show on condition (for later in FIR-188)"
                                    className="text-neutral-600 rounded-none placeholder-neutral-300"/>
                            </div>
                        </>
                    }
                </F4FormDiv>
            </F4FormDiv>
        </F4FormDiv> );
}

export default SBOEditorField;

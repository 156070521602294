// TagCondition.js
import {Button, Icon, Label, Tag} from '@aktek/f4kit';
// import {} from '@fortawesome/free-regular-svg-icons';
import {faPlus, faTimes} from '@fortawesome/pro-regular-svg-icons';

import {GetTagTextColor} from '@/app/Admin/CompanySettings/pages/Tags/helpers/GetTagTextColor.fn';
import {strings} from '@/localization/i18n';

const TagConditionColumn = ({
    tagColors,
    tagCondition,
    id,
    handleTagConditionClick,
    handleConditionTagRemove,
    clearColumn,
}) => {
    return (
        <div className="flex items-center w-5/12 p-3 pr-6 group min-h-[3.75rem]">
            {!tagCondition && (
                <div className="w-10/12 flex flex-wrap">
                    <Button size="sm" isGhost label={strings('everything')} onClick={() => handleTagConditionClick(id, strings('everything'))} />
                    <Button size="sm" isGhost label={strings('everything_else')} onClick={() => handleTagConditionClick(id, strings('everything_else'))} />
                    <Button size="sm" isGhost label={strings('select_tags')} onClick={() => handleTagConditionClick(id, strings('select_tags'))} />
                </div>
            )}

            {tagCondition && typeof tagCondition === 'string' && (
                <Label text={tagCondition} size="sm" color="neutral-600" className="w-10/12"/>
            )}

            {tagCondition && Array.isArray(tagCondition) && (
                <div className="w-10/12 flex flex-wrap items-center gap-2">
                    {tagCondition.map?.((condition, i) => {
                        return (
                            <Tag
                                key={i}
                                size="sm"
                                label={condition}
                                backgroundColor={tagColors[condition] ?? '#9BD8B8'}
                                textColor={GetTagTextColor(tagColors[condition])}
                                className={` rounded-xl pr-[0.5rem]`}
                                rightElement={() => <Icon icon={faTimes} size="xs" onClick={() => handleConditionTagRemove(id, condition)}/>}
                            />
                        );
                    })}
                </div>
            )}

            {tagCondition && (
                <div className="w-2/12 flex gap-1 opacity-0 group-hover:opacity-100 transition-all duration-200 ease-in-out">
                    {typeof tagCondition !== 'string' && <Button isGhost
                        icon={faPlus}
                        size="xs"
                        variant="neutral"
                        className="!h-3 !w-3"
                        onClick={() => handleTagConditionClick(id, strings('select_tags'))}
                    />}
                    {typeof tagCondition === 'string' && <Button isGhost
                        size="xs"
                        variant="neutral"
                        className="!h-3 !w-3 invisible"
                    />}
                    <Button isGhost
                        icon={faTimes}
                        size="xs"
                        variant="neutral"
                        className="!h-3 !w-3"
                        onClick={() => clearColumn(id, true)} />
                </div>
            )}
        </div>
    );
};

export default TagConditionColumn;

import {Icon, Label, TabNav, TabsManager, TIconType} from '@aktek/f4kit';
import {SBOService} from '@aktek/morph-frontend-sdk';
import {faArrowUpRight, faDatabase, faPlusSquare} from '@fortawesome/pro-regular-svg-icons';
import {useEffect, useState} from 'react';

import AskForConfirmation from '@/asks/AskForConfirmation';
import SearchBox from '@/components/SearchBox/SearchBox';
import {strings} from '@/localization/i18n';

import {AddToRecentlyUsedSBOs, STORAGE_KEY} from '../helpers/RecentlyUsedSBOs.fn.';

type TRecentSBO ={
    isEdited: boolean
    key: string
    label: string
    icon: TIconType,
}

type TSBOEditorSidebarProps ={
    recentSBO: Array<TRecentSBO>
    selectedSboId: string
    isSBOEdited?: boolean
    setIsSBOEdited?: (value:boolean) => void
    refreshTable?: () => Promise<void>
}

export default function SBOEditorSidebar(props: TSBOEditorSidebarProps) {
    const [recentlyUsedSBOs, setRecentlyUsedSBOs] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const fetchSBOs = async () => {
        const response = await SBOService.getUsableSBOs(searchTerm, {id: 'name', order: '1'}, 0, 15);

        if (response.isSuccessful()) {
            const data = response.getData();
            const SBOs = data?.data?.map((item) => ({
                key: item._id,
                label: item.name,
                icon: faDatabase,
            }));

            setRecentlyUsedSBOs(SBOs);
            localStorage.setItem(STORAGE_KEY, JSON.stringify(SBOs));
        }
    };

    useEffect(()=>{
        if (searchTerm) {
            fetchSBOs();
        } else {
            if (props.recentSBO.length !== 15) {
                fetchSBOs();
            } else setRecentlyUsedSBOs(props.recentSBO);
        }
    }, [searchTerm]);

    const handleSBOClick = async (newSBO, mySBO, e) => {
        if (newSBO ) {
            TabsManager.openNewTab('dataStructureEditor', mySBO.label, {sboId: mySBO.key, refreshTable: ()=> props?.refreshTable()});
            AddToRecentlyUsedSBOs(mySBO);
            e.stopPropagation();
        } else if (e === null && !newSBO) {
            if (props.isSBOEdited) {
                const canClose = await AskForConfirmation('Are you sure you want to close this window?',
                    'All the data you entered is not saved yet so everything will be discarded.',
                    {
                        confirmButtonProps: {variant: 'error', label: strings('discard')},
                        cancelButtonProps: {label: strings('stay_here')},
                    },
                );

                if (canClose) {
                    TabsManager.focusOrOpenNewTab('dataStructureEditor', mySBO.label, {sboId: mySBO.key, refreshTable: ()=> props?.refreshTable()});
                    props.setIsSBOEdited(false);
                }
            } else {
                TabsManager.focusOrOpenNewTab('dataStructureEditor', mySBO.label, {sboId: mySBO.key, refreshTable: ()=> props?.refreshTable()});
            }

            AddToRecentlyUsedSBOs(mySBO);
        }
    };

    return (
        <div className="flex flex-col gap-4 p-4 pt-0">
            <div className="flex flex-col gap-4 sticky top-0 bg-white elevation-1 !shadow-none pb-2 pt-4">

                <div className="flex justify-between items-center px-3">
                    <Label text={strings('recent_data_structures')} color="neutral-600" className="text-sm" fontWeight="semibold" />
                    <Icon tooltipProps={{showDelay: 1000}} tooltip="Add New Data Structure" icon={faPlusSquare} className="h-5 w-5" color="neutral-600"
                        onClick={()=> TabsManager.openNewTab('dataStructureEditor', 'New Data Structure', {refreshTable: ()=> props?.refreshTable()})}/>
                </div>

                <SearchBox
                    onSearch={(e)=>setSearchTerm(e)}
                    placeholder={strings('search_data_structures')}
                    hasBottomBorder={false}
                    textBoxInputClassName="!bg-neutral-100 !rounded-lg !text-neutral-500 !font-normal"
                />
            </div>

            {recentlyUsedSBOs.length > 0
                ? <TabNav
                    size="sm"
                    orientation="vertical"
                    tabs={recentlyUsedSBOs}
                    value={props?.selectedSboId || recentlyUsedSBOs[0]}
                    onChange={(sboId)=> {
                        const mySBO = recentlyUsedSBOs.find((sbo) => sbo.key === sboId);
                        const sbo = {
                            key: sboId,
                            label: mySBO.label,
                            icon: faDatabase,
                        };
                        handleSBOClick(false, sbo, null);
                    }}
                    className="-mt-2"
                    buttonProps={{allowIconAutoPlacement: true}}
                    buttonClassName="hover:bg-primary-25 hover:text-primary-700 !min-h-10 !h-full text-wrap !text-left group"
                    getButtonProps={(tab) => {
                        return {
                            edgeIcons: {right:
                         {icon: faArrowUpRight, color: 'neutral-700', className: 'hidden group-hover:!flex  rounded-lg hover:primary-100 bg-primary-100 p-1 w-4 h-4',
                             onClick: (e) => {
                                 const sbo = {
                                     key: tab.key,
                                     label: tab.label,
                                     icon: faDatabase,
                                 };
                                 handleSBOClick(true, sbo, e);
                             }}},
                        };
                    }}
                />
                :strings('no_data_structures_found')}
        </div>
    );
}

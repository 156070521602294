import {Icon, TDropdownOption} from '@aktek/f4kit';

export default function Operator(option: TDropdownOption) {
    const icon = option.object?.icon;

    return <span className="flex items-center space-x-2">
        {/* Icon */}
        {icon && <span className="flex justify-center items-center w-[1rem] h-[1rem]">
            <Icon {...icon} />
        </span>}
        {/* Text */}
        <span>{option.label}</span>
    </span>;
}

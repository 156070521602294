
import {F4FormElementDecorator} from '@aktek/f4form';
import {Button, Image, Label, toast, TSize, usePropState} from '@aktek/f4kit';
import {faCloudArrowUp} from '@fortawesome/pro-regular-svg-icons';
import axios from 'axios';
import {useEffect, useRef, useState} from 'react';
import {v4 as uuid} from 'uuid';

import Env from '@/config/env';
import {isValidFileType, uploadFileToURL} from '@/helpers/File';
import {strings} from '@/localization/i18n';

type TImageUploaderProps = {
    isDisabled?: boolean;
    size?: TSize;
    title?:string;
    value: string;
    showUserIcon?: boolean;
    maxSizeMB: number;
    defaultImage?: TImage;
    allowedTypes: Array<string>;
    onChange: (e: string) => void;
    setIsLoading?: (e: boolean) => void;
    isViewMode?: boolean;
    className?: string;
    label?: string;
    description?: string;
}

type TImage ={
    src: string
    alt: string
}

const DEFAULT_ALLOWED_IMAGE_TYPES = ['image/jpeg', 'image/png', 'image/jpg'];

export function ImageUploader({
    onChange,
    value='',
    isDisabled,
    title,
    className,
    maxSizeMB=1,
    allowedTypes = DEFAULT_ALLOWED_IMAGE_TYPES,
    label,
    description,
}: Partial<TImageUploaderProps>) {
    const fileInputRef = useRef(null);

    const getImage = (id) => {
        return {
            src: Env.getFileURL(id),
            alt: title || '',
        };
    };

    const [_value, setValue] = usePropState<string>(value, '', onChange);
    const [image, setImage] = useState<TImage | null>(_value ? getImage(_value) : null);

    useEffect(()=>{
        setValue(value);
    }, [value]);

    useEffect(()=>{
        if (!_value) setImage(null);
        else setImage(getImage(_value));
    }, [_value]);

    const handleImageClick = () => {
        if (isDisabled) return;
        fileInputRef.current.click();
    };

    const handleOnChange = async (event) => {
        const file = event.target.files[0];

        if (!file) return;

        if (!allowedTypes.includes(file.type)) {
            toast.error({
                subject: strings('invalid_image_type'),
                details: strings('invalid_image_type_desc'),
            });

            return;
        }

        const isValidType = await isValidFileType(file, allowedTypes);

        if (!isValidType) {
            toast.error(strings('invalid_image_mime_type'));

            return;
        }

        const fileSizeMB = file.size / 1024 / 1024; // Convert size from bytes to MB

        if (fileSizeMB > maxSizeMB) {
            toast.error({
                subject: strings('invalid_file_size'),
                details: strings('the_image_size_should_not_exceed_mb').replace('##MB', `${maxSizeMB}MB`),
            });

            return;
        }

        const {url, fileName} = Env.getNewEmptyFileURL();

        try {
            await uploadFileToURL(file, url);
            setImage({src: fileName, alt: title || ''});

            onChange(fileName);
            toast.success(strings('image_uploaded_successfully'));
        } catch (err) {
            toast.error(err?.message);
        }
    };

    return <>
        <input
            type="file"
            accept="image/*"
            ref={fileInputRef}
            className="hidden"
            onChange={handleOnChange}
        />
        <div
            onClick={handleImageClick}
            className={`flex flex-col flex-wrap justify-center items-center gap-2 border rounded-xl border-neutral-200 w-full h-full py-6 ${className}`}
        >
            {!image
            && <>
                <Button isCircle isGhost
                    size="xl"
                    icon={faCloudArrowUp}
                    className="rounded-full bg-primary-25"
                    aria-label={'FileUpload'} />
                <Label
                    size="md"
                    text={label}
                    fontWeight="bold"
                    color="primary-700"
                    className="flex pointer-events-none" />
                <Label
                    size="xs"
                    text={description}
                    fontWeight="normal"
                    color="neutral-600"
                    className="flex text-center pointer-events-none" />
            </>}
            {image
            && <Image
                alt={image?.alt}
                src={image?.src}
                className="rounded-lg !w-full !h-full !object-cover"
            />}
        </div>
    </>;
}

export default F4FormElementDecorator<TImageUploaderProps>(ImageUploader);

import {Button, Icon, Label, Modal, TextBox, TModalProps, TModalRef, useModalRef} from '@aktek/f4kit';
import {faClone, faLink} from '@fortawesome/pro-regular-svg-icons';
import cn from 'classnames';
import {forwardRef} from 'react';

import {CopyToClipboard} from '@/helpers/CopyToClipboard.fn';
import {strings} from '@/localization/i18n';

export type TPasswordLinkModalProps = {
    modalProps?: Partial<TModalProps>;
    className?: string;
    URL?: string;
    onConfirm?: () => void;
    onCancel?: () => void;
    openOnMount?: boolean;
}

const GeneratePasswordLinkModal = (props: TPasswordLinkModalProps, ref:TModalRef)=> {
    const {className, URL, onConfirm, onCancel, modalProps={size: 'xs'}} = props;

    const modalRef = useModalRef(ref);

    const onConfirmCallback = () => {
        onConfirm?.();
        modalRef.current.close();
    };

    const onCancelCallback = () => {
        onCancel?.();
        modalRef.current.close();
    };

    return <Modal
        ref={modalRef}
        hasXIcon={false}
        headerClassName="hidden"
        className={cn('overflow-hidden !rounded-3xl p-6 max-w-[32rem]', className)}
        title={null}
        {...modalProps}
        onClose={onCancel}
    >
        <div className="flex flex-col">
            <Icon
                icon={faLink}
                size="2xl"
                color="primary-600"
                className="bg-primary-25 rounded-full p-3 mb-5 w-fit"
            />
            <Label
                size="lg"
                color="neutral-900"
                className="font-semibold"
                text={strings('reset_password_link')}
            />

            <Label
                size="sm"
                color="neutral-600"
                className="mt-4"
                text={strings('reset_password_link_description')}
            />

            <div className="flex items-center gap-2 mt-4">
                <TextBox
                    value={URL}
                    type="text"
                    size="sm"
                    isDisabled
                    inputClassName=" !text-neutral-900 "
                />
                <Icon
                    icon={faClone}
                    size="md"
                    onClick={() => CopyToClipboard(URL)}
                    className="mx-1"
                    color="neutral-600"
                />
            </div>

            <div className="flex mt-6 w-full gap-4">
                <Button
                    label={strings('cancel')}
                    variant="white"
                    className="w-1/2"
                    onClick={onCancelCallback}
                />
                <Button
                    label={strings('confirm')}
                    className="w-1/2"
                    onClick={onConfirmCallback}
                />
            </div>
        </div>
    </Modal>;
};

export default forwardRef(GeneratePasswordLinkModal);

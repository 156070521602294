export function filterOn(sourceObject, optionsArray, labelPropName) {
    const result = {};

    for (const key in sourceObject) {
        if (sourceObject.hasOwnProperty(key)) {
            if (sourceObject[key]?.type && optionsArray.indexOf(sourceObject[key].type.toLowerCase()) != -1) {
                result[key] = sourceObject[key];

                if (labelPropName) {
                    result[key].label = result[key][labelPropName];
                }
            }
        }
    }

    return result;
}

function filterOnFn(sourceObject, func) {
    const result = {};

    for (const key in sourceObject) {
        if (sourceObject.hasOwnProperty(key)) {
            if (func(sourceObject[key], key)) {
                result[key] = sourceObject[key];
            }
        }
    }

    return result;
}

export default function GetOptions(props) {
    let options = {};
    const additionalOptions = props.additionalOptions;

    if (additionalOptions && props.options!= null) {
        options = {...props.options, ...additionalOptions};// Object.assign(props.options, additionalOptions);
    } else {
        options = props.options;
    }

    if (options && props.fieldType && (props.type === 'Field' || props.type === 'ComprehensiveFields')) {
        if (Array.isArray(props.fieldType)) {
            return filterOn(options, props.fieldType);
        }

        switch (props.fieldType) {
            case ('BulkEditable'):
            {
                return filterOnFn(options, ((e, key)=> {
                    return key !== 'designation' && [
                        'string', 'float', 'closedlist', 'date', 'datetime', 'boolean', 'integer', 'ref', 'reflist', 'meta', 'tags',
                    ].includes(e.type.toLowerCase());
                }));}

            case ('Filterable'):
            {
                return filterOnFn(options, ((e, key)=> {
                    return key !== 'designation' && [
                        'iofield',
                        'tags', 'string', 'flowstatus', 'integer',
                        'float', 'closedlist', 'date', 'refannex', 'reflistannex',
                        'time', 'datetime', 'automatic',
                        'connectedref', 'boolean', 'reflist',
                        'ref', 'meta', 'application'].includes(e.type.toLowerCase());
                }));
            }

            case ('Audience'):
            {
                return filterOnFn(options, ((e) => {
                    return e.type === 'meta' || (e.type === 'automatic' && e.object.dynamicField === 'User');
                }));
            }

            case ('Formula'):
            {
                return filterOn(options, ['formula']);
            }

            case ('Literal'):
            {
                return filterOn(options, ['string', 'iofield', 'boolean', 'flowstatus', 'integer', 'float', 'formula', 'closedlist']);
            }

            case ('SupportUniqueValues'):
            {
                return filterOn(options, ['string', 'iofield', 'flowstatus', 'integer', 'float', 'closedlist', 'ref', 'reflist']);
            }

            case ('Temporal'):
            {
                return filterOn(options, ['date', 'datetime', 'time']);
            }

            case ('Date'):
            {
                return filterOn(options, ['date']);
            }

            case ('DateTime'):
            {
                return filterOn(options, ['datetime']);
            }

            case ('Time'):
            {
                return filterOn(options, ['time']);
            }

            case ('Image'):
            {
                return filterOn(options, ['mediaimage']);
            }

            case ('Color'):
            {
                return filterOn(options, ['color']);
            }

            case ('Integer'):
            {
                return filterOn(options, ['integer']);
            }

            case 'Numeric': {
                return filterOnFn(options, (e) => {
                    return ['integer', 'float'].includes(e.type) || (e.type === 'formula' && e.object?.resultType !== 'string');
                });
            }

            case 'String': {
                return filterOnFn(options, (e) => {
                    return ['string', 'flowstatus'].includes(e.type) || (e.type === 'formula' && e.object?.resultType !== 'number');
                });
            }

            case ('Ref'):
            {
                return filterOn(options, ['ref']);
            }

            case ('GPS'):
            {
                return filterOn(options, ['gps']);
            }

            default:
            {
                return options;
            }
        }
    }

    return options;
}

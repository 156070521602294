import {TModalRef} from '@aktek/f4kit';
import {UsersV4Service} from '@aktek/helios-frontend-sdk';
import {forwardRef} from 'react';

import F4CreatableModal from '@/components/F4CreatableModal/F4CreatableModal';
import {TF4CreatableModalProps} from '@/components/F4CreatableModal/types/F4CreatableModalProps.t';
import {useAdminPermissions} from '@/context/Permissions/usePermissions';
import {strings} from '@/localization/i18n';

import {CompanyRelatedInfo} from './Users.CompanyRelatedInfo';
import {UserInfo} from './Users.UserInfo';
import {UserRoles} from './Users.UserRoles';

const CreateUserModal = (props: TF4CreatableModalProps, ref: TModalRef) => {
    const {viewState, ...rest}= props;
    const {roles} = useAdminPermissions();

    return <F4CreatableModal
        ref={ref}
        viewState={viewState}
        title={strings('user')}
        formKey="users"
        getFunction={UsersV4Service.getUserById}
        storeFunction={UsersV4Service.storeUser}
        createSuccessMessage={strings('user_created_success')}
        errorMessage={strings('user_created_failed')}
        editSuccessMessage={strings('edit_success_message')}
        steps={[
            {key: 'userInfo', label: strings('user_info')},
            {key: 'companyRelatedInfo', label: strings('company_related_info')},
            {key: 'userRoles', label: strings('roles'), isVisible: roles?.can_view},
        ]}
        {...rest}
    >
        <UserInfo mode={viewState?.mode}/>
        <CompanyRelatedInfo/>
        {roles?.can_view && <UserRoles viewState={viewState} />}
    </F4CreatableModal>;
};

export default forwardRef(CreateUserModal);

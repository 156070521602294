
import {Tag} from '@aktek/f4kit';

const TagsCellView = ({value}) => {
    if (!value) return null;

    return <div className="flex gap-1">
        {value?.tags?.map((tagLabel, index) => <Tag label={tagLabel || ''}
            key={'tags' + tagLabel + '-' + index }
            size="sm"
            variant="primary"
            labelProps={{text: tagLabel, color: 'white'}}
        />)}
    </div>;
};

export default TagsCellView;

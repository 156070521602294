
import {FileUpload, Modal, TFileUploadProps, TModalRef} from '@aktek/f4kit';
import {forwardRef} from 'react';

export type TF4FileReaderProps = Omit<TFileUploadProps, 'label'> & {
    title?: string;
    onClose?: () => void;
}

function F4FileReader(props: TF4FileReaderProps, ref: TModalRef) {
    const {onChange, onClose, title, description, extensions, maxFileSize, maxTotalFilesSize} = props;

    return (
        <Modal
            ref={ref}
            title={title}
            className="w-fit h-fit"
            headerClassName="px-4 ml-4 pb-0 font-semibold text-neutral-900 text-lg rounded-xl"
            size="md"
            onClose={onClose}
        >
            <FileUpload
                uploadButtonSize={'xl'}
                label=""
                onChange={onChange}
                description={description}
                className="w-[26rem]"
                extensions={extensions}
                maxFileSize={maxFileSize}
                maxTotalFilesSize={maxTotalFilesSize}
            />
        </Modal>
    );
}

export default forwardRef(F4FileReader);


import {useModalRef} from '@aktek/f4kit';
import {useEffect} from 'react';

import {FOLDERS_AND_DATA} from '@/app/Explorer/constants/constantIds.c';
import {TF4CreatableModalProps} from '@/components/F4CreatableModal/types/F4CreatableModalProps.t';
import SBOMoveModal from '@/components/SBOMoveModal/SBO.Move.Modal';
import {GetFirstKey} from '@/helpers/GetFirstKey.fn';
import InjectAsyncElement from '@/utils/InjectAsyncElement';

const InjectedModal = ({props, remove, resolve}) => {
    const modalRef = useModalRef();

    const getParentId = (id) =>{
        if (GetFirstKey(id) == FOLDERS_AND_DATA) return null;

        return GetFirstKey(id);
    };

    useEffect(()=>{
        modalRef.current?.open();
    }, []);

    return <SBOMoveModal
        {...props}
        ref={modalRef}
        onClose={remove}
        getResult={(result) => {
            const finalResults = {
                ...result,
                parentId: getParentId(result?.parentId),
            };
            resolve(finalResults);
            remove();
        }}
    />;
};

export default function AskForFolder(
    props?: Partial<TF4CreatableModalProps | unknown>,
): Promise<unknown> {
    return InjectAsyncElement((resolve, reject, remove) => {
        return <InjectedModal resolve={resolve} props={props} remove={remove}/>;
    } );
}

import {RefFieldButton} from '@/components/RefFieldButton/RefFieldButton';

const ConnectedRefCellView = ({value, column = {}}) => {
    const {field, affecter} = column;

    if (!field) return;

    const sboId = field.sboId;
    const isMulti = affecter?.type == 'refList';

    if (!isMulti) {
        return <RefFieldButton
            sboId={sboId}
            fieldId={field.fieldId}
            value={value}
        />;
    }

    return <div className="flex gap-1">
        {value?.map?.((refValue, index) => <RefFieldButton
            key={refValue?._id + '-' + index}
            sboId={sboId}
            fieldId={field.fieldId}
            value={refValue?._id}
        />)}
    </div>;
};

export default ConnectedRefCellView;

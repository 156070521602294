
import {camelCase} from 'lodash';

import {ACLPositions, ACLStatus} from '../constants/ACLPositions';
import {ACLRestrictions} from '../constants/ACLRestrictions';
import {checkBoxStyle} from '../constants/PermissionConstants';
import {EmptyCell} from './EmptyCell';
import PermissionCheckbox from './PermissionCheckbox';

const getFieldPermissionValue = (isReport, permission, ACL) => {
    if (isReport) return permission[ACL];

    const isACLRestrictions = ACLRestrictions[permission.category][ACL];

    if (isACLRestrictions && permission[isACLRestrictions] !== ACLPositions.can_view) {
        return ACLStatus.disabled;
    }

    return permission[ACL];
};

const PermissionRowCell = ({
    ACL,
    index,
    isReport,
    permission,
    rowACLs,
    parentACLs,
    currentPath,
    disabled,
    selectedSection,
    onSuperAdminClick,
    onCheckBoxClick,
    isSuperAdmin}) => {
    const fieldPermissionValue = getFieldPermissionValue(isReport, permission, ACL);
    const isIncludedInRow = rowACLs.indexOf(ACL) == -1;
    const position = ACLPositions[ACL];
    const canView = permission.aclType && ((permission.aclType+'')[position] !== (ACLPositions.can_view+''));

    if ( (parentACLs && isIncludedInRow) || canView) {
        return <EmptyCell key={`buffer_${permission.id}_${ACL}_${index}`} />;
    }

    return (
        <td key={`td_${permission.id}_${ACL}_${index}`}
            className="ReactVirtualized__Table__rowColumn"
            role="gridcell"
            style={checkBoxStyle}
            align="center">
            <PermissionCheckbox
                isSuperAdmin={isSuperAdmin}
                permission={permission}
                path={currentPath}
                readOnly={disabled}
                aclType={ACL}
                idqa={`${selectedSection}_${ACL}_${camelCase(permission.name)}`}
                rowIndex={permission.originalIndex}
                selectedSection={permission.category}
                permissionId={permission.id}
                onSuperAdminClick={onSuperAdminClick}
                permissionValue={fieldPermissionValue}
                onPermissionClick={onCheckBoxClick} />
        </td>
    );
};

export default PermissionRowCell;

import {Icon} from '@aktek/f4kit';
import {faBuilding, faCodeMerge} from '@fortawesome/pro-regular-svg-icons';

import {TColumn} from '@/components/Table/Types/Table.t';

export const GetAgencyColumns = () :Array<TColumn> => {
    return [
        {key: 'companyLoginId', header: 'Company ID', type: 'string', isSortable: true},
        {key: 'companyName', header: 'Company Name', type: 'string', isSortable: true},
        {key: 'parentCompany', header: 'Parent company', type: 'string', isSortable: true},
        {key: 'jobPriority', header: 'Job Priority', type: 'string', isSortable: true},
        {key: 'companyType', header: 'Type', type: 'string', isSortable: true,
            renderCell: (row, cell) => {
                const isAgency = row?.getValue(cell?.column?.id) === 'AGENCY';

                return <div className="flex flex-row gap-2 items-center">
                    <Icon icon={isAgency ? faBuilding : faCodeMerge} fontWeight={600} />
                    {isAgency ? 'Parent' : 'Child'} Company
                </div>;
            },
        },
        {key: 'companySubtype', header: 'Company Sub Type', type: 'string', isSortable: true,
            renderCell: (row, cell) => {
                const value = row?.getValue(cell?.column?.id);
                if (value === 'CLIENT') return 'Client';
                if (value === 'DEMO') return 'Demo';

                return null;
            },
        },

    ];
};

import {Modal, TModalProps, toast, useModalRef, usePropState} from '@aktek/f4kit';
import cn from 'classnames';
import {forwardRef, useEffect, useImperativeHandle, useState} from 'react';

import SmartFilter from './Builder/SmartFilter.Builder';
import SmartFilterPreview from './Preview/SmartFilter.Preview';
import {TSmartFilter} from './types/SmartFilter.t';

export type TPasswordLinkModalProps = {
    sboId: string;
    modalProps?: Partial<TModalProps>;
    className?: string;
    URL?: string;
    onConfirm?: () => void;
    onCancel?: () => void;
    openOnMount?: boolean;
    onChange?: (filter: TSmartFilter) => void
    filter?: TSmartFilter
}

export type TSmartFilterModalRef = {
    open: () => void;
};

function SmartFilterModal(props: TPasswordLinkModalProps, ref) {
    const {className, openOnMount, onConfirm, onCancel, modalProps={size: 'lg'}} = props;
    const [smartFilter, setSmartFilter] = usePropState<TSmartFilter>(null, null, props.onChange);
    const modalRef = useModalRef();

    useImperativeHandle(ref, () => ({
        open: () => {
            modalRef.current?.open();
        },
    }));
    useEffect(()=> {
        if (openOnMount) {
            modalRef.current.open();
        }
    }, []);

    return <Modal
        ref={modalRef}
        hasXIcon={true}
        headerClassName="pb-2"
        className={cn('overflow-hidden !rounded-3xl p-1', className)}
        title={'Smart Filter'}
        {...modalProps}
        onClose={onCancel}
    >
        <div className="flex flex-col">
            <SmartFilterPreview sboId={props.sboId} smartFilter={smartFilter}/>

            <SmartFilter
                sboId={props.sboId}

                onChange={(e)=>{
                    setSmartFilter(e);
                }}

            />
        </div>
    </Modal>;
}

export default forwardRef<TSmartFilterModalRef, TPasswordLinkModalProps>(SmartFilterModal);

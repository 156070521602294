
import {TF4ThemeColors} from '@aktek/f3theme';
import {F4FormElementDecorator} from '@aktek/f4form';
import {Icon} from '@aktek/f4kit';
import {faFolder} from '@fortawesome/pro-light-svg-icons';
import {IconDefinition} from '@fortawesome/pro-regular-svg-icons';

import AskForModal from '@/asks/AskForModal';

import ImageOrColorSelectorModal, {TImageColorSelectorProps, TImageColorSelectorValue, TImageColorSelectorValueType} from '../Modals/ImageOrColorSelector.Modal';

type TExplorerNodeDesignProps = {
    defaultIcon?: IconDefinition,
    value?: TImageColorSelectorValue,
    availableTypes?: TImageColorSelectorValueType[];
    onChange?: (value: TImageColorSelectorValue) => void,
    color?: TF4ThemeColors,
    background?: TF4ThemeColors,
}

function ExplorerNodeDesignProps(props: TExplorerNodeDesignProps) {
    const {defaultIcon = faFolder, availableTypes=['image', 'color'], color = 'primary-600', background = 'primary-25', value, onChange} = props;

    return <Icon
        size="xl"
        icon={defaultIcon}
        color={color}
        onClick={() => AskForModal<TImageColorSelectorProps>(ImageOrColorSelectorModal, {
            availableTypes,
            value,
            onChange,
        })} />;
}

const F4ExplorerNodeDesign = F4FormElementDecorator<TExplorerNodeDesignProps>(ExplorerNodeDesignProps);

export default F4ExplorerNodeDesign;

import {RefFieldButton} from '@/components/RefFieldButton/RefFieldButton';

const RefAnnexCellView = ({value, column}) => {
    const {field} = column;

    if (!field) return null;

    return <RefFieldButton
        sboId={field.sboId}
        fieldId={field.fieldId}
        value={value}
    />;
};

export default RefAnnexCellView;

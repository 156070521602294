import {XFieldTypes} from '@aktek/types';
import {faDatabase, faEmptySet, faEquals, faGreaterThan, faGreaterThanEqual, faInputPipe, faLessThan, faLessThanEqual, faNotEqual, faSquare, faSquareMinus, faXmarkCircle} from '@fortawesome/pro-regular-svg-icons';

import {strings} from '@/localization/i18n';

export const operators = {
    '$eq': {label: strings('operator_equal'), object: {icon: {icon: faEquals, color: 'primary-600'}}},
    '$ne': {label: strings('operator_not_equal'), object: {icon: {icon: faNotEqual, color: 'primary-600'}}},
    '$lt': {label: strings('operator_less_than'), object: {icon: {icon: faLessThan, color: 'primary-600'}}},
    '$lte': {label: strings('operator_less_equal_than'), object: {icon: {icon: faLessThanEqual, color: 'primary-600'}}},
    '$gt': {label: strings('operator_greater_than'), object: {icon: {icon: faGreaterThan, color: 'primary-600'}}},
    '$gte': {label: strings('operator_greater_equal_than'), object: {icon: {icon: faGreaterThanEqual, color: 'primary-600'}}},
    '$sboIn': {label: strings('operator_sbo_included'), object: {icon: {icon: faDatabase, color: 'primary-600'}}},
    '$sboNin': {label: strings('operator_sbo_not_included'), object: {icon: {icon: faXmarkCircle, color: 'primary-600'}}},
    '$empty': {label: strings('operator_empty'), object: {icon: {icon: faSquare, color: 'primary-600'}}},
    '$notEmpty': {label: strings('operator_not_empty'), object: {icon: {icon: faSquareMinus, color: 'primary-600'}}},
    '$in': {label: strings('operator_includes'), object: {icon: {icon: faInputPipe, color: 'primary-600'}}},
    '$nin': {label: strings('operator_excludes'), object: {icon: {icon: faEmptySet, color: 'primary-600'}}},
    '$regexMatch': {label: strings('operator_contains'), object: {icon: {icon: faInputPipe, color: 'primary-600'}}},
    '$notRegexMatch': {label: strings('operator_not_contains'), object: {icon: {icon: faXmarkCircle, color: 'primary-600'}}},

};

const numberOperators = ['$eq', '$ne', '$lt', '$lte', '$gt', '$gte', '$sboIn', '$sboNin', '$empty', '$notEmpty'].reduce((prevValue, currentVal) => {
    prevValue[currentVal] = operators[currentVal];

    return prevValue;
}, {});

const arrayOperators = ['$elemMatch', '$notElemMatch'].reduce((prevValue, currentVal) => {
    prevValue[currentVal] = operators[currentVal];

    return prevValue;
}, {});

const booleanOperators = ['$eq', '$ne', '$empty', '$notEmpty'].reduce((prevValue, currentVal) => {
    prevValue[currentVal] = operators[currentVal];

    return prevValue;
}, {});

const stringOperators = ['$eq', '$ne', '$regexMatch', '$notRegexMatch', '$sboIn', '$sboNin', '$empty', '$notEmpty'].reduce((prevValue, currentVal) => {
    prevValue[currentVal] = operators[currentVal];

    return prevValue;
}, {});

const multiOperators = ['$in', '$nin', '$empty', '$notEmpty'].reduce((prevValue, currentVal) => {
    prevValue[currentVal] = operators[currentVal];

    return prevValue;
}, {});

const dateOperators = ['$eq', '$ne', '$lt', '$lte', '$gt', '$gte', '$empty', '$notEmpty'].reduce((prevValue, currentVal) => {
    prevValue[currentVal] = operators[currentVal];

    return prevValue;
}, {});

const referenceOperator = ['$eq', '$ne', '$empty', '$notEmpty'].reduce((prevValue, currentVal) => {
    prevValue[currentVal] = operators[currentVal];

    return prevValue;
}, {});

const sboOperators = ['$in', '$nin'].reduce((prevValue, currentVal) => {
    prevValue[currentVal] = operators[currentVal];

    return prevValue;
}, {});

const getOperatorsOptions = (fieldType: XFieldTypes) => {
    switch (fieldType) {
        case ('string'):
            return stringOperators;
        case ('integer'):
        case (XFieldTypes.Float):
        case ('iofield'):
            return numberOperators;
        case (XFieldTypes.ClosedList):
            return referenceOperator;
        case (XFieldTypes.Boolean):
            return booleanOperators;
        case ('flowstatus'):
            return multiOperators;
        case (XFieldTypes.Date):
        case (XFieldTypes.Time):
        case (XFieldTypes.Datetime):
            return dateOperators;

        case ('tags'):
        case ('refannex'):
        case ('ref'):
            return referenceOperator;

        case ('reflistannex'):
        case ('reflist'):
            return multiOperators;

        case ('connectedref'):
            return referenceOperator;

       /* case ('meta'):
        {
            if (field.isMulti) {
                return multiOperators;
            }

            return referenceOperator;
        }

        case ('automatic'):
        {
            if (field.dynamicField === 'User') {
                return referenceOperator;
            } else {
                return dateOperators;
            }
        }*/
    }
};

export default getOperatorsOptions;


import {Row} from '@tanstack/react-table';

import AskForModal from '@/asks/AskForModal';
import {TTableRowAction} from '@/components/Table/Types/TableActionMenuEntry.t';
import {TCompany} from '@/types/Company';

import AgencyCreateModal from '../components/Agency.Create.Modal';
import UsageMetricsModal from '../components/Agency.UsageMetrics.Modal';

export const OnRowAgencyActions = async (row: Row<TCompany>, action: TTableRowAction) => {
    switch (action.key) {
        case 'view': {
            return AskForModal(AgencyCreateModal, {
                viewState: {mode: 'view', _id: row.original._id},
            });
        }

        case 'usage_metrics': {
            return AskForModal(UsageMetricsModal, {
                companyId: row.original._id,
                openOnMount: true,
                header: row.original.companyName,
            });
        }

        default:
            return;
    }
};


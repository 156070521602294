
import {Cell, Row} from '@tanstack/react-table';

import TagsCellView from '@/components/Fields/Tags/Tags.Cell.View';

export const CellTags = (columnDef) => {
    return {
        cell: ({cell, row}: {row: Row<unknown>, cell: Cell<unknown, unknown>}) => {
            const value = row.getValue(cell.column.id);

            return <TagsCellView value={value} />;
        },
    };
};

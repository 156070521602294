import {FitHeight, LoadingIndicator, TabsFlexLayout, TabsManager, toast} from '@aktek/f4kit';
import {Authentication_TokensService} from '@aktek/helios-frontend-sdk';
import {useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';

import {useGlobalFilesAndFolders} from '@/context/UserContext/Hooks/useGlobalFilesAndFolders';
import {useGlobalTheme} from '@/context/UserContext/Hooks/useGlobalTheme';
import {useUserAuth} from '@/context/UserContext/Hooks/useUserAuth';
import {GetItemById} from '@/helpers/GetItemById.fn';
import {strings} from '@/localization/i18n';
import {useNavigateToRoot} from '@/routing/navigations';

import AppHeader from './AppHeader';
import useCompanySwitch from './hooks/useCompanySwitch';
import {TabFactory} from './Tabs';

export default function Home() {
    const {userCompanies, currentCompany} = useUserAuth();
    const {fetchTheme} = useGlobalTheme();
    const {setLinkToFolder} = useGlobalFilesAndFolders();

    // Navigation
    const navigateToRoot = useNavigateToRoot();

    // params
    const {shortcut, folder, companyId} = useParams();

    // company switch hook
    const {loading, handleSwitchCompany} = useCompanySwitch(folder);

    useEffect(() => {
        if (companyId && companyId !== currentCompany._id) {
            const foundCompany = GetItemById(userCompanies, companyId);

            if (!foundCompany) {
                navigateToRoot(true);
                toast.error(strings('missing_required_permissions'));

                return;
            }

            handleSwitchCompany(companyId, folder);

            return;
        }

        if (shortcut) TabsManager.focusOrOpenTabFromShortcut(shortcut);

        if (folder) setLinkToFolder(folder);

        if (folder || shortcut) navigateToRoot(true);
    }, [shortcut, folder]);

    const {tabsSchema} = ls;
    useEffect(() => {
        !tabsSchema && TabsManager.focusOrOpenNewTab('home', 'home', {}, {isPinned: true});
        fetchTheme();
    }, []);

    if (loading) {
        return <div className=" absolute flex items-center w-full h-full p-4 justify-center elevation-5 !shadow-none"
            style={{backgroundColor: 'rgba(0, 0, 0, 0.15)'}}>
            <LoadingIndicator size="xl" />
        </div>;
    }

    return (
        <FitHeight className="w-full h-full">
            <div className="px-2s">
                <AppHeader />
            </div>
            <div className="relative overflow-hidden h-full">
                <TabsFlexLayout
                    tabFactory={TabFactory}
                    // tabsManager={TabsManager}
                    onModelChange={(modelJSON, cleanModelJSON) => ls.tabsSchema = modelJSON.toJson()}
                    defaultSchema={tabsSchema}
                />
            </div>
        </FitHeight>
    );
}


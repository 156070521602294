import {faDashboard, faNetworkWired} from '@fortawesome/pro-regular-svg-icons';

import NavigationCategory from '@/components/Navigation/Navigation.Viewer.Category';
import NavigationPageContainer from '@/components/Navigation/Navigation.Viewer.PageContainer';
import NavigationSection from '@/components/Navigation/Navigation.Viewer.Section';
import {strings} from '@/localization/i18n';

export default function AKTEKiQ({}): React.ReactElement {
    return <NavigationPageContainer>

        <NavigationSection label={strings('iq')}>
            <NavigationCategory
                tabKey="dashboards"
                icon={faDashboard}
                name="iQ Areas"
            />
            <NavigationCategory
                tabKey="networks"
                icon={faNetworkWired}
                name="iQ News"
            />
            <NavigationCategory
                tabKey="dashboards"
                icon={faDashboard}
                name="iQ Social"
            />
            <NavigationCategory
                tabKey="networks"
                icon={faNetworkWired}
                name="iQ Hazards"
            />
            <NavigationCategory
                tabKey="dashboards"
                icon={faDashboard}
                name="iQ Web"
            />
        </NavigationSection>

        <NavigationSection label={strings('analysis')}>
            <NavigationCategory
                tabKey="networks"
                icon={faNetworkWired}
                name="iQ Analysis"
            />
        </NavigationSection>
    </NavigationPageContainer>;
}

;


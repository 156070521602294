import {F4FormContainer, F4FormDiv, F4FormElementDecorator, F4FormStep, F4Nestor, useF4FormContext} from '@aktek/f4form';
import {Label} from '@aktek/f4kit';
import {TF4DecoratedElementProps} from 'node_modules/@aktek/f4form/dist/Core/types/F4DecoratedElementProps.t';
import {useCallback, useEffect, useState} from 'react';

import {TMode} from '@/components/F4CreatableModal/types/F4CreatableModalProps.t';
import F4Dropdown from '@/components/F4Elements/F4Dropdown';
import LicenseFeature from '@/components/LicenseComponents/LicenseFeature';
import {LICENSE_FEATURES, LIMITS_FOR_METRICS} from '@/constants/LicenseFeatures.c';
import {strings} from '@/localization/i18n';

import OnLicenseSelect from '../helpers/Agency.OnLicenseSelect.fn';
import useAgency from '../hooks/useAgency';

type TLicenseInfo = TF4DecoratedElementProps & {
    mode: TMode;
}

export const LicenseInfo = ({mode}: TLicenseInfo) => {
    const {getLicenses, dropdownOptions} = useAgency();

    const isViewMode = mode == 'view';
    const context = useF4FormContext();

    const [hasCustomizations, setHasCustomizations] = useState(false);
    const companyInfo = context.data;
    useEffect(() => {
        // NOTE: fix this after we implement the dropdown (that works with API calls)
        getLicenses(null, {id: 'plan', order: 1}, 0, 100);
    }, []);

    useEffect(() => {
        if (!!companyInfo?.license?.licensePlan) {
            return setHasCustomizations(true);
        }

        setHasCustomizations(false);
    }, [companyInfo, hasCustomizations]);

    const onLicenseSelect = useCallback(
        (license) =>
            OnLicenseSelect(
                license,
                setHasCustomizations,
                context,
                companyInfo,
            ),
        [setHasCustomizations, context, companyInfo],
    );

    return (

        <F4FormStep step="license" autoNest>

            <div className="overflow-y-auto flex flex-col gap-4 pb-4">

                <F4FormContainer rowSpan={12} className="w-full flex flex-col">

                    <F4Dropdown
                        options={dropdownOptions || {}}
                        placeholder={isViewMode ? strings('not_specified') : undefined}
                        isDisabled={isViewMode}
                        hideArrow={isViewMode}
                        isClearable={!isViewMode}
                        show={dropdownOptions}
                        label={strings('license_plan')}
                        onChange={onLicenseSelect}
                        name="licensePlan" />

                    <F4Nestor name="customizations">
                        <F4FormDiv
                            className="flex flex-col justify-between items-center"
                            style={{display: hasCustomizations ? 'flex' : 'none'}}
                        >
                            <F4Nestor name="features">
                                <F4FormDiv className="flex flex-col justify-between items-center pr-6 pb-2">

                                    <F4FormDiv className="flex justify-between items-center py-4">
                                        <Label
                                            className="w-full"
                                            size="xl"
                                            text={strings('features')}
                                            color="neutral-700"
                                            fontWeight="medium"
                                        />
                                        {!isViewMode && (
                                            <Label
                                                className="pr-2"
                                                size="sm"
                                                text={strings('customize')}
                                                color="neutral-600"
                                                fontWeight="medium"
                                            />
                                        )}
                                    </F4FormDiv>

                                    {LICENSE_FEATURES.map((item, index) => (
                                        <LicenseFeature key={index} item={item} mode={mode} />
                                    ))}
                                </F4FormDiv>
                            </F4Nestor>

                            <div className="w-full h-px bg-neutral-200 mx-auto my-4" />

                            <F4Nestor name="limits">
                                <F4FormDiv className="flex flex-col justify-between items-center pt-2 pr-6">
                                    <F4FormDiv className="flex justify-between items-center py-4">
                                        <Label
                                            size="xl"
                                            text={strings('iq_areas_limits')}
                                            color="neutral-700"
                                            fontWeight="medium"
                                        />
                                        {!isViewMode && (
                                            <Label
                                                className="pr-2"
                                                size="sm"
                                                text={strings('customize')}
                                                color="neutral-600"
                                                fontWeight="medium"
                                            />
                                        )}
                                    </F4FormDiv>

                                    {LIMITS_FOR_METRICS.map((item, index) => (
                                        <LicenseFeature key={index} item={item} mode={mode} />
                                    ))}
                                </F4FormDiv>
                            </F4Nestor>
                        </F4FormDiv>
                    </F4Nestor>

                </F4FormContainer>

            </div>
        </F4FormStep>
    );
};

const F4LicenseInfo = F4FormElementDecorator<TLicenseInfo>(LicenseInfo);
export default F4LicenseInfo;

import {CompanySettingsService} from '@aktek/helios-frontend-sdk';
import {useCallback, useState} from 'react';

import XApplyTheme from '@/helpers/ApplyTheme.fn';
import {TTheme} from '@/types/TTheme';

export type TUseTheme = {
    theme: TTheme
    setTheme: (theme: TTheme) => void;
    fetchTheme: () => Promise<void>;
}

export const useTheme : () => TUseTheme = () => {
    const [theme, _setTheme] = useState<TTheme>(ls?.theme as TTheme);

    const setTheme = (updatedTheme: TTheme) => {
        ls.theme = updatedTheme as string;
        _setTheme(updatedTheme);
    };

    const fetchTheme = useCallback( async () => {
        const updatedDesign = await CompanySettingsService.getDesignSettings();

        const fetchedData = updatedDesign.getData()?.appearance;
        setTheme(fetchedData);

        const theme = {
            colors: fetchedData?.colors,
            font: fetchedData?.font,
        };
        XApplyTheme(theme);
    }, []);

    return {theme, setTheme, fetchTheme};
};

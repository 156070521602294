import {AccountManagementService, CompaniesService, CompanyLicenseService} from '@aktek/helios-frontend-sdk';

export const GetCompany =async (companyId, canAccessLicense, manageAccountManagers, onFetchLicenseData) => {
    const handleError = () => ({

        isSuccessful: () => false,
        getData: () => null,
    });

    const fetchCompanyData = async () => {
        const company = await CompaniesService.getCompanyById(companyId);

        return company.isSuccessful() ? company : null;
    };

    const fetchAccountManagers = async () => {
        if (!manageAccountManagers?.can_access) return null;
        const accountManagers = await AccountManagementService.getAccountManagers(companyId);

        return accountManagers.isSuccessful() ? accountManagers.getData() : null;
    };

    const fetchLicenseData = async () => {
        if (!canAccessLicense) return null;
        const license = await CompanyLicenseService.getLicenseProgram(companyId);
        if (!license.isSuccessful()) return null;

        const licenseData = license.getData();
        onFetchLicenseData(licenseData);

        return licenseData;
    };

    try {
        const company = await fetchCompanyData();
        if (!company) return handleError();

        const accountManagers = await fetchAccountManagers();
        if (manageAccountManagers?.can_access && !accountManagers) return handleError();

        const licenseData = await fetchLicenseData();
        if (canAccessLicense && !licenseData) return handleError();

        const companyData = company.getData();
        const availableLanguages = companyData?.languages || [];
        const defaultLanguage = companyData?.defaultLanguage;
        const companyLicense = companyData?.license || {};
        const companyCustomizations = companyLicense?.customizations || {};

        const _languages = !availableLanguages.includes(defaultLanguage) && defaultLanguage
            ? [...availableLanguages, defaultLanguage]
            : availableLanguages;

        const finalInitialValue = {
            ...companyData as object,
            accountManagers: accountManagers || [],
            languages: _languages,
            license: {
                ...companyLicense,
                customizations: {
                    ...companyCustomizations,
                    enabled: true,
                },
            },
        };

        return {
            isSuccessful: () => true,
            getData: () => finalInitialValue,
        };
    } catch (error) {
        return handleError();
    }
};

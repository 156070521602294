import {F4FormStep} from '@aktek/f4form';
import {Label} from '@aktek/f4kit';

import {strings} from '@/localization/i18n';

const NoLicenseNotice = ({isViewMode}:{isViewMode:boolean}) => {
    return <F4FormStep step="activation" autoNest>
        {!isViewMode && (
            <div className="flex flex-col gap-2 pb-3">
                <Label
                    text={strings('please_choose_license_to_proceed_with_activation')}
                    color="neutral-600"
                    size="md"
                />
                <Label
                    text={strings('or_click_save_to_create_company_without_activation')}
                    color="neutral-600"
                    size="md"
                />
            </div>
        )}

        {isViewMode && (
            <Label
                text={strings('this_company_is_not_activated_yet')}
                color="neutral-600"
                size="md"
            />
        )}
    </F4FormStep>;
};

export default NoLicenseNotice;

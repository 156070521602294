import {F4ThemeColors} from '@aktek/f3theme';
import {F4Nestor, useF4FormContext} from '@aktek/f4form';
import {Icon} from '@aktek/f4kit';
import {faTrashCan} from '@fortawesome/pro-light-svg-icons';
import {useMemo} from 'react';

import F4Dropdown from '@/components/F4Elements/F4Dropdown';
import F4Textbox from '@/components/F4Elements/F4TextBox';
import IOHub from '@/iohub/IOHub';

import GetOperators from '../consts/GetOperators.fn';
import Operator from './SmartFilter.Builder.Rule.Operator.fn';
import type {TSmartFilterRuleProps} from './types/SmartFilter.Builder.t';

const ruleColors = F4ThemeColors.filter((e) => e.startsWith('neutral')).map((e) => `bg-${e}`);

function SmartFilterRule(props: TSmartFilterRuleProps) {
    const {depth, rule, onDelete, parentId, sboId, fields} = props;
    const {id} = rule || {};

    const context = useF4FormContext();
    const fieldId = context.getValue(`${rule.id}.fieldId`) as string;
    const field = fields?.[fieldId];

    const operators = field ? GetOperators(field?.object?.type) : {};

    // console.log('fields', fields);
    // console.info('⤁ SmartFilter.Rule.tsx:21:', {operators});
    const bgColor = useMemo(() => {
        if (depth == 0) return '';

        return ruleColors[depth];
    }, [depth]);

    const renderOptionFn = useMemo(() => IOHub.GetHubRule('field').renderOption({} as never), []);

    return <F4Nestor key={rule.id} name={rule.id}>
        <div className={`${bgColor} mt rounded-2xl w-full flex flex-row gap-2 p-4 items-center`}>

            <F4Dropdown
                name="fieldId"
                options={fields}
                renderOption={renderOptionFn}
                renderValue={renderOptionFn}
            />

            <F4Dropdown name="operator"
                hideLabel
                options={operators}
                renderOption={Operator}
                renderValue={Operator} />

            <F4Textbox name="value" />
            <Icon icon={faTrashCan} color="neutral-400" onClick={() => onDelete(parentId, id)} />
        </div>
    </F4Nestor>;
}

export default SmartFilterRule;

import {Breadcrumb, FitHeight, TabsManager} from '@aktek/f4kit';
import {RepositoryService} from '@aktek/plutus-frontend-sdk';

import F4ViewableComponent from '@/components/F4ViewableComponent/F4ViewableComponent';
import {strings} from '@/localization/i18n';

import {GEO_COLUMNS} from './GeoRepository.columns';

export default function GeoRepository() {
    const getAllData = async (search, sort, offset, pageSize) => {
        const category = 'Geo';

        return await RepositoryService.retrieveFiles(category, pageSize, offset, sort, search);
    };

    return (
        <div className="bg-neutral-50 h-full">
            <FitHeight className="h-full w-full p-4">
                <Breadcrumb
                    className="!bg-neutral-50 !p-0 !pb-6"
                    size="sm"
                    path={[
                        {
                            onClick: () => TabsManager.focusOrOpenNewTab('home', 'home', {}),
                            title: strings('home'),
                        },
                        {
                            onClick: () => {},
                            title: strings('geo_repository'),
                        },
                    ]}
                />

                <F4ViewableComponent
                    // modal={CompanyStoreModal}
                    tableColumns={GEO_COLUMNS}
                    onRowAction={() => {}}
                    getAllData={getAllData}
                />
            </FitHeight>
        </div>
    );
}


import {LANGUAGES} from '@/constants/languages';

const GetSortedAvailableLanguages = () => {
    return Object.fromEntries(
        Object.entries(LANGUAGES).sort(([, a], [, b]) =>
            a?.label.localeCompare(b?.label),
        ),
    );
};

export default GetSortedAvailableLanguages;

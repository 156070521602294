import {TModalRef} from '@aktek/f4kit';
import {forwardRef} from 'react';

import F4CreatableModal from '@/components/F4CreatableModal/F4CreatableModal';
import {TF4CreatableModalProps} from '@/components/F4CreatableModal/types/F4CreatableModalProps.t';
import {useAdminPermissions} from '@/context/Permissions/usePermissions';
import {strings} from '@/localization/i18n';

import useRoles from '../hooks/useRoles';
import RoleInfo from './RoleInfo';
import RolesPermissions from './RolesPermissions';
import UsersAssigned from './UsersAssigned';

const CreateRoleAndPermissionModal = (props: TF4CreatableModalProps, ref: TModalRef) => {
    const {viewState, ...rest}= props;
    const {users} = useAdminPermissions();
    const {getRoles, storeRole, initialIsSuperAdmin} = useRoles();

    return <F4CreatableModal
        ref={ref}
        viewState={viewState}
        title={strings('role')}
        className="w-5/6"
        formKey="roles"
        createSuccessMessage={strings('create_role')}
        editSuccessMessage={strings('edit_role')}
        getFunction={getRoles}
        storeFunction={storeRole}
        steps={[
            {key: 'roleInfo', label: 'Role Info'},
            {key: 'permissions', label: 'Permissions'},
            {key: 'assignedUsers', label: 'Users Assigned', isVisible: users?.can_view},
        ]}
        {...rest}
    >
        <RoleInfo />
        <RolesPermissions viewState={viewState} initialIsSuperAdmin={initialIsSuperAdmin} />
        <UsersAssigned mode={viewState.mode} />
    </F4CreatableModal>;
};

export default forwardRef(CreateRoleAndPermissionModal);

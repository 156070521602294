
import {Drawer} from '@aktek/f4kit';

import {useGlobalFilesAndFolders} from '@/context/UserContext/Hooks/useGlobalFilesAndFolders';
import {openCloseDrawer} from '@/utils/openCloseDrawer';

import ExplorerSideBar from './components/ExplorerSideBar';
import FoldersRightPanel from './components/FoldersRightPanel';
import RecentlyUsed from './components/RecentlyUsed';
import useExplorerUI from './hooks/useExplorerUI';
import {getClasses} from './styles/Explorer.styles';

export default function Explorer({}): React.ReactElement {
    const classes = getClasses();
    const {state, linkToFolder} = useGlobalFilesAndFolders();
    const explorerUIState = useExplorerUI(state, linkToFolder);

    return <div className={classes.full}>
        <Drawer
            defaultOpen={ls.drawer?.explorer ?? true}
            onOpenChange={(e) => openCloseDrawer('explorer', !!e)}
            sideWidth={96}
            tooltipDelay={1000}
            buttonSize="sm"
        >
            <ExplorerSideBar explorerUIState={explorerUIState} />
            <>
                {explorerUIState.rightPanelView == 'recent' && <RecentlyUsed explorerUIState={explorerUIState} />}
                {explorerUIState.rightPanelView == 'folders' && <FoldersRightPanel explorerUIState={explorerUIState} />}
            </>
        </Drawer>
    </div>;
}

import {TDropdownOption} from '@aktek/f4kit';
import {faPieChart} from '@fortawesome/pro-regular-svg-icons';

import {TColumn} from '@/components/Table/Types/Table.t';
import {strings} from '@/localization/i18n';

export const COMPANY_MODAL_TABS = [
    {key: 'companyDetails', label: 'Company Details'},
    {key: 'license', label: 'License'},
];

export const TYPES = {
    'AGENCY': 'Parent Company',
    'COMPANY': 'Child Company',
};

export const COMPANY_SUBTYPE = {
    'CLIENT': 'Client',
    'DEMO': 'Demo',
};

export const JOB_PRIORITY:Record<number, TDropdownOption>= {
    1: {label: strings('low_priority')},
    2: {label: strings('medium_priority')},
    3: {label: strings('high_priority')},
    4: {label: strings('very_high_priority')},
};

export const CUSTOM_AGENCY_ACTIONS = [{
    key: 'history',
    icon: faPieChart,
    tooltip: strings('global_usage_metrics'),
    label: 'History',
}];

export const USAGE_METRICS_COLUMNS : TColumn[] = [
    {key: 'name', header: 'Name', type: 'string', isSortable: true},
    {key: 'value', header: 'Value', type: 'string', isSortable: true},
];

import {XFieldTypes} from '@aktek/types';
import {pickBy} from 'lodash';

const RequiredColumns = [
    // XFieldTypes.String,
    // XFieldTypes.Integer,
    // XFieldTypes.Float,
    // XFieldTypes.Boolean,

    /** Ref */
    XFieldTypes.Ref,
    // XFieldTypes.RefAnnex,
    // XFieldTypes.RefList,
    // XFieldTypes.RefListAnnex,
    // XFieldTypes.ConnectedRef,

    // XFieldTypes.Color,
    // XFieldTypes.ClosedList,
    // XFieldTypes.Signature,
    // XFieldTypes.Gps,

    /** Media */
    // XFieldTypes.MediaAudio,
    // XFieldTypes.MediaImage,
    // XFieldTypes.MediaVideo,
    // XFieldTypes.MediaAlbum,
    // XFieldTypes.MediaGeneric,

    /**  Date fields */
    // XFieldTypes.Date,
    // XFieldTypes.Time,
    // XFieldTypes.Datetime,

    /** subform */
    // XFieldTypes.Multisubform,
    // XFieldTypes.Subform,

    /** others... */
    // XFieldTypes.Meta,
    // XFieldTypes.Automatic,
    // XFieldTypes.Stopwatch,
    // XFieldTypes.Formula,
];

export default (fieldsMap) => pickBy(fieldsMap, (field) => !!RequiredColumns.includes(field?.type));

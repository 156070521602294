import {TModalRef} from '@aktek/f4kit';
import {LicensePlanService} from '@aktek/helios-frontend-sdk';
import {forwardRef} from 'react';

import F4CreatableModal from '@/components/F4CreatableModal/F4CreatableModal';
import {TF4CreatableModalProps} from '@/components/F4CreatableModal/types/F4CreatableModalProps.t';
import {strings} from '@/localization/i18n';

import LicenseInfo from './Licensing.LicenseInfo';

const LicensingCreateModal = (props: TF4CreatableModalProps, ref: TModalRef) => {
    const {viewState, ...rest}= props;

    return (
        <F4CreatableModal
            title={strings('license')}
            ref={ref}
            viewState={viewState}
            formKey="license"
            className="md:w-3/5 lg:w-2/5 "
            hasSidebar={false}
            getFunction={LicensePlanService.getLicensePlan}
            editSuccessMessage={strings('edit_license_successfully')}
            createSuccessMessage={strings('create_license_successfully')}
            storeFunction={LicensePlanService.storeLicensePlan}
            {...rest}
        >
            <LicenseInfo />
        </F4CreatableModal>
    );
};

export default forwardRef(LicensingCreateModal);

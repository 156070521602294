import {Button, Label, TextBox} from '@aktek/f4kit';

import {strings} from '@/localization/i18n';
import {useNavigateToLogin} from '@/routing/navigations';

import AuthContainer from '../../components/AuthContainer';
import useForgotPassword from './hooks/useForgotPassword';
import {getClasses} from './styles/ForgetPassword.styles';

export default function ForgotPassword() {
    const navigateToLogin = useNavigateToLogin();
    const {
        handleKeyDown,
        validationResult,
        handleEmailChange,
        forgetPasswordEmailState,
        setForgetPasswordEmailState,
        handleForgetPasswordRequest,
    } = useForgotPassword();

    const {isValid, errors} = validationResult;
    const {emailTouched} = forgetPasswordEmailState;

    const hasEmailError = errors?.email && emailTouched;

    const onKeyDown = (event)=>handleKeyDown(event, navigateToLogin);

    const onBlur = ()=>setForgetPasswordEmailState({...forgetPasswordEmailState, emailTouched: true});

    const onForgetPassword = ()=>handleForgetPasswordRequest(navigateToLogin);
    const classes = getClasses();

    return (
        <AuthContainer>
            <Label
                className={classes.container}
                text={strings('forget_password_text')}
                size="md"
                fontWeight="bold" />

            <TextBox
                label={strings('email')}
                placeholder={strings('email')}
                size="sm"
                type="email"
                className="mb-5"
                onChange={handleEmailChange}
                onKeyDown={onKeyDown}
                onBlur={onBlur}
                errorText={hasEmailError && errors?.email}
                variant={hasEmailError ? 'error': 'neutral'}

            />

            <div className="flex justify-end items-center w-full pb-6">
                <Button
                    label={strings('back_to_login')}
                    size="sm"
                    className="!px-0"
                    isGhost isOutlined
                    hasHoverBackground={false}
                    onClick={navigateToLogin}/>
            </div>

            <Button
                label={strings('forgot_password')}
                className="w-full"
                variant="primary"
                onClick={onForgetPassword}
                isDisabled={!isValid} />
        </AuthContainer>
    );
}

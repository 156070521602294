import {TF4FormRefFn} from '@aktek/f4form';
import {Button, ContextMenu} from '@aktek/f4kit';
import {faCopy, faCorner, faTrash} from '@fortawesome/pro-regular-svg-icons';
import {ReactNode} from 'react';

import {TField, TFieldTypes, TSection} from '../types/SBOEditor.t';

type TSectionsFieldsPopoverActionsProps ={
    type: 'section' | 'field' | 'tag';
    deleteField?: (fieldId:string)=> void
    addField?: (fieldType: TFieldTypes, sectionId: string, duplicatedField: TField) => void
    deleteSection?: (sectionId:string )=> void
    addSection?: (duplicatedSection?:TSection, fieldId?: string)=> void
    field?: TField
    section?: TSection
    sboFormRef?: React.MutableRefObject<TF4FormRefFn>
}

export default function SectionsFieldsPopoverActions(props: TSectionsFieldsPopoverActionsProps) {
    let res: ReactNode = null;

    const removePopoverContainer = ()=>{
        const popupcontainer = document.getElementById('popupcontainer');
        popupcontainer && popupcontainer.remove();
    };

    if (props.type ==='section') {
        res = <ContextMenu className="w-full  py-4">
            <Button isGhost edgeIcons={{left: {icon: faCopy}}} justifyContent="start"
                label={'Duplicate'} className="w-full min-w-52" onClick={()=>{
                    props.addSection(props.section);
                    removePopoverContainer();
                }}/>
            <Button isGhost edgeIcons={{left: {icon: faTrash}}} textColor="error-600" label={'Delete'}
                className="w-full min-w-52 hover:!bg-error-50 hover:!text-error-600" justifyContent="start"
                onClick={()=> {
                    props.deleteSection(props.section.id);
                    removePopoverContainer();
                }}/>
        </ContextMenu>;
    } else {
        res = <ContextMenu className="w-full  py-4">
            <Button isGhost edgeIcons={{left: {icon: faCopy}}} label={'Duplicate'} className="w-full min-w-52" justifyContent="start"
                onClick={()=>{
                    props.addField(props.field.type, props.field.parentSection, props.field);
                    removePopoverContainer();
                }}/>
            <Button isGhost edgeIcons={{left: {icon: faCorner}}} label={'Move to'} className="w-full min-w-52" justifyContent="start"/>
            <Button isGhost edgeIcons={{left: {icon: faTrash}}} textColor="error-600" label={'Delete'}
                className="w-full min-w-52 hover:!bg-error-50 hover:!text-error-600" justifyContent="start" onClick={()=>{
                    props.deleteField(props.field.id);
                    removePopoverContainer();
                }}/>

        </ContextMenu>;
    }

    return <>
        {res}
    </>;
}

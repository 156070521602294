import {TF4FormContextValue} from '@aktek/f4form';
import {toast} from '@aktek/f4kit';
import {CompanySettingsService} from '@aktek/helios-frontend-sdk';
import {useEffect, useState} from 'react';

import {strings} from '@/localization/i18n';
import {TCompanySettings} from '@/types/CompanySettings';

import {DEFAULT_SETTINGS} from '../constants/Settings.c';

const useSettings = () => {
    const [settings, setSettings] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        fetchSettings();
    }, []);

    const fetchSettings = async () => {
        setIsLoading(true);
        const res = await CompanySettingsService.getCompanySettings();

        if (!res.isSuccessful?.()) return;

        const settingsData = res.getData() as unknown[];
        const settingsValues = settingsData.reduce((acc, {section, setting, value}) => {
            if (!acc[section]) {
                acc[section] = {};
            }

            acc[section][setting] = value;

            return acc;
        }, {});

        setSettings(settingsValues);
        setIsLoading(false);
    };

    const handleSave = async (context: TF4FormContextValue) => {
        if (!context.validate().isFormValid) return;

        setIsLoading(true);
        const formData = context.data;

        const transformedData = Object.entries(formData).flatMap(([section, _settings]) =>
            Object.entries(_settings).map(([setting, value]) => ({
                section,
                setting,
                value: typeof value === 'boolean' ? value : isNaN(value as number) ? value : Number(value),
            })),
        );

        const res = await CompanySettingsService.storeCompanySettings(transformedData);

        if (!res.isSuccessful()) return;

        toast.success(strings('settings_saved_successfully'));

        setSettings(formData);

        const localStorageData = Object.entries(formData).reduce((acc, [, settings]) => {
            Object.entries(settings).forEach(([setting, value]) => {
                if (typeof value === 'boolean') {
                    acc[setting] = value;
                } else if (!isNaN(value as number)) {
                    acc[setting] = Number(value);
                } else {
                    acc[setting] = value;
                }
            });

            return acc;
        }, {});
        ls.companySettings = localStorageData as TCompanySettings;
        setIsLoading(false);
    };

    const handleReset = (appearanceF4FormContext: TF4FormContextValue) => {
        appearanceF4FormContext.reset(DEFAULT_SETTINGS);
    };

    const handleDiscard = (context: TF4FormContextValue) => {
        context.reset();
    };

    return {
        isLoading,
        settings,
        handleSave,
        handleReset,
        handleDiscard,
    };
};

export default useSettings;

import {DatePicker, Icon, Label, Popover, TPlacement} from '@aktek/f4kit';
import {faCalendar, faXmark} from '@fortawesome/pro-regular-svg-icons';
import {useCallback, useState} from 'react';

import {ConvertUnixToDate, DateToUnix} from '@/helpers/globalDates.fn';
import {strings} from '@/localization/i18n';

import {useDate} from '../hooks/useDate';

type TValidityDatePickerProps = {
    onClickHandlerFunction?: (timestamp: unknown) => void
    pickedDateState?: string
    onClearField?: () => void
    defaultOpen?: boolean
    placement?: TPlacement
    label?: string
}

const ValidityDatePicker = (props: TValidityDatePickerProps) => {
    const {
        onClickHandlerFunction,
        pickedDateState,
        onClearField,
        defaultOpen = false,
        placement = 'left',
        label = strings('validity'),
    } = props;

    const today = new Date();
    const [isOpened, setIsOpened] = useState(defaultOpen);
    const [pickedDate, setPickedDate] = useState(pickedDateState);

    const onClickHandler = useCallback((timestamp) => {
        setPickedDate(ConvertUnixToDate(timestamp));
        onClickHandlerFunction?.(timestamp);
    }, []);

    const onClearFieldHandler = useCallback((e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        onClearField?.();
        setPickedDate('');
    }, []);

    const {selectedDate, setSelectedDate, handleDayClick} = useDate(0, isOpened, setIsOpened, onClickHandler);

    return (

        <Popover
            width="full"
            trigger="controlled"
            placement={placement}
            isOpen={isOpened}
            matchReferenceWidth={false}

            onClose={()=>{
                setIsOpened(false);
            }}
            content= {
                <DatePicker
                    renderCalendarContentProps={{
                        dateProps: {
                            placeholder: strings('select_date'),
                            value: 0,
                            minDate: DateToUnix(today),
                            maxDate: 0,
                            dateFormat: 'DD/MM/YYYY',

                        },
                        isOpened: isOpened,
                        setIsOpened: setIsOpened,
                        selectedDate: selectedDate,
                        setSelectedDate: setSelectedDate,
                        handleDayClick: handleDayClick,

                    }} />
            }>
            <div onClick={() => setIsOpened(!isOpened)}>
                <Label text={label} color="neutral-700" fontWeight="medium" />

                <div
                    className="!w-11/12 border border-neutral-300 flex flex-row items-center justify-between p-4 rounded-lg">
                    <Label text={pickedDate} className="-my-1.5" color="neutral-900" />
                    <div className="flex items-center justify-center gap-2">
                        <Icon icon={faCalendar} color="neutral-600" />
                        {pickedDate && <Icon icon={faXmark} color="neutral-600" className="cursor-pointer" onClick={onClearFieldHandler} />}
                    </div>
                </div>

            </div>

        </Popover>
    );
};

export default ValidityDatePicker;

import {Badge, Card, Icon, Label, MultiLineOverflowContainer, Popover, TabsManager} from '@aktek/f4kit';
import {useCallback, useMemo} from 'react';

import {NavigationButtonClasses as getNavigationCardClasses} from './styles/NavigationCard.styles';
import {TNavigationCategoryProps, TNavigationPage} from './types/Navigation.t';

function NavigationCategory({isVisible, tabKey, color: variant, name, pages = [], customBadges = [], icon}: TNavigationCategoryProps) {
    if (isVisible == false) return;

    const handleBadgeClick = useCallback(async (event: React.MouseEvent, selectedTab: string, onClick?: ()=> void) => {
        event.stopPropagation();
        event.preventDefault();

        if (!onClick) {
            TabsManager.openNewTab('navigationPagesViewer', name, {pages, selectedTab});

            return;
        }

        onClick?.();
    }, [pages, name]);

    const shownBadges = useMemo(() => {
        if (!Array.isArray(pages) || !Array.isArray(customBadges)) {
            throw new Error('Pages and customBadges should be array or undefined');
        }

        const mutablePages = [...pages];
        const mutableCustomBadges = [...customBadges];

        mutablePages.sort((a, b) => a.label.localeCompare(b.label));
        mutableCustomBadges.sort((a, b) => a.label.localeCompare(b.label));

        return mutableCustomBadges.concat(mutablePages);
    }, [pages, customBadges]);

    const classes = getNavigationCardClasses();

    return (
        <Card
            key={tabKey}
            borderWidth={1}
            borderColor="neutral-100"
            roundingSize="2xl"
            backgroundColor="white"
            className={classes.card}
            onClick={() => {
                TabsManager.openNewTab('navigationPagesViewer', name, {pages, key: tabKey});
            }}>

            <div className={classes.cardHeader}>
                <Icon icon={icon} className="text-center" style={{color: variant}} size="xl" />
                <Label text={name} size="sm"
                    className="cursor-pointer" color="neutral-900" fontWeight="semibold" />
            </div>

            {/* !!TODO: To debug the infinite render, feel free to get rid of the overflow component below and just use this map by uncommenting it*/}
            {/* {shownBadges.map((badge, index)=>{
                return <Badge
                    onClick={(event) => {
                        handleBadgeClick(badge.key, event, badge?.onClick);
                    }}
                    variant="neutral-100" label={badge.label}
                    iconProps={{color: 'neutral-500'}}
                    labelProps={{color: 'neutral-700', fontWeight: 'medium'}}
                    icon={badge.icon || null} iconPlacement="left" size="xs" key={index}
                    additionalClassName={classes.badgeClassName}/>;
            })} */}

            <MultiLineOverflowContainer<TNavigationPage>
                isProtectedFn={() => false}
                renderOverflow={(badges) => {
                    return <Popover
                        showDelay={0}
                        stopClickEventPropagation
                        trigger="click"
                        placement="left"
                        className="flex-shrink-0" content={
                            <div className={`flex flex-col`}>
                                {badges.map((badge) => {
                                    return <div
                                        className={'flex flex-row gap-2 items-center hover:bg-neutral-50  p-2 cursor-pointer'}
                                        key={badge.label}
                                        onClick={(event) => {
                                            handleBadgeClick(event, badge.tabKey, badge?.onClick);
                                        }}
                                    >
                                        <Icon size="sm" className="w-4" icon={badge.icon} color="neutral-500" />
                                        <Label className="cursor-pointer" size="sm" text={badge.label} color="neutral-500" />
                                    </div>;
                                })}
                            </div>}>

                        <Badge
                            variant="neutral-100"
                            icon={null} label={`+${badges.length} more`}
                            iconPlacement="left" size="xs"
                            additionalClassName={classes.badgeClassName}
                        />
                    </Popover>;
                }}
                maxLines={3}
                items={shownBadges}
                renderVisibleItem={(badge, index) => {
                    return <Badge
                        onClick={(event) => {
                            handleBadgeClick(event, badge.tabKey, badge?.onClick);
                        }}
                        variant="neutral-100" label={badge.label}
                        iconProps={{color: 'neutral-500'}}
                        labelProps={{color: 'neutral-700', fontWeight: 'medium'}}
                        icon={badge.icon || null} iconPlacement="left" size="xs" key={index}
                        additionalClassName={classes.badgeClassName} />;
                }}

                className="flex flex-col gap-2 p w-[-webkit-fill-available]"
                overflowContainerClassName={`flex flex-row gap-2`}
            />
        </Card>
    );
}

export default NavigationCategory;

import {TabsManager, toast} from '@aktek/f4kit';
import {faTrash} from '@fortawesome/pro-light-svg-icons';
import {faDatabase} from '@fortawesome/pro-regular-svg-icons';

import {AddToRecentlyUsedSBOs} from '@/app/Admin/Data/pages/DataStructure/helpers/RecentlyUsedSBOs.fn.';
import AskForConfirmation from '@/asks/AskForConfirmation';
import AskForFolder from '@/asks/AskForFolder';
import AskForModal from '@/asks/AskForModal';
import ConfirmDeleteModal from '@/components/F4DeleteModal/F4ConfirmDeleteModal';
import {CopyToClipboard} from '@/helpers/CopyToClipboard.fn';
import {CreateURLWithParams} from '@/helpers/CreateURLWithParams.fn';
import {strings} from '@/localization/i18n';

import {GetFolderContextMenu, GetReportsContextMenu, GetShortcutContextMenu} from '../constants/getExplorerTreeContextMenu.c';
import FolderEditMoveModal from '../modals/Folders.Edit.Modal';

export const GetContextMenu = ({
    item,
    foldersPermissions,
    reportStructure,
    currentCompany,
    onSave,
    deleteFolder,
    openDataTable,
    moveDataTableLocation,
    deleteShortcut,
}) => {
    const {type} = item;

    if (type == 'folder') {
        return GetFolderContextMenu({
            copyLinkToFolder: () => onCopyToClipboard(item, currentCompany),
            openEditModal: () => onEditFolder(item, onSave),
            openDeleteModal: () => onDeleteFolder(item, onSave, deleteFolder),
            folders: foldersPermissions,
        });
    }

    if (type == 'report') {
        return GetReportsContextMenu({
            openReport: ()=> onReportOpen(item, openDataTable),
            moveDataTable: () => moveDataTable(item, moveDataTableLocation),
            editDataStructure: () => editDataStructure(item),
            folders: foldersPermissions,
            reportStructure: reportStructure,
        });
    }

    if (type == 'shortcut') {
        return GetShortcutContextMenu({
            openDeleteModal: () => onDeleteShortcut(item, onSave, deleteShortcut),
            openShortcut: ()=> TabsManager.focusOrOpenNewTab(item.component, item.component, {}),
            folders: foldersPermissions,
        });
    }

    return null;
};

// Folder context menu functions
const onDeleteFolder = (item, onDelete, deleteFolder) => {
    const {id, label} = item;

    AskForConfirmation(`Delete ${label}`, strings('delete_sentence'), {
        variant: 'error',
        icon: faTrash,
        onConfirm: async () => {
            const res = await deleteFolder(id);

            if (res.isSuccessful()) {
                toast.success('Folder deleted');
                onDelete();
            }
        }});

    /* return AskForModal(ConfirmDeleteModal, {
        itemId: item.id,
        itemName: item.label,
        deleteSDKCall: deleteFolder,
        onDelete,
        showTrashIcon: true,
    });*/
};

const onEditFolder = (item, onSave) => {
    AskForModal(
        FolderEditMoveModal,
        {state: {mode: 'edit', _id: item.id}, onSave, folder: item},
    );
};

const onCopyToClipboard = (item, currentCompany) => {
    CopyToClipboard(CreateURLWithParams({param1: item.id, param2: currentCompany?._id}));
};

// Report context menu functions
const onReportOpen = (item, openDataTable) => {
    const {label, id, type} = item;

    openDataTable({label, id, type});
};

const moveDataTable = async (item, moveDataTableLocation) => {
    const folderResults = await AskForFolder({folder: item});
    moveDataTableLocation(folderResults as object);
};

const editDataStructure = async (item) => {
    TabsManager.openNewTab('dataStructureEditor', item.label, {sboId: item.id});
    const tab = {
        key: item.id,
        label: item.label,
        icon: faDatabase,
    };
    AddToRecentlyUsedSBOs(tab);
};

// Shortcut context menu functions
const onDeleteShortcut = (item, onDelete, deleteShortcut) => {
    const {id, label} = item;

    AskForConfirmation(`Delete ${label}`, strings('delete_sentence'), {
        variant: 'error',
        icon: faTrash,
        onConfirm: async () => {
            const res = await deleteShortcut(id);

            if (res.isSuccessful()) {
                toast.success('Shortcut deleted');
                onDelete();
            }
        }});

    /*  return AskForModal(ConfirmDeleteModal, {
        itemId: item.id,
        itemName: item.label,
        deleteSDKCall: deleteShortcut,
        onDelete,
        showTrashIcon: true,
    });*/
};

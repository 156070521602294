import IOHubRules from './iohub.rules';
import type {THubEntryType, TIOHubPropsOptions} from './types/IOHub.t';

function GetKey<A extends THubEntryType>(props: TIOHubPropsOptions[A]) {
    const {type} = props;
    const hubRule = IOHubRules[type];
    const {cacheKeys} = hubRule;

    const cacheKeyParts = cacheKeys ? cacheKeys.map((e)=> props[e]).join('') : '';

    return `${type}${cacheKeyParts}`;
}

function GetRefreshKeys(type: THubEntryType) {
    const hubRule = IOHubRules[type];

    if (!hubRule) {
        console.warn('No cache rule for type:', type);

        return;
    }

    return hubRule.refreshKeys;
}

function GetHubRule(type: THubEntryType) {
    return IOHubRules[type];
}

export default {
    GetKey,
    GetRefreshKeys,
    GetHubRule,
};

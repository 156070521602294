import {F4FormDiv, F4FormStep, useF4FormContext} from '@aktek/f4form';
import {FitHeight} from '@aktek/f4kit';

import {TMode} from '@/components/F4CreatableModal/types/F4CreatableModalProps.t';

import {storingToF4Form} from '../helpers/storingToF4Form';
import usePermissions from '../hooks/usePermissions';
import PermissionEditor from './PermissionEditor';
import PermissionLoading from './PermissionLoading';

type TRolesPermissions = {
    viewState: { mode: TMode; _id?: string; actionTitle?: string } & Record<string, unknown>;
    initialIsSuperAdmin: boolean;
}

export function RolesPermissions(props: TRolesPermissions) {
    const {viewState, initialIsSuperAdmin} = props;

    const f4FormContext = useF4FormContext();
    const roleId = f4FormContext.data?.['id'];

    if (!viewState) {
        ls.permissionSection = 'admin';
    }

    const {
        updatedPermissionsState,
        resetPermissions,
        setUpdatedPermissionsState,
        loading,
        canResetPassword,
    } = usePermissions(roleId, f4FormContext, initialIsSuperAdmin, viewState);

    return (
        <F4FormStep step="permissions" autoNest>
            <F4FormDiv className="-mt-2">

                {loading && <PermissionLoading />}
                <FitHeight className="flex-col h-full bg-white rounded-lg">

                    {!loading && <FitHeight className="h-full -mt-1">
                        {updatedPermissionsState?.permissions
                                && <PermissionEditor
                                    key={updatedPermissionsState?.key + '' + loading}
                                    permissions={updatedPermissionsState?.permissions}
                                    onPermissionsChange={(data) => {
                                        storingToF4Form(data, f4FormContext);
                                        setUpdatedPermissionsState(data);
                                    }}
                                    resetPermissions={resetPermissions}
                                    canResetPassword={canResetPassword}
                                />}
                    </FitHeight>}
                </FitHeight>
            </F4FormDiv>

        </F4FormStep>
    );
}

export default RolesPermissions;

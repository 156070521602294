import {Badge, Icon} from '@aktek/f4kit';
import {faQuestionCircle, faSquareQuestion} from '@fortawesome/pro-light-svg-icons';

import SmartFilterPreviewField from './SmartFilter.Preview.Field';
import SmartFilterPreviewOperator from './SmartFilter.Preview.Rule.Operator';
import SmartFilterPreviewValue from './SmartFilter.Preview.Value';
import {TSmartFilterPreviewRuleProps} from './types/SmartFilter.Preview.t';

export default function SmartFilterPreviewRule(props: TSmartFilterPreviewRuleProps) {
    const {rule, fields} = props;

    const hasFill = !!rule.fieldId || !!rule.operator || !!rule.value;

    if (!hasFill) {
        return <Icon
            tooltip="Missing Rule"
            tooltipProps={{showDelay: 350}}
            className="self-center contents-center items-center align-middle"
            color="warning-500"
            icon={faSquareQuestion} />;
    }

    const {operator, fieldId} = rule;
    if (!fields) return 'No fields';
    const field = fields[fieldId];

    if (!field) {
        return <Badge
            className="rounded-xs"
            iconPlacement="left"
            size="sm"
            color="neutral-600"
            labelProps={{color: 'white'}}
            icon={faQuestionCircle}
            iconProps={{color: 'white'}}
            label="Select field" />;
    }

    return <div className="flex flex-row gap-1 items-center">
        <SmartFilterPreviewField fields={fields} fieldId={fieldId} />
        <SmartFilterPreviewOperator operator={operator}/>
        <SmartFilterPreviewValue rule={rule} fields={fields}/>
    </div>;
}

import {Breadcrumb, Button, Empty, Label, TBreadcrumbStep} from '@aktek/f4kit';
import {faPlus, faRefresh, IconDefinition} from '@fortawesome/pro-regular-svg-icons';
import _compact from 'lodash/compact';

import AskForCreationModal from '@/asks/AskForCreatableModal';
import {useAdminPermissions} from '@/context/Permissions/usePermissions';
import {useGlobalFilesAndFolders} from '@/context/UserContext/Hooks/useGlobalFilesAndFolders';
import {strings} from '@/localization/i18n';

import {TExplorerUIState} from '../hooks/useExplorerUI';
import FolderCreateModal from '../modals/Folders.Create.Modal';
import ExplorerLoadingComponent from './ExplorerLoadingComponent';

type TEmptyComponentProps = {
        label:string,
         description: string,
         parentId?:string,
         icon?:IconDefinition,
         explorerUIState:TExplorerUIState
        }

const EmptyComponent = (props: TEmptyComponentProps) => {
    const {
        label,
        description,
        parentId,
        icon,
        explorerUIState,
    } = props;
    const {folders: foldersPermissions} = useAdminPermissions();
    const {isLoading, refresh} = useGlobalFilesAndFolders();

    const {ancestors, setSelectedFolder, rightPanelView} = explorerUIState;

    const emptyActionElements = [
        foldersPermissions?.can_create && <Button
            key={1}
            variant="neutral"
            isGhost={false}
            isOutlined={true}
            isCircle={false}
            edgeIcons={{left: {icon: faPlus, color: 'neutral-600'}}}
            label={strings('new_folder')}
            onClick={() => AskForCreationModal(FolderCreateModal, {state: {mode: 'create', _id: null}, onSave: refresh, parentId})}
            size={'sm'} />,
        <Button
            key={2}
            variant="primary"
            isGhost={false}
            isOutlined={false}
            isCircle={false}
            label={strings('refresh')}
            onClick={refresh}
            size={'sm'}
            icon={faRefresh}
        />];

    return (
        <>
            {rightPanelView === 'folders' && (
                <Breadcrumb
                    className="!bg-neutral-50 pt-4"
                    buttonProps={{className: '!pl-1.5'}}
                    separatorIconProps={null}
                    onStepClick={setSelectedFolder}
                    path={ancestors as TBreadcrumbStep[]}
                />
            )}

            {rightPanelView === 'recent' && (
                <div className="!bg-neutral-50 p-4" >
                    <Label
                        text={strings('recently_used')}
                        color="primary-700"
                        size="sm"
                        fontWeight="semibold"
                        className="p-2"
                    />
                </div>
            )}

            <div className="flex justify-center bg-neutral-50 w-full h-full border-l border-neutral-200">
                <Empty
                    label={label}
                    size="lg"
                    icon={icon}
                    titleClassName="!text-base"
                    descriptionClassName="!text-sm"
                    className="max-w-lg mt-40 flex items-center justify-start"
                    description={description}
                    descriptionColor="neutral-600"
                    actionElements={_compact(emptyActionElements)}
                />

                <ExplorerLoadingComponent condition={isLoading} />
            </div>
        </>
    );
};

export default EmptyComponent;


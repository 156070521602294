import {Label} from '@aktek/f4kit';

import {TSmartFilterPreviewGroupOperatorProps} from './types/SmartFilter.Preview.t';

function SmartFilterPreviewGroupOperator(props: TSmartFilterPreviewGroupOperatorProps) {
    const {operator} = props;

    return <Label className="content-center" size="sm" fontWeight="semibold" text={operator == '$and' ? 'AND' : 'OR'} color="semantic2-600"/>;
}

export default SmartFilterPreviewGroupOperator;

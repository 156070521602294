import {TF4ThemeColors} from '@aktek/f3theme';
import {AvatarLabelGroup, Label, TStatusIconProps} from '@aktek/f4kit';
import {faSuitcase} from '@fortawesome/free-solid-svg-icons';
import {faMinus, faSnowflake} from '@fortawesome/pro-regular-svg-icons';

import {TColumn} from '@/components/Table/Types/Table.t';
import Env from '@/config/env';
import {GetNameInitials} from '@/helpers/GetNameInitial.fn';
import {strings} from '@/localization/i18n';
import {TUser} from '@/types/User.t';

const GetUsersColumns = (): Array<TColumn> => {
    return [
        {
            key: 'name', header: strings('user'), type: 'string', isSortable: true,
            renderCell({row}) {
                const {name, email, profilePicURL, isActive, isFrozen, isAccountManager} = row?.original as TUser;
                const img = profilePicURL ? {
                    src: Env.getFileURL(profilePicURL),
                    alt: 'Profile Picture',
                } : null;

                const avatarAltLabel = !profilePicURL ? GetNameInitials(name) : null;

                const icons = [
                    {
                        icon: faSnowflake,
                        baseClass: 'bg-[#B2DDFF] p-0.5',
                        color: 'white' as TF4ThemeColors,
                        isVisible: isFrozen,
                    },
                    {
                        icon: faMinus,
                        baseClass: 'bg-warning-500 p-0.5',
                        color: 'white' as TF4ThemeColors,
                        isVisible: !isActive,
                    },
                    {
                        icon: faSuitcase,
                        baseClass: 'bg-white !p-2',
                        color: 'primary-500' as TF4ThemeColors,
                        isVisible: isAccountManager,
                    },
                ];

                const positionedIcons: TStatusIconProps[] | TStatusIconProps = icons
                    .filter((icon) => icon.isVisible) // Filter out invisible icons first
                    .map((icon, index) => {
                        const positionClassesByIndex = {
                            0: '!bottom-0 !right-0',
                            1: '!bottom-4 !-right-2',
                            2: '!bottom-8 !right-0',
                        };

                        return {
                            ...icon,
                            className: `${icon.baseClass} ${positionClassesByIndex[index] || ''}`,
                        };
                    });

                return <AvatarLabelGroup
                    avatar={{
                        img: img,
                        avatarAltLabel: avatarAltLabel,
                        avatarClassName: avatarAltLabel ? 'border-none' : '',
                        statusIcon: positionedIcons,
                    }}
                    label={{
                        text: name,
                    }}
                    subLabel={{
                        text: email,
                    }}
                    size="sm"
                    isCheckable={false}
                />;
            },
        },
        {
            key: 'userType', header: strings('user_type'), type: 'string', isSortable: true,
            renderCell({cell}) {
                return <Label text={strings((cell.getValue()as string)?.toLowerCase())} />;
            },
        },
        {key: 'unixLastLoginDate', header: strings('last_login_date'), type: 'datetime', isSortable: true},
        {key: 'creationDate', header: strings('creation_date'), type: 'date', isSortable: true},

    ];
};

export default GetUsersColumns;

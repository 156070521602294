import {generateSaveablePermission} from './generateSaveablePermission';

export const storingToF4Form = (updatedPermissions, f4FormContext) => {
    if (updatedPermissions === null) return;
    const result = [];

    Object.keys(updatedPermissions).forEach((section) => {
        const sectionPermissions = updatedPermissions[section];
        sectionPermissions.forEach((permission) => {
            result.push(...generateSaveablePermission(permission));
        });
    });

    f4FormContext.setValue('permissions', result);
};

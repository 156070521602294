import {Button, Label, Tooltip} from '@aktek/f4kit';
import {faPlus} from '@fortawesome/pro-regular-svg-icons';

import AskForModal from '@/asks/AskForModal';
import {strings} from '@/localization/i18n';

import FolderCreateModal from '../modals/Folders.Create.Modal';

const AddFolderButton = ({isVisible, refresh, parentId}) => {
    if (!isVisible) return null;

    return (
        <Tooltip showDelay={1000} content={
            <div className="flex justify-center items-center px-1 py-0.5">
                <Label text={strings('add_folder')} size="xs"/>
            </div>}>

            <Button variant="primary" icon={faPlus} size="sm"
                onClick={() => AskForModal(FolderCreateModal, {viewState: {mode: 'create'}, onSave: refresh, parentId})}
            />
        </Tooltip>
    );
};

export default AddFolderButton;

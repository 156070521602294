import {F4FormDiv} from '@aktek/f4form';
import {Icon, Label} from '@aktek/f4kit';
import {faTag} from '@fortawesome/pro-regular-svg-icons';

import F4ToggleButton from '@/components/F4Elements/F4ToggleButton';

export default function SBOEditorRecordsTagsField() {
    return (
        <F4FormDiv className="group">
            <F4FormDiv className="flex ml-5">
                <F4FormDiv className="flex justify-between bg-neutral-100 rounded-xl items-center p-4 gap-2" show={(valueOf)=>!!valueOf('hasRecordTags')} >
                    <F4FormDiv className="flex items-center gap-0">
                        <Icon icon={faTag} color="success-600" className="w-4 h-4"/>
                        <Label text="Record tags" className="ml-2"/>
                    </F4FormDiv>
                    <F4FormDiv className="flex items-center gap-3 float-end">
                        <F4ToggleButton isGhost text={'Required'} name="areRecordTagsRequired" className="text-sm" size="sm" />
                    </F4FormDiv>
                </F4FormDiv>
            </F4FormDiv>
        </F4FormDiv>
    );
}


import F4ToggleSwitch from '@/components/F4Elements/F4Toggle';

const BooleanCellEdit = ({value, onChange}) => {
    return <F4ToggleSwitch
        value={value}
        onChange={(value) => {
            console.log('....Boolean.Cell.Edit.tsx -> Line 10', {value});
            onChange(value);
        }}
    />;
};

export default BooleanCellEdit;

import {GroupsService, GroupsV4Service} from '@aktek/helios-frontend-sdk';

import F4ViewableComponent from '@/components/F4ViewableComponent/F4ViewableComponent';
import useTableRef from '@/components/Table/Hooks/useTableRef';
import {useAdminPermissions} from '@/context/Permissions/usePermissions';
import {strings} from '@/localization/i18n';

import CreateGroupModal from './components/Groups.Create.Modal';
import {GetGroupRowActions} from './helpers/Groups.GetGroupRowActions.fn';
import {TGroup} from './types/Groups.t';

export default function Groups() {
    const tableRef = useTableRef();
    const {groups: groupsPermissions} = useAdminPermissions();

    return (
        <F4ViewableComponent<TGroup>
            name={strings('group')}
            tableRef={tableRef}
            modal={CreateGroupModal}
            tableColumns={[{key: 'name', header: strings('group'), type: 'string', isSortable: true}]}
            rowActions={GetGroupRowActions(groupsPermissions)}
            hasSearch
            defaultSortedColumn="name"
            canCreate={groupsPermissions?.can_create}
            emptyProps={{addLabel: strings('new_group')}}
            getAllData={GroupsV4Service.getAllGroups}
            deleteItem={GroupsService.deleteGroup}
            deleteSuccessMessage={strings('group_deleted_successfully')}
        />
    );
}

import {useMemo} from 'react';

/**
 * Custom hook to generate an array of props values based on the provided keys.
 * @param {Object} props - The props object of the component.
 * @param {string[]} keys - Array of keys to extract values from props.
 * @return {Array} - Array of values corresponding to the keys in props.
 */
const usePropsArray = (props, keys) => {
    const propsArray = useMemo(() => {
        if (Array.isArray(keys)) {
            return keys.map((key) => props[key]);
        }

        return null;
    }, [props, keys]);

    return propsArray;
};

export default usePropsArray;

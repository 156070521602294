import {useState} from 'react';

export default function useForceUpdate() : [number, ()=> void] {
    const [value, setValue] = useState(0); // integer state

    const forceUpdate = () => {
        setValue((value) => value + 1);
    }; // update the state to force render

    return [value, forceUpdate];
}

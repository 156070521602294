import {Label, TabsManager, TContextMenuEntry, TreeView} from '@aktek/f4kit';
import _isEqual from 'lodash/isEqual';
import {useEffect} from 'react';

import {useAdminPermissions} from '@/context/Permissions/usePermissions';
import {useGlobalFilesAndFolders} from '@/context/UserContext/Hooks/useGlobalFilesAndFolders';
import {useUserAuth} from '@/context/UserContext/Hooks/useUserAuth';
import {FindFolderById} from '@/helpers/FindFolderById.fn';
import {strings} from '@/localization/i18n';
import {FileTypes} from '@/types/FileTypes.t';

import {FOLDERS_AND_DATA, RECENTLY_USED, REPOSITORIES, SHORTCUTS} from '../constants/constantIds.c';
import {GetContextMenu} from '../helpers/Folder.GetContextMenu.fn';
import {TExplorerUIState} from '../hooks/useExplorerUI';
import {getTreeItems} from '../utils/getTreeItems.fn';
import ExplorerLoadingComponent from './ExplorerLoadingComponent';

export default function ExplorerTree({searchValue, explorerUIState}:{searchValue:string, explorerUIState: TExplorerUIState}) {
    const {
        switchToFoldersView,
        switchToRecentView,
        setSelectedFolder,
        selectedFolder,
    } = explorerUIState;

    const {
        folders: items,
        isLoading,
        refresh,
        deleteFolder,
        deleteShortcut,
        recentlyUsed,
        openDataTable,
        moveDataTableLocation,
        handleRecentlyUsedClick,
    } = useGlobalFilesAndFolders();

    const {folders, reportStructure} = useAdminPermissions();

    const {currentCompany} = useUserAuth();

    const data = getTreeItems(items, searchValue, recentlyUsed);
    const hasData = data && data.length > 0;

    const treeNodeSections = [FOLDERS_AND_DATA, SHORTCUTS, REPOSITORIES, RECENTLY_USED];

    // this useEffect to make sure the right side data are set correctly after the search
    useEffect(() => {
        if (!selectedFolder || searchValue) return;
        const result = FindFolderById(data, selectedFolder?.id);
        if (_isEqual(result, selectedFolder)) return;
        setSelectedFolder(result);
    }, [searchValue, data]);

    const onNodeClick = async (node) => {
        const {type, label, id} = node;

        const isSection = treeNodeSections.includes(id);
        const isRecentlyUsed = id == RECENTLY_USED;
        const isFolderAndData = id == FOLDERS_AND_DATA;

        if (isSection) ls.openExplorerSection('explorer-' + id);

        if (isFolderAndData) {
            switchToFoldersView();

            setSelectedFolder(node);

            return;
        }

        if (type == FileTypes.recent || isRecentlyUsed) {
            switchToRecentView();

            if (isSection) return setSelectedFolder(node);

            return handleRecentlyUsedClick(label, id);
        }

        if (type == FileTypes.report) return openDataTable({label, id, type});

        if (type == FileTypes.shortcut) {
            TabsManager.focusOrOpenNewTab(node.component, node.component, {selectedTab: node?.props?.selectedTab});
        }

        if (node.type == 'geo') return;

        switchToFoldersView();
        setSelectedFolder(node);

        return;
    };

    const onCaretClick = (node) => {
        const {id} = node;

        ls.toggleExplorerSection('explorer-' + id);
    };

    const onContextMenu = (item) => GetContextMenu({
        item,
        foldersPermissions: folders,
        reportStructure,
        currentCompany,
        onSave: refresh,
        deleteFolder,
        openDataTable,
        moveDataTableLocation,
        deleteShortcut,
    }) as TContextMenuEntry[];

    return <div className="py-2 h-full w-full overflow-y-auto">

        {hasData && <TreeView
            size="xs"
            data={data}
            onNodeClick={onNodeClick}
            onCaretClick={onCaretClick}
            getContextMenu={onContextMenu}
            className="[&>:first-child]:pl-5"
            nodeMoveTargetFilter={(e) => e.type == 'folder'}
            activeNode={selectedFolder?.id || RECENTLY_USED}
            labelProps={{
                color: 'neutral-900',
                fontWeight: 'normal',
                className: '!overflow-hidden ',
            }}/>}

        {!hasData && !isLoading && <Label
            text={strings('no_results_found')} size="sm" className="mt-5 ml-4 overflow-hidden" color="neutral-600"/>}

        <ExplorerLoadingComponent className="h-screen" condition={!data || isLoading} />
    </div>;
}

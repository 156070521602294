import {AdminPermissionsGroup, AppPermissionsGroup} from '@/types/Permissions.t';

import {useUserContext} from '../UserContext/UserContext.Provider';

export function usePermissions() {
    const {permissionsState} = useUserContext();

    return permissionsState;
}

export function useAdminPermissions() {
    const {permissionsState} = useUserContext();

    return permissionsState?.permissions?.admin || ({} as AdminPermissionsGroup);
}

export function useAppPermissions() {
    const {permissionsState} = useUserContext();

    return permissionsState?.permissions?.app || ({} as AppPermissionsGroup);
}

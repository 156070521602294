
import {F4Form, F4FormDiv, TF4FormContextValue, useF4FormRef} from '@aktek/f4form';
import {Button, Label, Modal, TModalRef, useModalRef} from '@aktek/f4kit';
import {faArrowDownToLine} from '@fortawesome/pro-regular-svg-icons';
import {F4ArrayChildrenProps} from 'node_modules/@aktek/f4form/dist/Core/types/F4ArrayChildrenProps.t';
import {forwardRef} from 'react';

import F4Textbox from '@/components/F4Elements/F4TextBox';
import {strings} from '@/localization/i18n';

type TExportModalProps = {
    onClose?: () => void;
    context?: TF4FormContextValue<object> | F4ArrayChildrenProps
}

function ExportModal(props: TExportModalProps, ref: TModalRef) {
    const {
        context,
        onClose,
    } = props;
    const modalRef = useModalRef(ref);
    const formRef = useF4FormRef();

    return (
        <Modal
            size="md"
            ref={modalRef}
            onClose={onClose}
            className="w-fit h-fit"
            headerClassName="!absolute !w-fit !h-fit right-0 elevation-1 !shadow-none rounded-xl"
        >
            <F4Form ref={formRef} formKey="export" className="p-4">

                <F4FormDiv className="flex  flex-col justify-center items-center ">

                    <Button
                        icon={faArrowDownToLine}
                        textColor="primary-600"
                        className="!bg-primary-25 cursor-default"
                        size="xl"
                        isCircle/>
                    <Label text={strings('export_theme')} size="lg" className="mt-4 font-semibold" color="neutral-900"/>

                </F4FormDiv>

                <F4Textbox
                    name="fileName"
                    colSpan={12}
                    label={strings('file_name')}
                    placeholder=""
                    className="mt-2"
                    rightPlaceholder={'.iotheme'}
                    type={'text'}

                />
                <F4FormDiv colSpan={12} className="flex justify-between items-center gap-4 mt-4">

                    <Button
                        label={strings('cancel')}
                        variant="white"
                        className="w-[10.625rem]"
                        onClick={() => modalRef.current.close()}
                    />

                    <Button
                        label={strings('export')}
                        className="w-[10.625rem]"
                        onClick={ () => {
                            context.exportData(formRef?.current?.getData()['fileName'], 'iotheme');
                            modalRef.current?.close();
                        }}
                    />
                </F4FormDiv>
            </F4Form>
        </Modal>
    );
}

export default forwardRef(ExportModal);

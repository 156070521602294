import {RefFieldButton} from '@/components/RefFieldButton/RefFieldButton';

const RefListAnnexCellView = ({value, column = {}}) => {
    const {field} = column;

    if (!field) return null;

    return <div className="flex gap-1">
        {value?.map?.((refValue, index) => <RefFieldButton
            key={refValue?._id + '-' + index}
            sboId={field.sboId}
            fieldId={field.fieldId}
            value={refValue?._id}
        />)}
    </div>;
};

export default RefListAnnexCellView;

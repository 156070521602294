import {F4FormConfigProvider, F4UndoRedoContextProvider} from '@aktek/f4form';
import {Icon, Label, LoadingIndicator} from '@aktek/f4kit';
import {faAsterisk} from '@fortawesome/pro-regular-svg-icons';
// import {faAsterisk} from '@fortawesome/free-solid-svg-icons';
import {HashRouter} from 'react-router-dom';

import {IOCacheContextProvider} from '@/iohub/IOCache/IOCacheContext';

import UserContextProvider from './UserContext/UserContext.Provider';

const Loader = () => <div className="justify-center items-center w-full h-full flex" style={{position: 'absolute', top: 0, bottom: 0, right: 0, left: 0, background: '#eeeeee', opacity: 0.7, textAlign: 'center', zIndex: 9999}}>
    <LoadingIndicator size="xl" />
</div>;

export default function GlobalProviders({children}) {
    return (
        <F4FormConfigProvider
            commonF4FormConfig={{
                revalidateFieldOnChange: 'errors',
                allowUndoRedo: true,
                printData: 'console',
                // TODO: error was: that this prop is not available on type: TF4FormProps
                // validateFieldOnChange: true,
                // validateFormOnChange: true,
                onChangeDebounce: 200,
                layout: {minColumnWidth: 6.25, gap: 0.625, hasGridLayout: true},
            }}
            commonElementProps={{
                size: 'md',
                color: 'blue',
                // we are relying on our label below
                label: null,
            }}
            loader={<Loader />}
            messages={{
                field_is_required: 'Field is mandatory',
            }}
            renderElement={(args) => {
                const {Element, renderedElement, computedProps, props, errors} = args;
                const {label, required} = props;

                return (
                    <>
                        <div className="flex flex-row gap-1 ml-1 mb-2">
                            {label && <Label
                                size="sm"
                                className="font-medium text-neutral-600 leading-tight "
                                fontWeight="medium"
                                color="neutral-900"
                                text={label}
                            />}
                            {/* <Label color="neutral-400" fontWeight="normal" text=" (optional)" /> */}
                            {required && <Icon size="xs" icon={faAsterisk} color="warning-800"/>}
                        </div>

                        {errors?.length > 0
                            ? <Element variant={'error'} {...computedProps}/>
                            : renderedElement}

                        {errors?.map?.((error, index) => {
                            return (
                                <div
                                    id="error-field"
                                    key={index}>
                                    <Label text={error} color="error-500" size="sm" />
                                </div>
                            );
                        })}
                    </>);
            }} >
            <F4UndoRedoContextProvider>
                <IOCacheContextProvider>
                    <UserContextProvider>
                        <HashRouter>
                            {children}
                        </HashRouter>
                    </UserContextProvider>
                </IOCacheContextProvider>
            </F4UndoRedoContextProvider>
        </F4FormConfigProvider>
    );
}

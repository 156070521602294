import IOSelect from '@/iohub/IOSelect';

const RefCellEdit = ({value, onChange, column = {}}) => {
    const {field} = column;

    if (!field) return null;

    return <IOSelect autoOpenOnMount
        value={value}
        sboId={field?.sboId}
        displayFieldId={field?.fieldId}
        type="ref"
        onChange={(value) => {
            onChange(value);
        }}
    />;
};

export default RefCellEdit;

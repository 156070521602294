// Constants
export const MAX_STACK_SIZE = 15;
export const STORAGE_KEY = 'recentlyUsedSBO';

// !!TODO: Not a stack, it's a bounded array
// Function to add a tab object to the recently used stack
export const AddToRecentlyUsedSBOs = (sbo) => {
    let stack = JSON.parse(localStorage.getItem(STORAGE_KEY)) || [];

    // Check if the tab object already exists in the stack
    stack = stack.filter((item) => item.key !== sbo.key);

    // Add the new tab object to the beginning of the stack with a timestamp
    const tabWithTimestamp = {...sbo, timestamp: Date.now()};
    stack.unshift(tabWithTimestamp);

    // Remove the oldest entry if the stack exceeds the maximum size
    if (stack.length > MAX_STACK_SIZE) {
        stack.pop();
    }

    localStorage.setItem(STORAGE_KEY, JSON.stringify(stack));
};

// Function to get and sort the recently used tabs
export const GetRecentlyUsedSBOs = ()=> {
    const stack = JSON.parse(localStorage.getItem(STORAGE_KEY)) || [];

    stack.sort((a, b) => b.timestamp - a.timestamp);

    return stack;
};


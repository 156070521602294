import {F4FormDiv, TF4FormRefFn} from '@aktek/f4form';

import {TField, TFieldTypes, TSBO, TSection} from '../types/SBOEditor.t';
import SBOEditorRecordsTagsField from './SBOEditorRecordsTagsField';
import SBOEditorSections from './SBOEditorSections';

type TSBOEditorBodyProps = {
    addSection: (duplicatedSection: TSection, fieldId: string) => void
    deleteSection: (sectionId: string) => void
    addField: (fieldType: TFieldTypes, sectionId: string, duplicatedField: TField) => void
    deleteField: (fieldId: string) => void
    onMoveFieldToTable: (fieldId: string) => void
    handleTagsVisibility: () => void
    sboFormRef: React.MutableRefObject<TF4FormRefFn>
}

export default function SBOEditorBody(props:TSBOEditorBodyProps) {
    const data = props.sboFormRef?.current?.data as TSBO;

    return (
        <F4FormDiv className="pb-6 -ml-5">
            <SBOEditorRecordsTagsField />

            <F4FormDiv className="group">
                <SBOEditorSections
                    addField={props.addField}
                    sboFormRef={props.sboFormRef}
                    addSection={props.addSection}
                    deleteSection={props.deleteSection}
                    onMoveFieldToTable ={props.onMoveFieldToTable }
                    deleteField={props.deleteField}
                    currentSBO={data}/>
            </F4FormDiv>
        </F4FormDiv>
    );
}


import {useSpreadsheetRef} from '@aktek/f4kit';
import {useCallback, useMemo, useRef, useState} from 'react';

import {TSmartFilterModalRef} from '@/components/SmartFilter/SmartFilter.Modal';

import {GetDataTableColumns} from '../helpers/DataTable.GetColumns.fn';
import {GetRibbonActions} from '../helpers/DataTable.GetRibbonActions.fn';
import {GetDataTableRows} from '../helpers/DataTable.GetRows.fn';
import {GetDataTableRowsCount} from '../helpers/DataTable.GetRowsCount.fn';

export const useDataTable = (sboId: string) => {
    const [isEditMode, setIsEditMode] = useState(false);
    const [filter, setFilter] = useState(null);
    const smartFilterModalRef = useRef<TSmartFilterModalRef>(null);
    const spreadsheetRef = useSpreadsheetRef();

    const getRows = useCallback(
        (search: unknown, sort: object, skip: number, limit: number) =>
            GetDataTableRows(sboId, search, sort, skip, limit, null, true),
        [sboId],
    );

    const getColumns = useCallback(() => GetDataTableColumns({sboId}), [sboId]);

    const getRowsCount = useCallback(() => GetDataTableRowsCount({sboId}), [sboId]);

    const onRibbonAction = useCallback(
        async (value: string) => {
            switch (value) {
                case 'edit':
                    setIsEditMode((isEdit) => !isEdit);
                    break;
                case 'save':
                    const saved = await spreadsheetRef.current?.save();
                    if (saved) setIsEditMode(false);
                    break;
                case 'filter':
                    smartFilterModalRef.current?.open();
                    break;
                default:
                    break;
            }
        },
        [spreadsheetRef],
    );

    const hasFilter = !!filter;
    const ribbonActions = useMemo(
        () => GetRibbonActions({isEditMode, hasFilter}),
        [isEditMode, hasFilter],
    );

    return {
        getRows,
        getColumns,
        getRowsCount,
        isEditMode,
        setIsEditMode,
        filter,
        setFilter,
        onRibbonAction,
        ribbonActions,
        spreadsheetRef,
        smartFilterModalRef,
    };
};

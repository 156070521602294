import {faArrowUpFromBracket, faChartSimple, faClone, faCloudArrowDown, faFilter, faGrid2Plus, faHistory, faPen, faSave} from '@fortawesome/pro-regular-svg-icons';

import {TTableRibbonActions} from '@/components/Table/Types/Ribbon.t';
import {strings} from '@/localization/i18n';

type TGetRibbonActins = ({isEditMode, hasFilter}:{isEditMode?: boolean, hasFilter?: boolean}) => TTableRibbonActions
export const GetRibbonActions : TGetRibbonActins = ({isEditMode, hasFilter}) => [
    {
        key: 'bulk',
        tooltip: strings('bulk_add_records'),
        label: strings('bulk'),
        icon: faGrid2Plus,
    },
    {
        key: 'duplicate',
        tooltip: strings('duplicate'),
        label: strings('duplicate'),
        icon: faClone,
    },
    {
        key: 'edit',
        tooltip: strings('edit'),
        label: strings('edit'),
        icon: faPen,
        buttonProps: {variant: isEditMode ? 'primary': 'white'},
    },
    {
        key: 'upload',
        tooltip: strings('data_import'),
        label: strings('upload'),
        icon: faArrowUpFromBracket,
    },
    {
        key: 'download',
        tooltip: strings('data_export'),
        label: strings('download'),
        icon: faCloudArrowDown,
    },
    {
        key: 'activity_log',
        tooltip: strings('activity_log'),
        label: strings('activity_log'),
        icon: faHistory,
    },
    {
        key: 'filter',
        tooltip: strings('filter'),
        label: strings('filter'),
        icon: faFilter,
        buttonProps: {variant: hasFilter ? 'primary': 'white'},
    },
    {
        key: 'save',
        tooltip: 'Save',
        label: strings('save'),
        icon: faSave,
        isVisible: isEditMode,
        buttonProps: {variant: 'primary'},
        placement: 'right',
    },
    {
        key: 'chart',
        tooltip: strings('chart'),
        label: strings('chart'),
        icon: faChartSimple,
        placement: 'right',
    },

];

import {TCellRowProps} from '../Types/ActionButton.t';
import {TTableRowAction} from '../Types/TableActionMenuEntry.t';

export const IsActionVisible = (menuEntry: TTableRowAction, row: TCellRowProps['row']) => {
    if (menuEntry.isVisible === false) return false;

    if (!menuEntry.isVisible) return true;

    if (typeof menuEntry.isVisible == 'function' && menuEntry.isVisible?.({row}) === false) {
        return false;
    }

    return true;
};

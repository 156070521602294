
import {Button, Label, Tooltip} from '@aktek/f4kit';

import {TActionButtonProps} from '../Types/ActionButton.t';

const ActionButton : React.FC<TActionButtonProps> = ({action, onClick, defaultButtonProps}) => {
    const {label, icon, buttonProps = {}, tooltip} = action || {};
    const finalButtonProps = {
        ...defaultButtonProps,
        ...buttonProps,
    };

    return <Tooltip
        placement="top"
        showDelay={600}
        content={
            <div className="flex justify-center items-center px-1 py-0.5">
                <Label text={tooltip ?? label} size="xs"/>
            </div>
        }
    >
        <Button
            onClick={onClick}
            isOutlined={false}
            isGhost
            variant={'neutral'}
            icon={icon}
            {...finalButtonProps}
        />
    </Tooltip>;
};

export default ActionButton;

import {toast} from '@aktek/f4kit';
import {UserService} from '@aktek/helios-frontend-sdk';
import {useState} from 'react';

import {isEventEnterKey} from '@/helpers/Events';
import {strings} from '@/localization/i18n';

import {useForgetPasswordValidation} from './useForgetPasswordValidation';

export default function useForgotPassword() {
    const [forgetPasswordEmailState, setForgetPasswordEmailState] = useState({email: '', emailTouched: false} );
    const {validationResult} = useForgetPasswordValidation(forgetPasswordEmailState);

    const handleEmailChange = (email)=> {
        setForgetPasswordEmailState({...forgetPasswordEmailState, email: email.trim()});
    };

    const handleKeyDown = (event, onForgetPasswordSuccess) => {
        if (!isEventEnterKey(event)) return;

        handleForgetPasswordRequest(onForgetPasswordSuccess);
    };

    const handleForgetPasswordRequest = async (onForgetPasswordRequestSuccess)=> {
        if (!validationResult.isValid) return;

        const requestForgetPasswordResponse
        = await UserService.requestPasswordReset(forgetPasswordEmailState.email);

        const data = requestForgetPasswordResponse.getData?.();

        if (requestForgetPasswordResponse.isSuccessful?.()) {
            if (data.resetPossible) {
                onForgetPasswordRequestSuccess();
                toast.success(strings('reset_password_sent'));
            } else {
                toast.warning(strings('cannot_reset'));
            }
        } else {
            toast.error(strings('request_reset_fail'));
        }
    };

    return {
        forgetPasswordEmailState, setForgetPasswordEmailState,
        handleEmailChange, validationResult,
        handleForgetPasswordRequest, handleKeyDown,
    };
}

;


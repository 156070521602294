import {F4FormContainer, F4FormDiv, F4FormStep, F4Nestor} from '@aktek/f4form';
import {Label} from '@aktek/f4kit';
import {useMemo, useState} from 'react';

import F4Textbox from '@/components/F4Elements/F4TextBox';
import LicenseFeature from '@/components/LicenseComponents/LicenseFeature';
import SearchBox from '@/components/SearchBox/SearchBox';
import {LICENSE_FEATURES, LIMITS_FOR_METRICS} from '@/constants/LicenseFeatures.c';
import {strings} from '@/localization/i18n';

const LicenseInfo = () => {
    const [search, setSearch] = useState('');

    const filteredFeatures = useMemo(() => {
        return LICENSE_FEATURES.filter((item) =>
            item.name.toLowerCase().includes(search.toLowerCase()),
        );
    }, [search, LICENSE_FEATURES]);

    const filteredLimits = useMemo(() => {
        return LIMITS_FOR_METRICS.filter((item) =>
            item.name.toLowerCase().includes(search.toLowerCase()),
        );
    }, [search, LIMITS_FOR_METRICS]);

    const hasFeatures = filteredFeatures.length > 0;
    const hasLimits = filteredLimits?.length > 0;

    return (
        <F4FormStep autoNest>

            <F4FormContainer colSpan={12} className="" >

                <F4Textbox type="text" name="plan" label={strings('plan_name')} required autoFocus />

                <F4FormDiv className="flex flex-col justify-between items-center relative">

                    <F4Nestor name="features">

                        <div className="!sticky -top-4 bg-white w-full elevation-1 !shadow-none pt-2">
                            <SearchBox
                                placeholder={strings('search_features_limits')}
                                className="!bg-neutral-100 rounded-lg w-full mb-3 !mx-0 !px-0"
                                textBoxInputClassName="!bg-neutral-100 !text-base !font-normal !text-neutral-900"
                                hasBottomBorder={false}
                                value={search}
                                autoFocusOnOpen={false}
                                onSearch={(value) => setSearch(value.toLowerCase())}
                            />
                        </div>
                        <F4FormDiv className="flex justify-between items-center py-4">
                            <Label className="w-full" size="xl" text={strings('features')} color="neutral-700" fontWeight="medium" />
                            <Label className="pr-2" size="sm" text={strings('customize')} color="neutral-600" fontWeight="medium" />
                        </F4FormDiv>
                        {hasFeatures && filteredFeatures.map((item, index) => <LicenseFeature isLicensePage={true} key={index} item={item} />)}
                        {!hasFeatures && <p className="flex items-center mt-3 ml-2 overflow-hidden">{strings('no_results_found')}</p>}

                    </F4Nestor>

                    <F4FormDiv className="w-full h-px bg-neutral-200 mx-auto my-4" />

                    <F4Nestor name="limits">
                        <F4FormDiv className="flex justify-between items-center py-4">
                            <Label size="xl" text={strings('iq_areas_limits')} color="neutral-700" fontWeight="medium" />
                            <Label className="pr-2" size="sm" text={strings('customize')} color="neutral-600" fontWeight="medium" />
                        </F4FormDiv>

                        {hasLimits && filteredLimits?.map((item, index) => <LicenseFeature isLicensePage={true} key={index} item={item} />)}
                        {!hasLimits && <p className="flex items-center mt-3 ml-2 overflow-hidden">{strings('no_results_found')}</p>}

                    </F4Nestor>

                </F4FormDiv>

            </F4FormContainer>

        </F4FormStep>
    );
};

export default LicenseInfo;

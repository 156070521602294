import {F4FormDiv} from '@aktek/f4form';
import {TModalRef} from '@aktek/f4kit';
import {TagService} from '@aktek/morph-frontend-sdk';
import {forwardRef} from 'react';

import F4CreatableModal from '@/components/F4CreatableModal/F4CreatableModal';
import {TF4CreatableModalProps} from '@/components/F4CreatableModal/types/F4CreatableModalProps.t';
import F4ButtonColorPicker from '@/components/F4Elements/F4ButtonColorPicker';
import F4Textbox from '@/components/F4Elements/F4TextBox';
import {strings} from '@/localization/i18n';

function TagCreateModal(props: TF4CreatableModalProps, ref: TModalRef) {
    const {viewState, ...rest}= props;

    return (

        <F4CreatableModal
            viewState={viewState}
            ref={ref}
            title={strings('new_tag')}
            formKey="tag"
            hasSidebar={false}
            className="!h-[23.5rem] w-2/3 lg:w-1/3"
            editSuccessMessage={strings('edit_tag_success')}
            getFunction={TagService.getTagByName}
            storeFunction={TagService.storeTagv4}
            createSuccessMessage={strings('store_tag_success')}
            {...rest}
        >
            <F4FormDiv className="flex flex-col gap-4">
                <F4Textbox
                    name="tag"
                    label={strings('label')}
                    type={'text'}
                    required
                    isDisabled={viewState?.mode === 'edit'}
                    placeholder="Active"
                    colSpan={12}
                />
                <F4ButtonColorPicker
                    name="color"
                    label={strings('color')}
                    size="2xl"
                    colSpan={12}
                />
            </F4FormDiv>
        </F4CreatableModal>

    );
}

export default forwardRef(TagCreateModal);


import {TColumn} from '@aktek/f4kit';
import _isEmpty from 'lodash/isEmpty';
import _isFunction from 'lodash/isFunction';

import {CellActions} from '../Components/Cell.Actions';
import {TStatelessTableProps} from '../Types/Table.t';

export const getActionsColumnDefFromRowActions = ({state, onRowAction, rowActions}: Partial<TStatelessTableProps>) => {
    if (!rowActions || (_isEmpty(rowActions) && !_isFunction(rowActions))) return null;

    const column : TColumn= {
        key: 'actions',
        header: '',
        cell: CellActions(rowActions, state, onRowAction),
    };

    return column;
};

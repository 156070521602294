import {TIconProps} from '@aktek/f4kit';
import type {XFieldTypes} from '@aktek/types';
import {fa4, faArrowRight, faArrowsTurnRight, faAtom, faCalendar, faCalendarClock, faClock, faDivide, faEarthAmericas, faFill, faList, faTag, faText, faToggleOn} from '@fortawesome/pro-light-svg-icons';
type TFieldType = XFieldTypes | 'tags' | 'ioField';

export const FieldIcons : Record<TFieldType | 'default', Partial<TIconProps>> = {
    'string': {
        icon: faText,
        color: 'semantic1-400',
    },
    'color': {
        icon: faFill,
        color: 'warning-600',
    },
    'boolean': {
        icon: faToggleOn,
        color: 'primary-600',
    },
    'closedList': {
        icon: faList,
        color: 'primary-600',
    },
    'integer': {
        icon: fa4,
        color: 'primary-600',
    },
    'float': {
        icon: faDivide,
        color: 'error-600',
    },
    'gps': {
        icon: faEarthAmericas,
        color: 'error-600',
    },
    'date': {
        icon: faCalendar,
        color: 'error-600',
    },
    'time': {
        icon: faClock,
        color: 'warning-600',
    },
    'datetime': {
        icon: faCalendarClock,
        color: 'success-600',
    },
    'tags': {
        icon: faTag,
        color: 'success-600',
    },
    'ref': {
        icon: faArrowRight,
        color: 'primary-600',
    },
    'refList': {
        icon: faTag,
        color: 'success-600',
    },
    'connectedRef': {
        icon: faArrowsTurnRight,
        color: 'warning-600',
    },
    'ioField': {
        icon: faAtom,
        color: 'primary-600',
    },
    'default': {
        icon: faAtom,
        color: 'primary-600',
    },

};

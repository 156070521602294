import {TDropdownOption, TDropdownValue, TRadioButton} from '@aktek/f4kit';

import {COLORS} from '@/constants/colors.c';

export const THEME_COLORS = [
    {name: 'primary', label: 'color_primary', value: '#27666C'},
    {name: 'neutral', label: 'color_neutral', value: '#27666C'},
    {name: 'error', label: 'color_error', value: '#D92D20'},
    {name: 'warning', label: 'color_warning', value: '#556878'},
    {name: 'success', label: 'color_success', value: '#321578'},
    {name: 'white', label: 'color_white', value: '#000000'},
    {name: 'black', label: 'color_black', value: '#ffffff'},
    {name: 'semantic1', label: 'color_semantic1', value: '#4a1fb8'},
    {name: 'semantic2', label: 'color_semantic2', value: '#175cd3'},
    {name: 'semantic3', label: 'color_semantic3', value: '#ee4689'},

];

export const FONT_STYLES: Record<TDropdownValue, TDropdownOption> = {
    '"Inter", sans-serif': {label: 'Inter'},
    '"Josefin Sans", sans-serif': {label: 'Josefin Sans'},
    '"Playfair Display", serif': {label: 'Playfair Display'},
    '"Plus Jakarta Sans", sans-serif': {label: 'Plus Jakarta Sans'},
    '"Space Grotesk", sans-serif': {label: 'Space Grotesk'},
};
export const FONT_SIZES: TRadioButton[] = [
    {value: 'small', label: 'Small', buttonString: 'Aa', buttonStringSize: 'sm'},
    {value: 'medium', label: 'Medium', buttonString: 'Aa', buttonStringSize: 'md'},
    {value: 'large', label: 'Large', buttonString: 'Aa', buttonStringSize: 'xl'},
];

export const DEFAULT_APPEARANCE = {
    colors: COLORS,
    font: {
        fontSize: 'medium',
        fontStyle: '"Inter", sans-serif',
    },
    images: {
        logo: null,
        background: null,
    },
};

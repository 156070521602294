
import {applyLoginLogo, applyLoginSideImage} from '@/helpers/applyLoginAssets.fn';
import {getUpdatedCompany, getUpdatedCompanySettings, getUpdatedUser} from '@/helpers/GetUpdatedUserAuth.fn';

import {useUserContext} from '../UserContext.Provider';

export function useSignUserIn() {
    const {setUserAuth}= useUserContext();

    return (userAuthData) => {
        applyLoginSideImage(ls?.theme?.images?.background);

        applyLoginLogo(userAuthData?.currentCompany?.companyLogo);

        const updatedUserAuthData = {
            ...userAuthData,
            currentUser: getUpdatedUser(userAuthData.currentUser),
            companySettings: getUpdatedCompanySettings(userAuthData.companySettings),
            company: getUpdatedCompany(userAuthData.company),
        };

        ls.signIn(updatedUserAuthData);

        setUserAuth(updatedUserAuthData);
    };
}


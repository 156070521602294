import {useCallback, useState} from 'react';

import {useGlobalPath} from '@/context/UserContext/Hooks/useGlobalPath';
import {strings} from '@/localization/i18n';
import {useNavigateToRoot, useNavigateToURL} from '@/routing/navigations';

import AuthContainer from '../../components/AuthContainer';
import LoginCredentials from './components/LoginCredentials';
import TwoFactorAuth from './components/TwoFactorAuth';
import type {TTwoFactorState} from './types/twoFactorState.t';
enum ViewModes { login, twoFactorAuth}

export default function Login() {
    const navigateToRoot = useNavigateToRoot();
    const navigateTo = useNavigateToURL();
    const {path} = useGlobalPath();

    const [loginView, setLoginView]=useState<ViewModes>(ViewModes.login);
    const [twoFactorState, setTwoFactorState] = useState<TTwoFactorState>(null);

    const onLoginSuccess = useCallback((twoFactorState: TTwoFactorState)=> {
        setTwoFactorState(twoFactorState);
        setLoginView(ViewModes.twoFactorAuth);

        if (path) return navigateTo(path);
    }, []);

    const on2FASuccess = useCallback(()=> {
        if (path) return navigateTo(path);

        navigateToRoot(true);
    }, []);

    return (
        <AuthContainer>
            {loginView === ViewModes.login
            && <LoginCredentials
                onLoginSuccess={onLoginSuccess}
            />}

            {loginView === ViewModes.twoFactorAuth
            && <TwoFactorAuth
                setLoginView={setLoginView}
                twoFactorAuthState={twoFactorState}
                on2FASuccess={on2FASuccess}
            />}
        </AuthContainer>
    );
}

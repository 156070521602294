import {F4Nestor} from '@aktek/f4form';
import {ContentDivider, Label} from '@aktek/f4kit';

import F4Textbox from '@/components/F4Elements/F4TextBox';
import F4ToggleSwitch from '@/components/F4Elements/F4Toggle';
import {useAdminPermissions} from '@/context/Permissions/usePermissions';
import {strings} from '@/localization/i18n';

export default function PasswordSettings() {
    const {companySettings: companySettingsPermissions} = useAdminPermissions();

    return (
        <>
            <F4Nestor name="password">

                <div className="mt-8">
                    <ContentDivider
                        backgroundColor="neutral-50"
                        middleChild={<Label text={strings('password')}
                            className="text-neutral-600 font-medium" />} />
                </div>

                <F4Textbox
                    name="settings_password_time_validity"
                    colSpan={3}
                    required
                    type="number"
                    allowDecimal={false}
                    min={1}
                    placeholder="30"
                    isDisabled={!companySettingsPermissions?.can_edit}
                    rightPlaceholder={strings('days')}
                    label={strings('password_expiration_period')} />

                <F4Textbox
                    name="settings_notify_stale_password"
                    colSpan={3}
                    required
                    type="number"
                    allowDecimal={false}
                    min={1}
                    isDisabled={!companySettingsPermissions?.can_edit}
                    validate={(value: number, props, getValue)=> {
                        const max = Number(getValue('password.settings_password_time_validity'));
                        if (value >= max) return {isValid: false, messages: [strings('password_notification_error_message')]};

                        return {isValid: true};
                    }}
                    placeholder="30"
                    rightPlaceholder={strings('days')}
                    label={strings('password_expiration_notification')} />

            </F4Nestor>

            <F4Nestor name="password_reset">

                <div className="mt-8">
                    <ContentDivider
                        backgroundColor="neutral-50"
                        middleChild={<Label text={strings('password_reset')}
                            className="text-neutral-600 font-medium" />} />
                </div>

                <F4ToggleSwitch
                    name="settings_user_reset_pass"
                    colSpan={3}
                    label=""
                    size="md"
                    isDisabled={!companySettingsPermissions?.can_edit}
                    descriptionClassName="!text-neutral-700 !font-medium"
                    description={strings('allow_user_to_reset_password')}

                />

                <F4ToggleSwitch
                    name="settings_admin_reset_user_pass"
                    colSpan={3}
                    label=""
                    size="md"
                    isDisabled={!companySettingsPermissions?.can_edit}
                    descriptionClassName="!text-neutral-700 !font-medium"
                    description={strings('allow_admin_to_obtain_reset_links')}

                />
            </F4Nestor>
        </>
    );
}

import {TagService} from '@aktek/morph-frontend-sdk';

import F4ViewableComponent from '@/components/F4ViewableComponent/F4ViewableComponent';
import {useAdminPermissions} from '@/context/Permissions/usePermissions';
import {strings} from '@/localization/i18n';

import TagCreateModal from './components/Tags.Create.Modal';
import {GetTagsColumns, XTag} from './constants/Tags.columns';

export default function Tags() {
    const {tags} = useAdminPermissions();

    return (
        <div className="h-full">
            <F4ViewableComponent<XTag>
                name={strings('tag')}
                idColumnName="tag"
                nameColumnName="tag"
                defaultSortedColumn="tag"
                canCreate={tags?.can_create}
                deleteSuccessMessage={strings('delete_tag_success')}
                emptyProps={{addLabel: strings('new_tag')}}
                tableColumns={GetTagsColumns()}
                rowActions={['edit', 'delete']}
                getAllData={TagService.getAllTagsv4}
                deleteItem={TagService.deleteTag}
                modal={TagCreateModal}
            />
        </div>
    );
}

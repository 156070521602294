import moment from 'moment';

import {strings} from '@/localization/i18n';

export default function GetTooltip(time: number) {
    if (time) {
        return `${moment.unix(time/1000).format('DD/MM/yy HH:mm:ss')} ${strings('click_to_refresh')}`;
    }

    return strings('no_data');
}

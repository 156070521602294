import {F4Nestor} from '@aktek/f4form';
import {ContentDivider, Label} from '@aktek/f4kit';

import F4Textbox from '@/components/F4Elements/F4TextBox';
import F4ToggleSwitch from '@/components/F4Elements/F4Toggle';
import {useAdminPermissions} from '@/context/Permissions/usePermissions';
import {strings} from '@/localization/i18n';

export default function FreezeAccount() {
    const {companySettings: companySettingsPermissions} = useAdminPermissions();

    return (
        <F4Nestor name="account_freezing">

            <ContentDivider
                backgroundColor="neutral-50"
                middleChild={
                    <Label text={strings('freeze_account')}
                        className="text-neutral-600 font-medium"
                    />}
            />

            <F4ToggleSwitch
                name="settings_freeze_account_admin_freeze"
                colSpan={3}
                size="md"
                isDisabled={!companySettingsPermissions?.can_edit}
                label=""
                descriptionClassName=" !text-neutral-700 !font-medium"
                description={strings('allow_admin_manually_freeze_user')}

            />

            <F4ToggleSwitch
                name="settings_freeze_account_inactive_time"
                colSpan={3}
                label=""
                size="md"
                isDisabled={!companySettingsPermissions?.can_edit}
                descriptionClassName=" !text-neutral-700 !font-medium"
                description={strings('freeze_user_after_inactivity_period')}
                nullifyVars={['account_freezing.settings_max_allowed_inactive_time']}
            />

            <F4Textbox
                name="settings_max_allowed_inactive_time"
                colSpan={3}
                allowDecimal={false}
                type="number"
                min={1}
                show={(valueOf)=>!!valueOf('account_freezing.settings_freeze_account_inactive_time')}
                placeholder="30"
                rightPlaceholder={strings('days')}
                label={strings('maximum_inactivity_period')}
                required
            />

        </F4Nestor>
    );
}

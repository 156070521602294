import {faPen} from '@fortawesome/pro-regular-svg-icons';

import {TRowActionsBuilder} from '@/components/Table/Types/Table.t';
import {strings} from '@/localization/i18n';

export const GetRowLicensingActions = () => (): TRowActionsBuilder => {
    return [
        {
            key: 'edit',
            label: strings('edit'),
            icon: faPen,
            showOnRow: true,
        },
    ];
};

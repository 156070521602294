import {F4Nestor} from '@aktek/f4form';
import {ContentDivider, Label} from '@aktek/f4kit';

import F4ToggleSwitch from '@/components/F4Elements/F4Toggle';
import {useAdminPermissions} from '@/context/Permissions/usePermissions';
import {strings} from '@/localization/i18n';

export default function SecuritySettings() {
    const {companySettings: companySettingsPermissions} = useAdminPermissions();

    return (
        <F4Nestor name="security">

            <div className="mt-2">
                <ContentDivider
                    backgroundColor="neutral-50"
                    middleChild={
                        <Label text={strings('security')}
                            className="text-neutral-600 font-medium"
                        />}/>
            </div>

            <F4ToggleSwitch
                name="settings_disable_2fa"
                colSpan={3}
                label=""
                size="md"
                isDisabled={!companySettingsPermissions?.can_edit}
                descriptionClassName="!text-neutral-700 !font-medium"
                description={strings('disable_2FA')}

            />
        </F4Nestor>
    );
}

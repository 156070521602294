
import {useUserContext} from '../UserContext.Provider';
import {useGlobalPath} from './useGlobalPath';

export function useSignUserOut() {
    const {setUserAuth}= useUserContext();
    const {setPath} = useGlobalPath();

    return () => {
        ls.signOut();
        setUserAuth({});
        setPath('');
    };
}

import {strings} from '@/localization/i18n';

export const GetSteps = (canAccessLicense:boolean) => [
    {key: 'companyDetails', label: strings('company_details')},
    ...(canAccessLicense ? [
        {key: 'license', label: strings('license')},
        {key: 'activation', label: strings('activation')},
    ] : []),

];



import IOSelect from '@/iohub/IOSelect';

const TagsCellEdit = ({value, onChange}) => {
    return <IOSelect isMulti
        type="tags"
        onChange={(tags) => {
            onChange({
                joined: tags?.join?.('') || '',
                tags,
            });
        }}
        value={value?.tags || []}
    />;
};

export default TagsCellEdit;

import {SBOService} from '@aktek/morph-frontend-sdk';

type TGetColumns = {
    sboId: string
}

export const GetDataTableColumns = async ({sboId}: TGetColumns) => {
    const response = await SBOService.getSBOHeaders(sboId);
    const data = response.getData();
    if (!data) return [];

    const items = data?.headers || [];

    return items.map((item) => {
        const column = {
            key: item._id,
            header: item.title,
            type: item.type,
            isSortable: true,
        };

        return column;
    });
};

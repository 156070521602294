import {Label, TModalRef} from '@aktek/f4kit';
import {forwardRef, useState} from 'react';

import F4CreatableModal from '@/components/F4CreatableModal/F4CreatableModal';
import {TF4CreatableModalProps} from '@/components/F4CreatableModal/types/F4CreatableModalProps.t';
import F4ExplorerNodeDesign from '@/components/F4Elements/F4ExplorerNodeDesign';
import F4Textbox from '@/components/F4Elements/F4TextBox';
import F4TreeViewSelector from '@/components/F4Elements/F4TreeViewSelector';
import SearchBox from '@/components/SearchBox/SearchBox';
import {useGlobalFilesAndFolders} from '@/context/UserContext/Hooks/useGlobalFilesAndFolders';
import {GetFirstKey} from '@/helpers/GetFirstKey.fn';
import {useDebouncedCallback} from '@/hooks/useDebouncedCallback';
import {strings} from '@/localization/i18n';

import useFilteredItems from '../hooks/useFilteredItems';

type TFolderCreateModal = TF4CreatableModalProps & {
    parentId?: string
}

const FolderCreateModal = (props: TFolderCreateModal, ref: TModalRef) => {
    const {viewState, parentId, ...rest}= props;
    const {folders, storeFolder} = useGlobalFilesAndFolders();
    const [searchValue, setSearchValue] = useState('');
    const items = useFilteredItems(folders, searchValue, true, 'folder');

    const debounceTime = 250;

    const hasItems = items && items.length > 0;
    const [info, setInfo] = useState({
        folderName: '',
        parentId: undefined,
    });
    const updateInfoState = (key, value) => setInfo((state) => ({...state, [key]: value}));

    const _onChange = (value: string) => {
        setInfo((prevInfo) => ({
            ...prevInfo,
            folderName: value.trim(),
        }));
    };

    const handleTextBoxChange = useDebouncedCallback(debounceTime, _onChange);

    const handleTreeViewChange = (value) => {
        const itemChecked = GetFirstKey(value);
        updateInfoState('parentId', itemChecked);
    };

    return (
        <F4CreatableModal
            ref={ref}
            viewState={viewState}
            title={strings('folder')}
            formKey="folder"
            className="!w-1/2"
            hasSidebar={false}
            isDisabled={info.folderName === '' || info.parentId === undefined}
            storeFunction={storeFolder}
            createSuccessMessage={strings('folder_created_successfully')}
            errorMessage={strings('folder_created_failed')}
            {...rest}
        >

            <>
                <div className="sticky !shadow-none elevation-5 -top-4 bg-white -mt-1">
                    <div className="flex items-start justify-start gap-3 mb-6" >

                        <div className={`bg-primary-25 rounded-xl px-4 pt-2 pb-3 flex items-center justify-center w-fit h-fit mt-3.5`}>
                            <F4ExplorerNodeDesign name="assets"/>
                        </div>

                        <F4Textbox autoFocus required
                            name="folderName"
                            label={strings('name')}
                            placeholder=""
                            colSpan={12}
                            onChange={handleTextBoxChange}
                            type="text"
                        />
                    </div>

                    <SearchBox onSearch={setSearchValue}
                        className="!bg-neutral-100 rounded-lg  mb-4"
                        textBoxInputClassName="!bg-neutral-100 !font-normal !text-neutral-900"
                        size="md"
                        autoFocusOnOpen={false}
                    />

                </div>

                <F4TreeViewSelector name="parentId" data={items} parentId={parentId} size="xs" onChange={handleTreeViewChange} />

                {!hasItems && <Label
                    text={strings('no_results_found')}
                    size="sm"
                    className="mt-5 ml-3 overflow-hidden"
                    color="neutral-600"/>}
            </>

        </F4CreatableModal>

    );
};

export default forwardRef(FolderCreateModal);

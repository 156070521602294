import {faArrowRightFromArc, faBarChart, faBolt, faBrush, faBuilding, faBuildings, faChartLineUp,
    faClockRotateLeft, faCloudArrowDown,
    faDatabase, faEye, faFileLines, faFill, faFunction, faGear, faLanguage, faList, faLock, faMapPin, faPlus,
    faTag, faTrashCan, faTriangleExclamation, faUser, faUsers} from '@fortawesome/pro-regular-svg-icons';

import AgencyCreateModal from '@/app/Admin/Agency/components/Agency.Create.Modal';
import LicensingCreateModal from '@/app/Admin/Licensing/components/Licensing.Create.Modal';
import AskForModal from '@/asks/AskForModal';
import type {TNavigationSection} from '@/components/Navigation/types/Navigation.t';
import {strings} from '@/localization/i18n';
import {TPermissions} from '@/types/Permissions.t';

type TAdminNavigation = (openTab, permissions: TPermissions)=> TNavigationSection[]
export const AdminNavigation : TAdminNavigation = (openTab, permissions) => [
    {
        sectionTitle: strings('security'),
        categories: [
            {
                tabKey: 'accessControl',
                name: strings('access_control'),
                icon: faLock,
                color: '#039855',
                pages: [
                    {label: strings('rolesAndPermissions'), tabKey: 'rolesAndPermissions',
                        icon: faEye, isVisible: permissions.admin.roles?.viewOrEdit},

                    {label: strings('users'), tabKey: 'users',
                        icon: faUser, isVisible: permissions.admin.users?.viewOrEdit},

                    {label: strings('groups'), tabKey: 'groups',
                        icon: faUsers, isVisible: permissions.admin.groups?.viewOrEdit},

                    {label: strings('tac'), tabKey: 'tac',
                        icon: faTag, isVisible: permissions.admin.tagBasedAccessControl?.viewOrCreateOrEdit},
                ],
                isVisible: permissions.canAccessAccessControl,
            },
        ],
        isVisible: permissions.canAccessSecurity,
    },
    {
        sectionTitle: strings('data'),
        categories: [
            {
                tabKey: 'dataStructure',
                name: strings('data_structure'),
                color: '#1570EF',
                icon: faDatabase,

                customBadges: [{label: strings('new_data_structure'), icon: faPlus,
                    onClick: () => openTab('dataStructureEditor', 'dataStructureEditor', {}),
                    isVisible: permissions.canAccessDataStructure,
                }],
                pages: [
                    {label: strings('data_structure'), tabKey: 'dataStructure', icon: faDatabase},
                    {label: strings('conditional_coloring'), tabKey: 'conditional_coloring', icon: faFill},
                    {label: strings('translations'), tabKey: 'translations', icon: faLanguage},
                ],
                isVisible: permissions.canAccessDataStructure,
            },
            {
                tabKey: 'automation',
                name: strings('automation'),
                color: '#1570EF',
                icon: faBolt,
                pages: [
                    {label: strings('apply_formula'), tabKey: 'applyFormula', icon: faFunction},
                    {label: strings('transform_ref_into_field'), tabKey: 'transformRefIntoField', icon: faArrowRightFromArc},
                    {label: strings('transform_field_into_ref'), tabKey: 'transformFieldIntoRef', icon: faArrowRightFromArc},
                    {label: strings('transform_field_into_multiref'), tabKey: 'transformFieldIntoMultiRef', icon: faArrowRightFromArc},
                    {label: strings('extract_domain_names'), tabKey: 'extractDomainNames', icon: faCloudArrowDown},
                    {label: strings('extract_record_of_interest'), tabKey: 'extractRecordOfInterest', icon: faCloudArrowDown},
                    {label: strings('update_record_of_interest'), tabKey: 'updateRecordOfInterest', icon: faCloudArrowDown},
                    {label: strings('reverse_geocode'), tabKey: 'reverseGeocode', icon: faMapPin},
                    {label: strings('progress'), tabKey: 'automationProgress', icon: faChartLineUp},
                ],
                // FIXME: hassan fix while with SBO
                isVisible: true, // CanAccessDataStructure(),
            },
        ],
        isVisible: permissions.canAccessData,
    },
    {
        sectionTitle: strings('my_company'),
        categories: [
            {
                tabKey: 'companySettings',
                name: strings('company_settings'),
                color: '#6938EF',
                pages: [
                    {label: strings('settings'), tabKey: 'settings', icon: faGear, isVisible: permissions.canAccessSettings},
                    {label: strings('appearance'), tabKey: 'appearance', icon: faBrush, isVisible: permissions.canAccessSettings},
                    {label: strings('tags'), tabKey: 'tags', icon: faTag, isVisible: permissions.canAccessTags},
                ],
                icon: faGear,
                isVisible: permissions.canAccessCompanySettings,
            },
            {
                tabKey: 'usageMetrics',
                name: strings('usage_metrics'),
                color: '#6938EF',
                pages: [
                    {label: strings('monthly_usage')},
                    {label: strings('overall_usage')},
                ],
                icon: faBarChart,
            },
        ],
    },
    {
        sectionTitle: 'Agency',
        categories: [
            {
                tabKey: 'agency',
                name: strings('agency'),
                icon: faBuilding,
                color: '#BA24D5',
                customBadges: [{label: strings('new_company'), icon: faPlus,
                    onClick: () => AskForModal(AgencyCreateModal),
                    isVisible: permissions.canCreateCompanies,
                }],
                pages: [
                    {tabKey: 'agency', label: strings('companies'), icon: faBuildings, isVisible: permissions.canViewCompanies},

                ],
                isVisible: permissions.canAccessAgency,
            },
            {
                tabKey: 'licensing',
                name: strings('licensing'),
                color: '#BA24D5',
                icon: faFileLines,
                customBadges: [{label: strings('new_license'), icon: faPlus,
                    onClick: () => AskForModal(LicensingCreateModal),
                }],
                pages: [
                    {tabKey: 'licensing', label: strings('licenses_list'), icon: faFileLines, isVisible: permissions.canAccessLicense},

                ],
                isVisible: permissions.canAccessLicense,
            },
        ],
        isVisible: permissions.canAccessAgencySection,
    },
    {
        sectionTitle: 'Advanced',
        categories: [
            {
                tabKey: 'advanced',
                name: strings('advanced'),
                color: '#088AB2',
                pages: [
                    {tabKey: 'restoreFields', label: strings('restore_fields'), icon: faClockRotateLeft},
                    {tabKey: 'deleteAllData', label: strings('delete_all_data'), icon: faTrashCan},
                ],
                icon: faTriangleExclamation,
            },
            {
                tabKey: 'iOJobs',
                color: '#088AB2',
                name: strings('io_jobs'),
                pages: [{tabKey: 'iOJobs', label: strings('view_all_jobs'), icon: faList}],
                icon: faList,
            },
        ],
    },
];
